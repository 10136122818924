import {bgColorObj, txtColorObj} from "./constants/LabelsColorConstants";
import "./css/AGLabels.css";
import {labelType, lineSizes} from "./constants/LabelsConstants";
import React from "react";
import {useSelector} from "react-redux";
import {paramLabel} from "./constants/LabelsFontSizesConstants";

export default function ADISCLabels(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, linha4, linha5, linha6, iconSrc, icon,fontSizeLinha1,fontSizeL1_2,fontSizeL2_2,idx;
    let fontSizeL1_3,fontSizeL2_3,fontSizeL3_3;
    linha1=props.linha1;
    linha2=props.linha2;
    linha3=props.linha3;
    idx=props.index;
    linha4="";
    linha5="";
    linha6="";

    console.log(idx);
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";
    if(labelConfigState.labelConfig.linha1==="" && labelConfigState.labelConfig.linha2==="" ) {
            linha1 = props.linha1; // disciplina
        } else if(labelConfigState.labelConfig.linha1!=="" && labelConfigState.labelConfig.linha2==="") {
            linha1="";
            linha2=props.linha1; // disciplina
            linha3= props.linha2; // linha1
        } else if(labelConfigState.labelConfig.linha1==="" && labelConfigState.labelConfig.linha2!=="") {
            linha1 = "";
            linha2 = props.linha1; // disciplina
            linha3 = props.linha3; // linha2
        } else {
            linha1="";
            linha2="";
            linha3="";
            linha4 = props.linha1; //disciplina
            linha5 = props.linha2; // linha1
            linha6 = props.linha3; // linha2
        }

    const setFontSize = (labelType, configuration) => {
        // *****************************
        // set fontsize for labels .....
        // TODO: talvez se possa usar offsetHeight para melhor a função
        //   ver  : https://stackoverflow.com/questions/687998/auto-size-dynamic-text-to-fill-fixed-size-container
        //******************************
        let linha1Temp = "";
        let linha2Temp = "";
        let linha3Temp = "";
        linha1Temp = props.linha1; // disciplina
        linha2Temp = props.linha2; // linha 1
        linha3Temp = props.linha3; // linha 2

        let c_s_icon;
        if (configuration.icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons") {
            c_s_icon = "SEM_ICON";
        } else {
            c_s_icon = "COM_ICON";
        }

        if (labelType === "AG" || labelType === "EFG" || labelType==="ADISC") {
            if (linha2Temp === "" && linha3Temp === "") {
                // 1 linha
                if (linha1Temp.length > paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_length"]) {
                    fontSizeLinha1 = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_size_ini"] - (linha1Temp.length - paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_min_size"]) + "pt";
                } else {
                    fontSizeLinha1 = paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_size_ini"] + "pt";
                }
            } else if (linha1Temp !== "" && linha2Temp !== "" && linha3Temp === "") {
                // 2 linhas (linha 1 e linha2)
                if (Math.max(linha1Temp.length, linha2Temp.length) > paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_length"]) {
                    fontSizeL1_2 = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] - (linha1Temp.length - paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_min_size"]);
                    fontSizeL2_2 = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] - (linha2Temp.length - paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_min_size"]);
                } else {
                    fontSizeL1_2 = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"];
                    fontSizeL2_2 = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"];
                }
                // ajusta o tamanho caso o tamanho da fonte da linha1 seja maior ou igual ao tamanho da fonte da disciplina
                if(fontSizeL2_2 >= fontSizeL1_2)
                    fontSizeL2_2 = fontSizeL1_2 - 1;
                fontSizeL1_2 = fontSizeL1_2 + "pt";
                fontSizeL2_2 = fontSizeL2_2 + "pt";
            } else if (linha1Temp !== "" && linha2Temp !== "" && linha3Temp !== "") {
                // 3 linhas (linha1, linha2 e linha3)
                if (Math.max(linha1Temp.length, linha2Temp.length, linha3Temp.length) > paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_length"]) {
                    fontSizeL1_3 = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] - (linha1Temp.length - paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_min_size"]);
                    fontSizeL2_3 = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] - (Math.max(linha2Temp.length, linha3Temp.length) - paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_min_size"]) ;
                    fontSizeL3_3 = fontSizeL2_3;
                } else {
                    fontSizeL1_3 = paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] ;
                    fontSizeL2_3 = paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] ;
                    fontSizeL3_3 = paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] ;
                }
                if(fontSizeL2_3 >= fontSizeL1_3) {
                    fontSizeL2_3 = fontSizeL1_3 - 1;
                    fontSizeL3_3 = fontSizeL2_3;
                }
                fontSizeL1_3 = fontSizeL1_3 + "pt";
                fontSizeL2_3 = fontSizeL2_3 + "pt";
                fontSizeL3_3 = fontSizeL3_3 + "pt";
                //console.log(fontSizeL1_3 + "-" + fontSizeL2_3 + "-" + fontSizeL3_3);
            }
        }
    };
    setFontSize(labelType[labelConfigState.labelConfig.labelSKU], labelConfigState.labelConfig);
    return(
        <div id="label-tiketa-ag" className="labelSimulationCard"
                 >
                <div
                    className="label label-ag"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorEsq,
                        display: labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "right" ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand_ag"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconleft iconleft_ag">
                        <img
                            id="iconLeft"
                            alt="labelIcon"
                            className="iconleft iconleft_ag"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconEsq + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-sicon label-ag-l1-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: fontSizeLinha1,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha1}
                    </div>
                    <div
                        className="label-l2-sicon label-ag-l2-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: fontSizeL1_2,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha2}
                    </div>
                    <div
                        className="label-l3-sicon label-ag-l3-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: fontSizeL2_2,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha3}
                    </div>
                    <div
                        className="label-ag-l4-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["ADISC"]["fontSizeL1_3"],
                        }}
                    >
                        {linha4}
                    </div>
                    <div
                        className="label-ag-l5-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["ADISC"]["fontSizeL2_3"],
                        }}
                    >
                        {linha5}
                    </div>
                    <div
                        className="label-ag-l6-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["ADISC"]["fontSizeL3_3"],
                        }}
                    >
                        {linha6}
                    </div>
                    <div
                        className="label-l1-cicon label-ag-l1-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: fontSizeLinha1,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha1}
                    </div>
                    <div
                        className="label-l2-cicon label-ag-l2-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: fontSizeL1_2,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha2}
                    </div>
                    <div
                        className="label-l3-cicon label-ag-l3-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: fontSizeL2_2,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha3}
                    </div>
                    <div
                        className="label-ag-l4-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["ADISC"]["fontSizeL1_3"],
                        }}
                    >
                        {linha4}
                    </div>
                    <div
                        className="label-ag-l5-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["ADISC"]["fontSizeL2_3"],
                        }}
                    >
                        {linha5}
                    </div>
                    <div
                        className="label-ag-l6-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["ADISC"]["fontSizeL3_3"],
                        }}
                    >
                        {linha6}
                    </div>

                </div>
                <div
                    className="label label-ag"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorDir,
                        display: labelConfigState.labelConfig.labelSimulationType === "icons" || (labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "left") ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand-dir_ag"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconright iconright_ag">
                        <img
                            id="iconRight"
                            alt="labelIcon"
                            className="iconright iconright_ag"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconDir + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-cicon-right label-ag-l1-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: fontSizeLinha1,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha1}
                    </div>
                    <div
                        className="label-l2-cicon-right label-ag-l2-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: fontSizeL1_2,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha2}
                    </div>
                    <div
                        className="label-l3-cicon-right label-ag-l3-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: fontSizeL2_2,
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha3}
                    </div>
                    <div
                        className="label-ag-l4-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["ADISC"]["fontSizeL1_3"],
                        }}
                    >
                        {linha4}
                    </div>
                    <div
                        className="label-ag-l5-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["ADISC"]["fontSizeL2_3"],
                        }}
                    >
                        {linha5}
                    </div>
                    <div
                        className="label-ag-l6-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["ADISC"]["fontSizeL3_3"],
                        }}
                    >
                        {linha6}
                    </div>
                </div>
            </div>
    );
}