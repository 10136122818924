import React, {useState, useEffect} from "react";

import {Row, Col, Button, Form, Table} from "react-bootstrap";

import Message from "../components/Message";
import Loader from "../components/Loader";

import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {updatePassword} from "../actions/passwordUpdateActions";
import {passwordUpdateActions} from "../store/passwordUpdateSlice";
import PasswordChecklist from "react-password-checklist"


function ChangePasswordPage() {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const {userInfo} = useSelector(state => state.userLogin)
    const {success} = useSelector(state => state.passwordUpdate)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        } else {
            if (success) {
                setConfirmMessage('Password Actualizada!')
                dispatch(passwordUpdateActions.passwordUpdateReset({}))
                setLoading(true);
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            }
        }
    }, [dispatch, navigate, userInfo, success])

    const submitHandler = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setMessage('Coloque duas passwords iguais!')
        } else {
            dispatch(updatePassword(
                {'password': password}
            ))
        }
    };

    return (
        <Row>
            <Col md={3}/>
            <Col md={6}>
                <h2>Alterar Password</h2>

                {message && <Message variant="danger">{message}</Message>}
                {confirmMessage && <Message variant="info">{confirmMessage}</Message>}

                {loading ? <Loader/> :

                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId="password">
                            <Form.Label>Nova Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Introduzir Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="passwordConfirm">
                            <Form.Label>Confirmar Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirmar Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Form.Group>
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                            minLength={5}
                            value={password}
                            valueAgain={confirmPassword}
                            onChange={(isValid) => {
                                isValid ? document.getElementById("validButton").disabled = false :
                                    document.getElementById("validButton").disabled = true
                            }}
                            messages={{
                                minLength: "A password deve conter mais de 5 caracteres.",
                                specialChar: "A password deve conter caracteres especiais.",
                                number: "A password deve conter um numero.",
                                capital: "A password deve conter uma letra maiuscula.",
                                match: "As passwords coincidem.",
                            }}
                        />
                        <Button id="validButton" type="submit" variant="primary" className="mt-3">
                            Actualizar Password
                        </Button>
                    </Form>
                }

            </Col>
            <Col md={3}/>
        </Row>
    );
}

export default ChangePasswordPage;
