import {bgColorObj, txtColorObj} from "./constants/LabelsColorConstants";
import "./css/DespensaLabels.css";
import {lineSizes} from "./constants/LabelsConstants";
import React from "react";
import {useSelector} from "react-redux";
import DespensaFontSizesCalculator from "./constants/DespensaFontSizesCalculator";

export default function DespensaLabels(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, isKitMember, iconSrc, icon;
    linha1=props.linha1 ? props.linha1:"" ;
    linha2=props.linha2 ? props.linha2:"" ;
    linha3=props.linha3 ? props.linha3:"";
    let fontSize1=DespensaFontSizesCalculator(labelConfigState.labelConfig.themeName,"linha1",labelConfigState.labelConfig.labelSKU,linha1.length);
    let fontSize2=DespensaFontSizesCalculator(labelConfigState.labelConfig.themeName,"linha2",labelConfigState.labelConfig.labelSKU,linha2.length);
    let fontSize3=DespensaFontSizesCalculator(labelConfigState.labelConfig.themeName,"linha3",labelConfigState.labelConfig.labelSKU,linha3.length);
    return(
        <div id="label-tiketa-desplb" className="labelSimulationCard" style={{
                             zoom: isKitMember===1 ? "70%":"100%",

                         }}>
                <div
                    className={"label-desplb-" + labelConfigState.labelConfig.labelSKU}
                    style={{zIndex:1,
                        backgroundImage:`url(${labelConfigState.labelConfig.labelBackgroundImage})`,
                        backgroundRepeat: "no-repeat"}}
                >
                    <div
                        className={"label-desplb-l1-sicon-" + labelConfigState.labelConfig.labelSKU}
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.fontColor],
                            fontFamily: labelConfigState.labelConfig.fontLinha1,
                            display: linha2 === ""  ? "" : "none",
                            fontSize: fontSize1 ,
                        }}
                    >
                        {linha1}
                    </div>
                    <div
                        className={"label-desplb-l2-sicon-" + labelConfigState.labelConfig.labelSKU}
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.fontColor],
                            fontFamily: labelConfigState.labelConfig.fontLinha1,
                            display: linha2 !== ""  ? "" : "none",
                            fontSize: fontSize2,
                        }}
                    >
                        {linha2}
                    </div>
                    <div
                        className={"label-desplb-l3-sicon-" + labelConfigState.labelConfig.labelSKU}
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.fontColor],
                            fontFamily: labelConfigState.labelConfig.fontLinha2,
                           display: linha2 !== ""  ? "" : "none",
                            fontSize: fontSize3,
                        }}
                    >
                        {linha3}
                    </div>

                </div>
        </div>
    );
}