import axios from "axios";
import {authActions} from '../store/authSlice';

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch(authActions.userLoginRequest())

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const {data} = await axios.post('/api/users/login/',
            {'username': email, 'password': password},
            config
        )
        dispatch(authActions.userLoginSuccess(data))
        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch(authActions.userLoginFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}
export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch(authActions.userLogout())
}
