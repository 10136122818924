import {createSlice} from "@reduxjs/toolkit";


const cartInitialState = {
    cart: {
        items: [],
        totalAmmount: 0,
        shippingCost: 0,
        shippingMethod: "Levantamento na Loja",
        client_data: {
            client_name: "",
            client_email: "",
            client_phone: "",
            client_address: "",
            client_city: "",
            client_postal_code: "",
        },
    }
};

const cartSlice=createSlice({
    name: 'cart',
    initialState: cartInitialState,
    reducers: {
        addItem2Cart(state,action) {
           state.cart.items = [...state.cart.items,action.payload];
           if(action.payload.labelConfig.lotePrice!=="") {
               if(action.payload.labelConfig.subOptionsValue!=="") {
                   state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) + (parseFloat(action.payload.labelConfig.quantity) * (parseFloat(action.payload.labelConfig.lotePrice) + parseFloat(action.payload.labelConfig.subOptionsValue)));
                   state.cart.totalAmmount = state.cart.totalAmmount.toFixed(2);
               } else {
                   state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) + (parseFloat(action.payload.labelConfig.quantity) * parseFloat(action.payload.labelConfig.lotePrice));
                   state.cart.totalAmmount = state.cart.totalAmmount.toFixed(2);
               }
           } else if(action.payload.labelConfig.subOptionsValue!=="") {
               if(action.payload.labelConfig.lotePrice!=="") {
                    state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) + (parseFloat(action.payload.labelConfig.quantity) * (parseFloat(action.payload.labelConfig.lotePrice) + parseFloat(action.payload.labelConfig.subOptionsValue)));
                    state.cart.totalAmmount = state.cart.totalAmmount.toFixed(2);
               } else {
                   state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) + (parseFloat(action.payload.labelConfig.quantity) * (parseFloat(action.payload.labelConfig.price) + parseFloat(action.payload.labelConfig.subOptionsValue)));
                   state.cart.totalAmmount = state.cart.totalAmmount.toFixed(2);
               }
           } else {
               state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) + (parseFloat(action.payload.labelConfig.quantity) * parseFloat(action.payload.labelConfig.price));
               state.cart.totalAmmount = state.cart.totalAmmount.toFixed(2);
           }
        },
        removeItemFromCart(state,action) {
            if (state.cart.items[action.payload].labelConfig.lotePrice!=="") {
                if(state.cart.items[action.payload].labelConfig.subOptionsValue!=="") {
                    state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) - (parseFloat(state.cart.items[action.payload].labelConfig.quantity) * (parseFloat(state.cart.items[action.payload].labelConfig.subOptionsValue) + parseFloat(state.cart.items[action.payload].labelConfig.lotePrice) ));
                } else {
                    state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) - (parseFloat(state.cart.items[action.payload].labelConfig.quantity) * parseFloat(state.cart.items[action.payload].labelConfig.lotePrice));
                }
            } else if(state.cart.items[action.payload].labelConfig.subOptionsValue!=="") {
                 if(state.cart.items[action.payload].labelConfig.lotePrice!=="") {
                     state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) - (parseFloat(state.cart.items[action.payload].labelConfig.quantity) * (parseFloat(state.cart.items[action.payload].labelConfig.subOptionsValue) + parseFloat(state.cart.items[action.payload].labelConfig.lotePrice) ));
                 } else {
                    state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) - (parseFloat(state.cart.items[action.payload].labelConfig.quantity) * (parseFloat(state.cart.items[action.payload].labelConfig.subOptionsValue) + parseFloat(state.cart.items[action.payload].labelConfig.price) ));
                 }

            } else {
                state.cart.totalAmmount = parseFloat(state.cart.totalAmmount) - (parseFloat(state.cart.items[action.payload].labelConfig.quantity) * parseFloat(state.cart.items[action.payload].labelConfig.price));
            }
            state.cart.totalAmmount = state.cart.totalAmmount.toFixed(2);
            state.cart.items.splice(action.payload,1);
        },
        updateItemQuantityAndTotal(state,action) {
            let total = parseFloat((state.cart.totalAmmount));
            let lotePrice = state.cart.items[action.payload.itemNumber].labelConfig.lotePrice;
            let subOptionsValue = state.cart.items[action.payload.itemNumber].labelConfig.subOptionsValue;
            let price = state.cart.items[action.payload.itemNumber].labelConfig.price;
            let qty = state.cart.items[action.payload.itemNumber].labelConfig.quantity;

            if (state.cart.items[action.payload.itemNumber].labelConfig.lotePrice!=="") {
                if(subOptionsValue!=="") {
                     state.cart.totalAmmount = total - (parseInt(qty) * (parseFloat(lotePrice) + parseFloat(subOptionsValue))) + (parseInt(action.payload.qtd) * (parseFloat(lotePrice) + parseFloat(subOptionsValue)));
                } else {
                    state.cart.totalAmmount = total - (parseInt(state.cart.items[action.payload.itemNumber].labelConfig.quantity) * lotePrice) + (parseInt(action.payload.qtd) * lotePrice);
                }
            } else if(subOptionsValue!=="") {
                if(state.cart.items[action.payload.itemNumber].labelConfig.lotePrice!=="") {
                    state.cart.totalAmmount = total - (parseInt(qty) * (parseFloat(lotePrice) + parseFloat(subOptionsValue))) + (parseInt(action.payload.qtd) * (parseFloat(lotePrice) + parseFloat(subOptionsValue)));
                } else {
                    state.cart.totalAmmount = total - (parseInt(qty) * (parseFloat(price) + parseFloat(subOptionsValue))) + (parseInt(action.payload.qtd) * (parseFloat(price) + parseFloat(subOptionsValue)));
                }
            } else {
                state.cart.totalAmmount = total - (parseInt(state.cart.items[action.payload.itemNumber].labelConfig.quantity) * state.cart.items[action.payload.itemNumber].labelConfig.price) + (parseInt(action.payload.qtd) * state.cart.items[action.payload.itemNumber].labelConfig.price);
            }
            state.cart.totalAmmount = state.cart.totalAmmount.toFixed(2);
            state.cart.items[action.payload.itemNumber].labelConfig.quantity = action.payload.qtd;
        },
        updateClientData(state,action) {
            state.cart.client_data.client_name = action.payload.clientName;
            state.cart.client_data.client_email = action.payload.clientEmail;
            state.cart.client_data.client_phone = action.payload.clientPhone;
        },
        resetCart(state) {
            state.cart.items=[];
            state.cart.totalAmmount = 0;
            state.cart.shippingCost = 0;
            state.cart.shippingMethod = "Levantamento na Loja";
            state.cart.client_data.client_name = "";
            state.cart.client_data.client_email = "";
            state.cart.client_data.client_phone = "";
            state.cart.client_data.client_address = "";
            state.cart.client_data.client_city = "";
            state.cart.client_data.client_postal_code = "";
        },
        updateShippingData(state,action) {
            state.cart.client_data.clientAddress = action.payload.clientAddress;
            state.cart.client_data.clientPostalCode = action.payload.clientPostalCode;
            state.cart.client_data.clientCity = action.payload.clientCity;
            state.cart.shippingCost = action.payload.shippingCost;
            state.cart.shippingMethod= action.payload.shippingMethod;
        },
    },
});
export const cartActions = cartSlice.actions;
export default cartSlice;
