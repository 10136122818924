import "./css/BackgroundAndTextColorSelector.css";
import React, {useState} from "react";
/*
* Componente usado para selecionar as cores do texto dos autocolantes para caixa de medicamentos
* */
const txtColors = [
    {id: "blackTx", classCss: "single-color color-add preto-inverted",desc:"Preto"},
    {id: "greenTx", classCss: "single-color color-add verde",desc:"Verde"},
    {id: "blueTx", classCss: "single-color color-add azul",desc:"Azul"},
    {id: "pinkTx", classCss: "single-color color-add rosa",desc:"Rosa"},
    {id: "redTx", classCss: "single-color color-add vermelho",desc:"Vermelho"},
    {id: "purpleTx", classCss: "single-color color-add roxo",desc:"Roxo"},
    {id: "orangeTx", classCss: "single-color color-add laranja",desc:"Laranja"},
];
const disableBkColors = {
    whiteTx: ["whiteBk", "yellowBk"],
    blackTx: ["dkBlueBk", "blueBk", "dkGreenBk", "dkPurpleBk"],
    greenTx: [
        "redBk",
        "yellowBk",
        "orangeBk",
        "lhtGreyBk",
        "lhtPinkBk",
        "pinkBk",
        "dkBlueBk",
        "blueBk",
        "lhtBlueBk",
        "dkGreenBk",
        "greenBk",
        "lhtGreenBk",
        "dkPurpleBk",
        "purpleBk",
    ],
    blueTx: [
        "redBk",
        "yellowBk",
        "orangeBk",
        "lhtGreyBk",
        "lhtPinkBk",
        "pinkBk",
        "dkBlueBk",
        "blueBk",
        "lhtBlueBk",
        "dkGreenBk",
        "greenBk",
        "lhtGreenBk",
        "dkPurpleBk",
        "purpleBk",
    ],
    pinkTx: [
        "redBk",
        "yellowBk",
        "orangeBk",
        "lhtGreyBk",
        "lhtPinkBk",
        "pinkBk",
        "dkBlueBk",
        "blueBk",
        "lhtBlueBk",
        "dkGreenBk",
        "greenBk",
        "lhtGreenBk",
        "dkPurpleBk",
        "purpleBk",
    ],
    redTx: [
        "redBk",
        "yellowBk",
        "orangeBk",
        "lhtGreyBk",
        "lhtPinkBk",
        "pinkBk",
        "dkBlueBk",
        "blueBk",
        "lhtBlueBk",
        "dkGreenBk",
        "greenBk",
        "lhtGreenBk",
        "dkPurpleBk",
        "purpleBk",
    ],
    purpleTx: [
        "redBk",
        "yellowBk",
        "orangeBk",
        "lhtGreyBk",
        "lhtPinkBk",
        "pinkBk",
        "dkBlueBk",
        "blueBk",
        "lhtBlueBk",
        "dkGreenBk",
        "greenBk",
        "lhtGreenBk",
        "dkPurpleBk",
        "purpleBk",
    ],
    orangeTx: [
        "redBk",
        "yellowBk",
        "orangeBk",
        "lhtGreyBk",
        "lhtPinkBk",
        "pinkBk",
        "dkBlueBk",
        "blueBk",
        "lhtBlueBk",
        "dkGreenBk",
        "greenBk",
        "lhtGreenBk",
        "dkPurpleBk",
        "purpleBk",
    ],
};


function TextLabelsColorSelector(props) {
    const [txtColorSel, setTxtColorSel] = useState(props.selectedTxtColor);

    const selectTxtColorHandler = (event) => {
        let selectedTxtColor = event.target.getAttribute("data-txtcolor");
        let selectedTxtColorDesc = event.target.getAttribute("data-colordesc");
        setTxtColorSel(selectedTxtColor);
        props.onSelectedTexColor(
            selectedTxtColor,
            disableBkColors[selectedTxtColor],
            selectedTxtColorDesc
        );
    };

    return (
        <div>
            <label className="field">Cor do Texto:</label>
            <div className="field-selection">
                {txtColors.map(({id, classCss,desc}) => {
                    return (
                        <div
                            key={id}
                            id={id}
                            data-txtcolor={id}
                            data-colordesc={desc}
                            className={id === txtColorSel ? classCss + " selected" : classCss}
                            style={{fontFamily: id}}
                            onClick={selectTxtColorHandler}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default TextLabelsColorSelector;
