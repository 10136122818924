import "./NewLabelForm.css";
import BackgroundColorSelector from "./labelsOptionsSelectors/BackgroundColorSelector";
import IconSelector from "./labelsOptionsSelectors/IconSelector";
import InputFieldSelector from "./labelsOptionsSelectors/InputFieldSelector";
import FontSelector from "./labelsOptionsSelectors/FontSelector";
import QuantityAndSubmitPanel from "./labelsOptionsSelectors/QuantityAndSubmitPanel";
import TextColorSelector from "./labelsOptionsSelectors/TextColorSelector";
import ThemeSelector from "./labelsOptionsSelectors/ThemeSelector";
import DespensaThemeSelector from "./labelsOptionsSelectors/DespensaThemeSelector";
import {Tabs, Tab} from "react-bootstrap";
import LoteSelector from "./labelsOptionsSelectors/LoteSelector";
import ModelSelector from "./labelsOptionsSelectors/ModelSelector";
import ProductDescription from "./labelsOptionsSelectors/ProductDescription";
import {useNavigate, useParams} from "react-router-dom";
import React, {Fragment, useEffect, useRef, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {
    updateLinha1,
    updateLinha2,
    updateLinha3,
    updateLinhaLC,
    updateLinhaLCM,
    updateSelectedIcon,
    updateThemeAttrib,
    updateLabelTitle4Cart,
    updateModel,
    updateTextColor,
    updateBkColor,
    updateTxtFont,
    updateLote,
    updateLabelSimulationType,
    updateQuantity,
    updatePrice,
    updateSubjects,
    updateARP,
    updatesubOptionsValue,
    updateSelectedSubOptionsCodes,
    updateSelectedSubOptionsDescriptions,
    updateOptionsDescriptions,
    updateDICLabels, updateTextLabelsObjects, updateDespensaThemeAttrib, updateDespensaBackgroundImage,
    updateHasPersonalizedOptions, updateIsKit
} from "../../actions/labelConfigActions";
import SchoolSubjectSelector from "./labelsOptionsSelectors/SchoolSubjectSelector";
import ARPCustomSelectField from "./labelsOptionsSelectors/ARPCustomSelectField";
import PersonalizedOptionsSelector from "./labelsOptionsSelectors/PersonalizedOptionsSelector";
import FeetTextColorSelector from "./labelsOptionsSelectors/FeetTextColorSelector";
import TextLabelsColorSelector from "./labelsOptionsSelectors/TextLabelsColorSelector";
import TextLabelsSelector from "./labelsOptionsSelectors/TextLabelsSelector";
import ShoeLabelsLeftRightThemeSelector from "./labelsOptionsSelectors/ShoeLabelsLeftRightThemeSelector";



const inputFieldLCTitle = "Lapis e Canetas:(max: 30 carateres)";
const inputFieldLCMTitle = "Lapis e Canetas Mini:(max: 20 carateres)";


const fontFieldTitle = "Tipos de Letra:";
const fontFieldOptions = [{
    id: "TL1", font: "TL1",
}, {
    id: "TL2", font: "TL2",
}, {
    id: "TL3", font: "TL3",
}, {
    id: "TL4", font: "TL4",
}, {
    id: "TL5", font: "TL5",
}, {
    id: "TL6", font: "TL6",
}, {
    id: "TL7", font: "TL7",
}, {
    id: "TL8", font: "TL8",
},
];
const loteFieldTitle = "Lote:";

export default function NewLabelForm(props) {
    const {userInfo} = useSelector((state) => state.userLogin);
    const labelConfigState = useSelector((state)=> state.labelConfig );
    const history = useNavigate();
    const {sku} = useParams();
    const dispatch = useDispatch();
    const labelImg = useRef();
    // get product info from API
    const [tiketas, setTiketas] = useState([]);
    // get lotes info from API
    const [lotes, setLotes] = useState([]);
    // get icon groups from API
    const [iconsGroups, setIconsGroups] = useState([]);
    // get text groups from API
    const [textGroups, setTextGroups] = useState([]);
    // get labels text groups from API
    const [labelsTextGroups, setLabelsTextGroups] = useState([]);
    // get all Icons
    const [icons, setIcons] = useState([]);
    // get all Themes
    const [themes, setThemes] = useState([]);
    // get all subjects
    const [subjects, setSubjects] = useState([]);
    // get all personalized options
    const [personOptions, setPersonOptions] = useState([]);
    // get all personalized suboptions
    const [personSubOptions, setPersonSubOptions] = useState([]);
    // get all depensa themes
    const [despensaThemes, setDespensaThemes] = useState([]);
    //
    //const [subOptionsValue, setSubOptionsValue] = useState([]);
    let selectedSubOptions,selectedSubOptionsDescriptions,optionsDescriptions;
    useEffect(() => {
        if (userInfo) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            async function fetchTiketas() {
                const {data} = await axios.get(`/api/get_product/?sku=${sku}`, config);
                setTiketas(data);
            }

            async function fetchLotes() {
                const {data} = await axios.get(`/api/get_product_lotes/?sku=${sku}`, config);
                setLotes(data);
            }
            // get icons groups
            async function fetchIconsGroups() {
                const {data} = await axios.get(`/api/get_icons_groups/?sku=${sku}`, config);
                setIconsGroups(data);
            }

            // get icons
            async function fetchIcons() {
                const {data} = await axios.get(`/api/get_all_icons/`, config);
                setIcons(data);
            }
            // get themes
            async function fetchThemes() {
                const {data} = await axios.get(`/api/get_all_themes/?sku=${sku}`, config);
                setThemes(data);
            }
            // get subjects
            async function fetchSubjects() {
                const {data} = await axios.get(`/api/get_all_subjects/`, config);
                setSubjects(data);
            }
            async function fetchPersonalizedOptions() {
                const {data} = await axios.get(`/api/get_personalized_options/?sku=${sku}`, config);
                setPersonOptions(data);
            }
            async function fetchPersonalizedSubOptions() {
                const {data} = await axios.get(`/api/get_personalized_suboptions/?sku=${sku}`, config);
                setPersonSubOptions(data);
            }
            // get text groups by product_sku
            async function fetchTextGroups() {
                const {data} = await axios.get(`/api/get_text_groups/?sku=${sku}`, config);
                setTextGroups(data);
            }
            // get all label text groups by product_sku
            async function fetchLabelsTextGroups() {
                const {data} = await axios.get(`/api/get_labels_text_groups/?sku=${sku}`, config);
                setLabelsTextGroups(data);
            }
            // get  Text Labels themes for despensa labels
            async function fetchLabelsTextThemes() {
                const {data} = await axios.get(`/api/get_all_labels_text_themes/?sku=${sku}`, config);
                setDespensaThemes(data);
            }
            fetchSubjects();
            fetchThemes();
            fetchIcons();
            fetchIconsGroups();
            fetchTiketas();
            fetchLotes();
            fetchPersonalizedOptions();
            fetchPersonalizedSubOptions();
            fetchTextGroups();
            fetchLabelsTextGroups();
            fetchLabelsTextThemes();


        } else {
            history("/login/");
        }

    }, [sku]);
    useEffect(()=> {
        // update has personalized options
        dispatch(updateHasPersonalizedOptions(tiketas.length !== 0 ? tiketas[0].has_personalized_options : false));
        // set title for cart description
        dispatch(updateLabelTitle4Cart(tiketas.length !== 0 ? tiketas[0].title : ""));
        // update iskit field
        dispatch(updateIsKit(tiketas.length !== 0 ? tiketas[0].is_kit : false));
        // update base price for kits or produts without lotes
        dispatch(updatePrice(tiketas.length !== 0 ? tiketas[0].price : 0));
        // update Label simulation type for elder stikers
        if (tiketas[0]?.has_themes === false)
            dispatch(updateLabelSimulationType("icons"));
        // create initial selected suboption array of objects
        if (personSubOptions.length > 0) {
            selectedSubOptions = Object.assign({}, ...personSubOptions.filter((subOption) => subOption.is_default_selected === true).map((x) => ({[x.option_code]: x.sub_option_code})));
            selectedSubOptionsDescriptions = Object.assign({}, ...personSubOptions.filter((subOption) => subOption.is_default_selected === true).map((x) => ({[x.option_code]: x.sub_option_desc})));
        }

        if(personOptions.length > 0) {
            optionsDescriptions = Object.assign({}, ...personOptions.map((x) => ({[x.option_code]: x.option_desc})));
            dispatch(updateOptionsDescriptions(optionsDescriptions));
        }
        //console.log(selectedSubOptionsDescriptions);
        if (personSubOptions.length > 0 && labelConfigState.labelConfig.selectedSubOptionsCodes === "") {
            dispatch(updateSelectedSubOptionsCodes(selectedSubOptions));
            dispatch(updateSelectedSubOptionsDescriptions(selectedSubOptionsDescriptions));
            //dispatch(updateOptionsDescriptions(optionsDescriptions));

        }
        //console.log(sku);
        //console.log(labelConfigState.labelConfig.despensaTheme);
        //if(sku==="ANTG" && labelConfigState.labelConfig.despensaTheme==="Tema 1" )
        //    console.log("Entrou no theme");
        // se calhar vou ter de alterar aqui para actualizar com a imagem de background da esquerda direita
        if(labelConfigState.labelConfig.labelBackgroundImage==="")
            dispatch(updateDespensaBackgroundImage(despensaThemes.length !== 0 ? despensaThemes[0].theme_label_background_image:""));
        // alterei aqui para acrescentar as dependencias ao tiketas e ao personSubOptions
    },[tiketas,personSubOptions,despensaThemes,personOptions]);


    const inputFieldLinha1Title = "Linha 1: (max: " + (tiketas.length !== 0 && (tiketas[0].sku === "AM" || tiketas[0].sku === "TM" || tiketas[0].sku === "KRCR" || tiketas[0].sku === "TMR" || tiketas[0].sku === "AMR" || tiketas[0].sku === "EPED") ? "15" : tiketas.length !== 0 && tiketas[0].sku === "EFG" ? "30" : "20") + " carateres)";
    const inputFieldLinha2Title = "Linha 2: (max: " + (tiketas.length !== 0 && (tiketas[0].sku === "AM" || tiketas[0].sku === "TM" || tiketas[0].sku === "KRCR" || tiketas[0].sku === "TMR" || tiketas[0].sku === "AMR" || tiketas[0].sku === "EPED") ? "15" : tiketas.length !== 0 && tiketas[0].sku === "EFG" ? "30" : "20") + " carateres)";
    const inputFieldLinha3Title = "Linha 3: (max: " + (tiketas.length !== 0 && (tiketas[0].sku === "AM" || tiketas[0].sku === "TM" || tiketas[0].sku === "TMR" || tiketas[0].sku === "AMR" || tiketas[0].sku === "EPED") ? "15" : tiketas.length !== 0 && tiketas[0].sku === "EFG" ? "30" : "20") + " carateres)";

    const enteredlinha1Handler = (enteredLinha1Data) => {
        dispatch(updateLinha1(enteredLinha1Data));
    };
    const enteredlinha2Handler = (enteredLinha2Data) => {
        dispatch(updateLinha2(enteredLinha2Data));
    };
    const enteredlinha3Handler = (enteredLinha3Data) => {
        dispatch(updateLinha3(enteredLinha3Data));
    };
    const enteredlinhaLCHandler = (enteredLinhaLCData) => {
        dispatch(updateLinhaLC(enteredLinhaLCData));
    };
    const enteredlinhaLCMHandler = (enteredLinhaLCMData) => {
        dispatch(updateLinhaLCM(enteredLinhaLCMData));
    };
    const selectedIconHandler = (selectedIcon,selectedIconDesc,selectedIconGroupCode) => {
        let iconAttrib = {
          icon: selectedIcon,
          iconDesc: selectedIconDesc,
          iconGroupCode: selectedIconGroupCode,
        };
        dispatch(updateSelectedIcon(iconAttrib));
    };
    const selectedThemeHandler = (selectedTheme, iconEsq, backColorEsq, fontColorEsq, iconDir, backColorDir, fontColorDir,selectedThemeDesc) => {
        let themeAttrib = {
            theme: selectedTheme,
            themeDesc: selectedThemeDesc,
            iconEsq: iconEsq,
            backColorEsq: backColorEsq,
            fontColorEsq: fontColorEsq,
            iconDir: iconDir,
            backColorDir: backColorDir,
            fontColorDir: fontColorDir,
        };
        dispatch(updateThemeAttrib(themeAttrib));
    };
    const selectedModelHandler = (selectedModel) => {
        dispatch(updateModel(selectedModel));
    };
    const selectedTextColorHandler = (selectedTextColor, disabledBkColorArray,colorDesc) => {
        console.log(textGroups);
        let colorObj = {
            txtColor: selectedTextColor,
            disabledBkColors: disabledBkColorArray,
            bkColor: (selectedTextColor === "whiteTx") ? "redBk" : "whiteBk",
            bkColorDesc: (selectedTextColor === "whiteTx") ? "Vermelho" : "Branco",
            colorDesc: colorDesc,
        };
        console.log(colorObj);
        dispatch(updateTextColor(colorObj));
    };
    const selectedBkColorHandler = (selectedBkColor,selectedBkColorDesc) => {
        let bkColorObj = {
            bkColor: selectedBkColor,
            bkColorDesc: selectedBkColorDesc,
        };
        dispatch(updateBkColor(bkColorObj));
    };
    const selectedFontHandler = (selectedFont) => {
        dispatch(updateTxtFont(selectedFont));
    };
    const selectedLoteHandler = (selectedLote, price,description,loteRef) => {
        let loteConfig = {
            lote: selectedLote,
            lotePrice: price,
            loteDesc : description,
            loteRef : loteRef,
        };
        dispatch(updateLote(loteConfig));
    };
    const changedSubOptionsHandler = (value2Add2Price,selectedOptions) => {
        selectedSubOptionsDescriptions = Object.assign({}, ...personSubOptions.filter((subOption) => Object.values(selectedOptions).includes(subOption.sub_option_code)).map((x) => ({[x.option_code]: x.sub_option_desc})));
        dispatch(updatesubOptionsValue(value2Add2Price));
        dispatch(updateSelectedSubOptionsCodes(selectedOptions));
        dispatch(updateSelectedSubOptionsDescriptions(selectedSubOptionsDescriptions));

    }
    const selectedLabelTabHandler = (event) => {
        dispatch(updateLabelSimulationType(event));
    };
    const enteredQuantity = (enteredQuantity) => {
        dispatch(updateQuantity(enteredQuantity));
        // Não sei se devia chamar aqui, mas fora dá erro, devia estar num metodo ao carregar no botão submit que fizesse submit depois desta chamada
        //dispatch(updateLabelTitle4Cart(tiketas.length !== 0 ? tiketas[0].title : ""));
    };
    const selectedSubjectsHandler = (selectedSubjectsObj) => {
        dispatch(updateSubjects(selectedSubjectsObj));
    };
    const selectedARPHandler = (selectedARPOption,selectedARPDesc) => {
        let ARPObj = {
            code: selectedARPOption,
            description: selectedARPDesc
        }
        dispatch(updateARP(ARPObj));
    };
    const selectedTextLabelsHandler = (selectedTextLabelObj) => {
        dispatch(updateTxtFont("TL3")); // update font para calibri nas etiquetas  EACXMED
        dispatch(updateTextLabelsObjects(selectedTextLabelObj));
    };
    const selectedDespensaThemeHandler = (selectedTheme, themeName, fontLinha1, fontLinha2, fontColor, labelBackgroundImage) => {
        let despensaThemeAttrib = {
            theme: selectedTheme,
            themeName: themeName,
            fontLinha1: fontLinha1,
            fontLinha2: fontLinha2,
            fontColor: fontColor,
            labelBackgroundImage: labelBackgroundImage,
            labelBackgroundImage2: "",
        };
        dispatch(updateDespensaThemeAttrib(despensaThemeAttrib));
    };
     const selectedShoeLabelsThemeHandler = (selectedTheme, themeName, fontLinha1, fontLinha2, fontColor, labelBackgroundImage) => {
        let despensaThemeAttrib = {
            theme: selectedTheme,
            themeName: themeName,
            fontLinha1: fontLinha1,
            fontLinha2: fontLinha2,
            fontColor: fontColor,
            labelBackgroundImage: labelBackgroundImage,
            labelBackgroundImage2: "",
        };
        // alterar aqui
        dispatch(updateDespensaThemeAttrib(despensaThemeAttrib));
    };
    return (<div>
        <ProductDescription
            title={tiketas.length !== 0 ? tiketas[0].title : ""}
            description={tiketas.length !== 0 ?  tiketas[0].short_description : ""}
            productPrice={labelConfigState.labelConfig.lotePrice !== "" ? labelConfigState.labelConfig.subOptionsValue!==0 ? (parseFloat(labelConfigState.labelConfig.lotePrice) + parseFloat(labelConfigState.labelConfig.subOptionsValue)).toFixed(2):
                labelConfigState.labelConfig.lotePrice :
                    labelConfigState.labelConfig.subOptionsValue!==0 ?
                        (parseFloat(labelConfigState.labelConfig.price) + parseFloat(labelConfigState.labelConfig.subOptionsValue)).toFixed(2):
                        labelConfigState.labelConfig.price
                //tiketas.length !== 0 ? tiketas[0].price : ""
                    }
        />
        {(tiketas.length !== 0 && (tiketas[0].sku==="EAESPM" || tiketas[0].sku==="EAESPR" || tiketas[0].sku==="EAESPG") ) &&
            <Fragment>
                <DespensaThemeSelector
                    selectedDespensaTheme={labelConfigState.labelConfig.despensaTheme}
                    onSelectedDespensaTheme={selectedDespensaThemeHandler}
                    despensaThemes={despensaThemes.length !== 0 ? despensaThemes : []}/>
                <TextLabelsSelector
                    selectedTab  = {tiketas[0].sku==="EAESPM" ? 9 : tiketas[0].sku==="EAESPR" ? 18 : 27}
                    labelsGroups={textGroups.length !== 0 ? textGroups :[]}
                    textLabels = {labelsTextGroups.length !== 0 ? labelsTextGroups :[]}
                    arraySelectedTextLabels={labelConfigState.labelConfig.textLabelsObjects}
                    onSelectedTextLabels={selectedTextLabelsHandler}
                    options2Select={tiketas.length !== 0 ? tiketas[0].number_of_options_2_select:"10"}
                />
            </Fragment>
        }
        {tiketas.length !== 0 && tiketas[0].sku==="EPED" &&
                <ShoeLabelsLeftRightThemeSelector
                    selectedDespensaTheme={labelConfigState.labelConfig.despensaTheme}
                    onSelectedDespensaTheme={selectedDespensaThemeHandler}
                    despensaThemes={despensaThemes.length !== 0 ? despensaThemes : []}/>
        }
        {tiketas.length !== 0 && tiketas[0].sku==="ANTG" &&
                <ShoeLabelsLeftRightThemeSelector
                    selectedDespensaTheme={labelConfigState.labelConfig.despensaTheme}
                    onSelectedDespensaTheme={selectedDespensaThemeHandler}
                    despensaThemes={despensaThemes.length !== 0 ? despensaThemes : []}/>
        }
        {(tiketas.length !== 0 && (tiketas[0].has_themes !== false || tiketas[0].has_icons !== false)) &&
            <Tabs onSelect={selectedLabelTabHandler} defaultActiveKey={tiketas.length !== 0 && tiketas[0].has_themes === false ? "icons" : labelConfigState.labelConfig.labelSimulationType}
                  className="nav-fill">
                {tiketas.length !== 0 && tiketas[0].has_themes === true &&
                    <Tab eventKey="themes" title="Temas Pré-definidos">
                        <ThemeSelector
                            selectedTheme={labelConfigState.labelConfig.theme}
                            onSelectedTheme={selectedThemeHandler}
                            themes={themes.length !== 0 ? themes : []}
                        />
                        <ModelSelector
                            selectedModel={labelConfigState.labelConfig.model}
                            onSelectedModel={selectedModelHandler}
                        />
                    </Tab>
                }
                <Tab eventKey="icons" title="Personalize a Gosto">
                    <IconSelector
                        selectedIcon={labelConfigState.labelConfig.icon}
                        selectedGroup={labelConfigState.labelConfig.groupCode}
                        onSelectedIcon={selectedIconHandler}
                        categories={iconsGroups.length !== 0 ? iconsGroups : []}
                        icons={icons.length !== 0 ? icons : []}
                    />
                    <TextColorSelector
                        selectedTxtColor={labelConfigState.labelConfig.txtColor}
                        onSelectedTexColor={selectedTextColorHandler}
                    />
                    <BackgroundColorSelector
                        disableColors={labelConfigState.labelConfig.disableBkColors}
                        selectedBkColor={labelConfigState.labelConfig.bkColor}
                        onSelectedBkColor={selectedBkColorHandler}
                    />
                </Tab>
            </Tabs>
        }
        {(tiketas.length !== 0 &&  tiketas[0].sku === "ECPE") &&
            <FeetTextColorSelector
                        selectedTxtColor={labelConfigState.labelConfig.txtColor}
                        onSelectedTexColor={selectedTextColorHandler}
            />
        }
        {(tiketas.length !== 0 &&  tiketas[0].sku === "EACXMED") &&
            <TextLabelsColorSelector
                        selectedTxtColor={labelConfigState.labelConfig.txtColor}
                        onSelectedTexColor={selectedTextColorHandler}
            />
        }
        <InputFieldSelector
            title={inputFieldLinha1Title}
            id="linha1"
            onEnteredText={enteredlinha1Handler}
            fieldValue={{fieldId: "linha1", fieldValue: labelConfigState.labelConfig.linha1 }}
            maxlength={tiketas.length !== 0 && (tiketas[0].sku === "AM" || tiketas[0].sku === "TM" || tiketas[0].sku === "KRCR" || tiketas[0].sku === "TMR" || tiketas[0].sku === "AMR" || tiketas[0].sku === "EPED") ? "15" : tiketas.length !== 0 && tiketas[0].sku === "EFG" ? "30" : "20"}
            style={{display: (tiketas.length !== 0 && (tiketas[0].n_lines === 2 || tiketas[0].n_lines === 3)) ? "" : "none"}}
        />
        <InputFieldSelector
            title={inputFieldLinha2Title}
            id="linha2"
            onEnteredText={enteredlinha2Handler}
            fieldValue={{fieldId: "linha2", fieldValue: labelConfigState.labelConfig.linha2 }}
            maxlength={tiketas.length !== 0 && (tiketas[0].sku === "AM" || tiketas[0].sku === "TM" || tiketas[0].sku === "KRCR" || tiketas[0].sku === "TMR" || tiketas[0].sku === "AMR" || tiketas[0].sku === "EPED") ? "15" : tiketas.length !== 0 && tiketas[0].sku === "EFG" ? "30" : "20"}
            style={{display: (tiketas.length !== 0 && (tiketas[0].n_lines === 2 || tiketas[0].n_lines === 3)) ? "" : "none"}}
        />
        <InputFieldSelector
            title={inputFieldLinha3Title}
            id="linha3"
            onEnteredText={enteredlinha3Handler}
            fieldValue={{fieldId: "linha3", fieldValue: labelConfigState.labelConfig.linha3 }}
            maxlength={tiketas.length !== 0 && tiketas[0].sku === "EFG" ? "30" : "20"}
            style={{display: (tiketas.length !== 0 && tiketas[0].n_lines === 3) ? "" : "none"}}
        />
        <InputFieldSelector
            title={inputFieldLCTitle}
            id="linhaLC"
            onEnteredText={enteredlinhaLCHandler}
            fieldValue={{fieldId: "linhaLC", fieldValue: labelConfigState.labelConfig.linhaLC }}
            maxlength="30"
            style={{display: (tiketas.length !== 0 && tiketas[0].has_lc_line === true) ? "" : "none"}}
        />
        <InputFieldSelector
            title={inputFieldLCMTitle}
            id="linhaLCM"
            onEnteredText={enteredlinhaLCMHandler}
            fieldValue={{fieldId: "linhaLCM", fieldValue: labelConfigState.labelConfig.linhaLCM }}
            maxlength="20"
            style={{display: (tiketas.length !== 0 && tiketas[0].has_lcm_line === true) ? "" : "none"}}
        />
        {(tiketas.length !== 0 && tiketas[0].n_lines !== 0) &&
            <FontSelector
                selectedFont={labelConfigState.labelConfig.txtFont}
                title={fontFieldTitle}
                options={fontFieldOptions}
                onSelectedFont={selectedFontHandler}
            />
        }
        {labelConfigState.labelConfig.labelType==="ARP" &&
            <ARPCustomSelectField
                selectedARP={labelConfigState.labelConfig.otherOptionsObjects}
                onSelectedARP={selectedARPHandler}
            />
        }
        <LoteSelector
            selectedLote={labelConfigState.labelConfig.lote}
            title={loteFieldTitle}
            options={lotes.length !== 0 ? lotes : []}
            onSelectedLote={selectedLoteHandler}
        />

        {(tiketas.length !== 0 && tiketas[0].has_personalized_options ) &&
            <PersonalizedOptionsSelector
                options={personOptions.length!==0 ? personOptions:[]}
                subOptions={personSubOptions.length!==0 ? personSubOptions:[]}
                selectedSubOptions={labelConfigState.labelConfig.selectedSubOptionsCodes}
                subOptionsValue={labelConfigState.labelConfig.subOptionsValue}
                onChangedSubOptions={changedSubOptionsHandler}
            />

        }

        {labelConfigState.labelConfig.labelType==="ADISC" &&
            <SchoolSubjectSelector
                options={subjects.length !== 0 ? subjects :[]}
                arraySelectedSubject={labelConfigState.labelConfig.otherOptionsObjects}
                onSelectedSubjects={selectedSubjectsHandler}
                options2Select={tiketas.length !== 0 ? tiketas[0].number_of_options_2_select:"10"}
            />
        }
        {labelConfigState.labelConfig.labelType==="EACXMED" &&
            <TextLabelsSelector
                selectedTab  = {1}
                labelsGroups={textGroups.length !== 0 ? textGroups :[]}
                textLabels = {labelsTextGroups.length !== 0 ? labelsTextGroups :[]}
                arraySelectedTextLabels={labelConfigState.labelConfig.textLabelsObjects}
                onSelectedTextLabels={selectedTextLabelsHandler}
                options2Select={tiketas.length !== 0 ? tiketas[0].number_of_options_2_select:"28"}
            />
        }

        <QuantityAndSubmitPanel
            quantity={labelConfigState.labelConfig.quantity}
            onEnteredQuantity={enteredQuantity}
        />

    </div>);
}
