export const bgColorObj = {
    whiteBk: "rgb(255, 255, 255)",
    redBk: "rgb(232, 32, 39)",
    yellowBk: "rgb(255, 238, 0)",
    orangeBk: "rgb(243, 100, 33)",
    lhtGreyBk: "rgb(168, 169, 172)",
    lhtPinkBk: "rgb(246, 172, 195)",
    pinkBk: "rgb(255, 43, 181)",
    dkBlueBk: "rgb(24, 71, 157)",
    blueBk: "rgb(0, 119, 178)",
    lhtBlueBk: "rgb(106, 208, 248)",
    dkGreenBk: "rgb(17, 148, 65)",
    greenBk: "rgb(67, 176, 72)",
    lhtGreenBk: "rgb(127, 194, 65)",
    dkPurpleBk: "rgb(95, 45, 145)",
    purpleBk: "rgb(132, 101, 172)",
};

export const txtColorObj = {
    redTx: "rgb(241, 38, 51)",
    blackTx: "rgb(0, 0, 0)",
    whiteTx: "rgb(255, 255, 255)",
    greenTx: "rgb(105, 192, 108)",
    blueTx: "rgb(22, 142, 206)",
    pinkTx: "rgb(237, 112, 166)",
    purpleTx: "rgb(134, 67, 173)",
    orangeTx: "rgb(243, 100, 33)",
    tema1Tx: "rgb(85,85,85)",
    tema2Tx: "rgb(254,251,247)",
    tema3Tx: "rgb(90,88,90)",
    tema4Tx: "rgb(30,32,32)",
    tema5Tx: "rgb(96,106,84)",
    tema6Tx: "rgb(161,118,104)",
    tema7Tx: "rgb(85,85,85)",
    tema1EDTx: "rgb(57,40,105)",
    tema2EDTx: "rgb(255,255,255)",
    tema3EDTx: "rgb(34,97,76)",
    tema4EDTx: "rgb(166,76,102)",
    tema5EDTx: "rgb(43,42,41)",
    tema6EDTx: "rgb(0,121,74)",
    tema7EDTx: "rgb(24,70,157)",
    tema8EDTx: "rgb(7,127,191)",
    tema9EDTx: "rgb(233,117,117)",
    tema95ANTx: "rgb(76,77,76)",
    tema96ANTx: "rgb(29,29,30)",
    tema97ANTx: "rgb(255,255,355)",
    tema98ANTx: "rgb(239,61,93)",
    tema99ANTx: "rgb(239,61,93)",
};