import "./TiketaPage.css";
import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import axios from "axios";
import LabelDiv from "../components/simulator/LabelDiv";
import {useSelector} from "react-redux";

function TiketaPage() {
    const {sku} = useParams();
    const [tiketas, setTiketas] = useState([]);
    const {userInfo} = useSelector((state) => state.userLogin);
    const history = useNavigate();

    useEffect(() => {
        async function fetchTiketa() {
            if (userInfo) {
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
                // TODO: try catch this
                const {data} = await axios.get(`/api/get_product/?sku=${sku}`, config);
                setTiketas(data);
            } else {
                // TODO: custom error message here
                history("/login/");
            }
        }

        fetchTiketa();

    }, [sku]);
    useNavigate();
    return (
        <div>
            <Row>
                <Col md={13}/>
                <LabelDiv labelSKU={sku} tiketaComp={tiketas.length !== 0 ? tiketas[0].kit_composition : ""} isKit={tiketas.length !== 0 ? tiketas[0].is_kit : false}/>
            </Row>
        </div>
    );
}

export default TiketaPage;
