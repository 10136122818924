import {bgColorObj, txtColorObj} from "./constants/LabelsColorConstants";
import "./css/AGLabels.css";
import {lineSizes} from "./constants/LabelsConstants";
import React from "react";
import {useSelector} from "react-redux";
import ADISCLabels from "./ADISCLabels";
import Carousel from 'react-bootstrap/Carousel';


export default function ADISCCarousel(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, linha4, linha5, linha6, iconSrc, icon;
    linha1=labelConfigState.labelConfig.linha1;
    linha2=labelConfigState.labelConfig.linha2;
    linha3=labelConfigState.labelConfig.linha3;
    linha4="";
    linha5="";
    linha6="";
    console.log(labelConfigState);
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";

    return(
        <div>
            {/*Se já tiver escolhido alguma disciplina aparece o carousel*/}
            {(labelConfigState.labelConfig.otherOptionsObjects.length!==0 ) &&
            <Carousel variant="dark" slide={false}>
                {labelConfigState.labelConfig.otherOptionsObjects.map((option,idx) => (
                    idx!==10 ?
                    <Carousel.Item>
                        <div className="d-block w-100" style={{margin:"100px"}} key={idx+1}>
                             <ADISCLabels linha1={option.subject_name} linha2={linha1} linha3={linha2} isKitMember={0} index={idx+1}/>
                         </div>
                     <Carousel.Caption>
                        <h5>Etiqueta {idx+1}</h5>
                     </Carousel.Caption>
                    </Carousel.Item>
                        :
                        ""
                ))}
            </Carousel> }
            {/*Se já não tiver escolhido nenhuma disciplina aparece uma etiqueta*/}
            {labelConfigState.labelConfig.otherOptionsObjects.length===0 &&  <ADISCLabels linha1="" linha2={linha1} linha3={linha2} isKitMember={0}/>}
        </div>
    );
}