import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Category.css";

export default function Category(props) {
    return(
        <Card className="my-1 p-3 rounded border-0">
            <Link
                to={props.category.has_lv2_child_catg === 1 ? "/tiketa/category/category/" + props.category.category_code : "/tiketa/category/" + props.category.category_code}
                className="btn btn-light my-2"
            >
                <Card.Img src={`/media/${props.category.image_url}`}/>
            </Link>
        </Card>
    );
}