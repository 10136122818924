import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Container, Navbar, Nav, NavDropdown, Badge, Image} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import './Header.css';
import {logout} from "../actions/userActions";
import {useNavigate,useLocation} from "react-router-dom";
import {resetLabelConfig} from "../actions/labelConfigActions";
import {resetCart} from "../actions/cartActions";

function Header() {
    const {userInfo} = useSelector(state => state.userLogin)
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname);
    const refreshPage = () => {
        window.location.reload();
    }

    const logoutHandler = () => {
        dispatch(logout())
        navigate("/login")
        refreshPage()
    }
    const changePasswordHandler = () => {
        navigate()
    }
    const logoClickHandler = () => {
        dispatch(resetLabelConfig());
        if(location.pathname === "/login") {
            navigate("/login");
        } else {
            navigate("/");
        }
    }
    const newOrderClickHandler = () => {
        dispatch(resetLabelConfig());
        dispatch(resetCart());
    }
    return (
        <header>
            <Navbar className="navbar-tiketa" variant="light" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Brand onClick={logoClickHandler} className="navbar-tiketa-brand">
                        <Image src="/media/tiketa_logo.png" width="10%" height="10%" style={{marginRight: "1em",}}/>
                        Loja Tiketa {userInfo && userInfo.isAdmin ? " - ADMINSTRAÇÃO" : userInfo && userInfo.isStaff ? " - GESTOR  DE LOJA": ""}
                    </Navbar.Brand>
                    <span className="navDireita">
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {userInfo &&
                            <LinkContainer to={userInfo ? "/cart" : "/login"}>
                                <Nav.Link className="navbar-tiketa-brand">
                                    <i className="fas fa-shopping-cart"/>
                                    <span className="badge badge-light">{cart.cart.items.length}</span>
                                    Fazer Pedido
                                </Nav.Link>
                            </LinkContainer>
                            }
                            {userInfo ? (
                                <NavDropdown title={userInfo.username} id='username' className="navbar-tiketa-brand">
                                    <LinkContainer to="/change_password">
                                        <NavDropdown.Item>Alterar Password</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Item onClick={logoutHandler}>Sair</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <LinkContainer to="/login">
                                    <Nav.Link className="navbar-tiketa-brand">
                                        <i className="fas fa-user"/>
                                        Iniciar Sessão
                                    </Nav.Link>
                                </LinkContainer>
                            )}
                            {userInfo && userInfo.isStaff ? (<LinkContainer to="/admin/order_list/Pending">
                                <Nav.Link className="navbar-tiketa-brand">
                                    Admin Control Panel
                                </Nav.Link>
                            </LinkContainer>) : userInfo && userInfo.staff && !userInfo.isAdmin ? (<LinkContainer to="/admin/store_order_list">
                                <Nav.Link className="navbar-tiketa-brand">
                                    Listagem de Pedidos
                                </Nav.Link>
                            </LinkContainer>) : "" }
                             <LinkContainer to="/" onClick={newOrderClickHandler}>
                                <Nav.Link className="navbar-tiketa-brand">
                                    Novo Pedido
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                    </span>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
