import "./LabelDiv.css";
import LabelSimulation from "./LabelSimulation";
import NewLabelForm from "./NewLabelForm";
import {Row, Col, Container} from "react-bootstrap";
import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {addItem2Cart, removeItemFromCart} from "../../actions/cartActions";
import {useDispatch, useSelector} from "react-redux";
import {
    resetLabelConfig,
    setLabelSKUandLabelType, resetFontAndSimulationType, updateImageData, updateLabelSimulationType,
} from "../../actions/labelConfigActions";

import {toPng} from "html-to-image";
import store from "../../store/index";
import Loader from "../Loader";
import MessageModal from "../UI/MessageModal";
import {labelType} from "./labels/constants/LabelsConstants";
import download from "react-csv/src/components/Download";



export default function LabelDiv(props) {
    const labelConfigState = useSelector((state) => state.labelConfig);
    const dispatch = useDispatch();
    dispatch(setLabelSKUandLabelType(props.labelSKU));
    const labelImgSingle = useRef();
    const labelImgKit = useRef();
    const navigate = useNavigate();
    const submitHandler = (event) => {
        event.preventDefault();

        if(labelConfigState.labelConfig.linha1==="" && (labelConfigState.labelConfig.linha2!=="" || labelConfigState.labelConfig.linha3!=="")) {
            alert("Por favor, preencha a linha 1");
            return;
        }
        if(labelConfigState.labelConfig.labelType==="ADISC" && labelConfigState.labelConfig.otherOptionsObjects.length <10) {
            alert("Tem de selecionar mais " + (10-labelConfigState.labelConfig.otherOptionsObjects.length) + " disciplinas!");
            return;
        }
        if(labelConfigState.labelConfig.labelType==="EACXMED" && labelConfigState.labelConfig.textLabelsObjects.length <28) {
            alert("Tem de selecionar mais " + (28-labelConfigState.labelConfig.textLabelsObjects.length) + " opções!");
            return;
        }
        if(labelConfigState.labelConfig.labelType==="EAESPM" && labelConfigState.labelConfig.textLabelsObjects.length <10) {
            alert("Tem de selecionar mais " + (10-labelConfigState.labelConfig.textLabelsObjects.length) + " opções!");
            return;
        }
        if((labelConfigState.labelConfig.labelType==="EAESPR" || labelConfigState.labelConfig.labelType==="EAESPG") && labelConfigState.labelConfig.textLabelsObjects.length <8) {
            alert("Tem de selecionar mais " + (8-labelConfigState.labelConfig.textLabelsObjects.length) + " opções!");
            return;
        }
        if(labelConfigState.labelConfig.labelType==="ARP" && labelConfigState.labelConfig.otherOptionsCodes ==="") {
            alert("Selecione um idioma, por favor!");
            return;
        }
        if(["BM","EABOL","DCCAA","DCCAT","EFMN","EFM","PI","MP"].includes(labelConfigState.labelConfig.labelType) || labelConfigState.labelConfig.labelType.substring(0,5)==="EAESP") {
             dispatch(resetFontAndSimulationType());
        }


        const formAction = event.target.getAttribute("data-action");
        if (labelConfigState.labelConfig.labelType.charAt(0) === "K") {
            if (labelImgKit.current === null) {
                return;
            }
            //console.log(labelImgKit.current);
            document.getElementById("backdrop").style.visibility="";
            toPng(labelImgKit.current, {cacheBust: false})
                .then((dataUrl) => {
                   if(formAction==="update") {
                        dispatch(removeItemFromCart(labelConfigState.labelConfig.cartItemNumber));
                    }
                    dispatch(updateImageData(dataUrl));
                    dispatch(addItem2Cart(store.getState().labelConfig));
                    dispatch(resetLabelConfig());
                    document.getElementById("backdrop").style.visibility="hidden";
                    navigate('/');
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (labelImgSingle.current === null) {
                return;
            }
            //console.log(labelImgSingle.current);
            document.getElementById("backdrop").style.visibility="";
            toPng(labelImgSingle.current, {cacheBust: false})
                .then((dataUrl) => {
                    if(formAction==="update") {
                        dispatch(removeItemFromCart(labelConfigState.labelConfig.cartItemNumber));
                    }
                    dispatch(updateImageData(dataUrl));
                    dispatch(addItem2Cart(store.getState().labelConfig));
                    dispatch(resetLabelConfig());
                    document.getElementById("backdrop").style.visibility="hidden";
                    navigate('/');
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        event.preventDefault();
    };


    const resetPrintDataHandler = () => {
        dispatch(resetLabelConfig());
    }

    return (
        <Fragment>
                 <div id="backdrop" className="backdrop" style={{visibility: "hidden"}}><MessageModal title="Tiketa.pt" message="A adicionar o produto ao pedido..."/></div>
                <form onSubmit={submitHandler} data-action={labelConfigState.labelConfig.cartItemNumber==="-1" ? "buy":"update"}>

                <Link to="/" onClick={resetPrintDataHandler}>
                    Voltar
                </Link>
                <Container>
                    <Row >
                       <Col sm={6}>
                            <LabelSimulation
                                tiketaComp={props.tiketaComp}
                                labelImgSingle={labelImgSingle}
                                labelImgKit={labelImgKit}
                                isKit={props.isKit}
                            />
                        </Col>
                        <Col sm={6}>
                            <NewLabelForm/>
                        </Col>
                    </Row>
                </Container>
            </form>
        </Fragment>);
}
