import "./css/CustomSelectField.css";
import {Fragment, useState} from "react";

export default function ARPCustomSelectField(props) {
    const [arpSelected, setArpSelected] = useState(props.selectedARP.code);

    const selectARPHandler = (event) => {
        var select = event.target;//document.getElementById('arpSelect');
        var valueCod = select.options[select.selectedIndex].value;
        var valueDesc = select.options[select.selectedIndex].innerText;
        setArpSelected(valueCod);
        props.onSelectedARP(valueCod,valueDesc);
    }

    return(
        <Fragment>
            <label className="field">Idioma das mensagens :</label>
            <select name="arp" id="arpSelect" title="" onChange={selectARPHandler} defaultValue={props.selectedARP.code }>
                <option value="">-- Por favor, selecione --</option>
                <option value='PT'>Português</option>
                <option value='EN'>Inglês</option>
                <option value='ES'>Espanhol</option>
            </select>
        </Fragment>
    );
}