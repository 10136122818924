import "./css/PersonalizedOptionsSelector.css";
import React, {Fragment, useEffect, useState} from "react";
import PersonalizedSubOptionSelector from "./PersonalizedSubOptionSelector";


function PersonalizedOptionsSelector(props) {

   const [personalizedOptions,setPersonalizedOptions] = useState(props.selectedSubOptions);
    useEffect(()=>{
        //set initial lote
        setPersonalizedOptions(props.selectedSubOptions)
    },[props.selectedSubOptions.length]);

    const subOptions = props.subOptions;
    //console.log(personalizedOptions);
    const selectedSubOptionHandler = (subOptionCode,optionCode,optionValue,prevSelectedSubOption) => {
        console.log(subOptionCode,optionCode,optionValue,prevSelectedSubOption);

        setPersonalizedOptions( (prevState) =>{
            return {
                ...prevState,
                [optionCode]: subOptionCode,
            }
        })
        // calcula o valor a acrescentar ao preço
        // tenho de ir buscar o suboptionvalue ao label config
        //console.log(subOptions);
        //console.log(parseFloat((subOptions.find((subOption) => subOption.sub_option_code===subOptionCode)).sub_option_value));
        let value = parseFloat(props.subOptionsValue) + parseFloat((subOptions.find((subOption) => subOption.sub_option_code===subOptionCode)).sub_option_value -
            (subOptions.find((subOption) => subOption.sub_option_code===prevSelectedSubOption)).sub_option_value);
         //console.log("value=" & value);
        let subOptionSelected = {
            ...personalizedOptions,
            [optionCode]: subOptionCode,
        };
        props.onChangedSubOptions(value,subOptionSelected);
        //console.log(personalizedOptions);
        //console.log("value=" + value);
        //console.log((subOptions.find((subOption) => subOption.sub_option_code===subOptionCode)).sub_option_value);
        //console.log("no personalized option" + subOptionCode +"-" + optionCode + "-" + optionValue);
    }
    return(
        <Fragment>
            {props.options.map((el,index) => {
                return (
                    <div key={el.option_code}>
                        <label className="field"> {el.option_desc}</label>
                        <PersonalizedSubOptionSelector
                            optionType={el.option_type}
                            optionCode={el.option_code}
                            subOptions={subOptions}
                            selectedSubOptions={personalizedOptions[el.option_code]}
                            onSelectedSubOption={selectedSubOptionHandler}
                        />
                    </div>
                );
            })
            }
        </Fragment>
    );
}

export default PersonalizedOptionsSelector;

