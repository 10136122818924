import {
    Row,
    Col,
    ListGroup,
    Image,
    Form,
    Button,
    Card, Accordion,
} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Message from "../../components/Message";
import {Link, useNavigate} from "react-router-dom";
import "./CartPage.css";
import React, {useEffect, useRef, useState} from "react";

import {resetLabelConfig, updateCartItemNumber, updateLabelConfig} from "../../actions/labelConfigActions";
import {removeItemFromCart, updateItemQuantityAndTotal} from "../../actions/cartActions";
import axios from "axios";

function CartPage() {
    const cart = useSelector((state)=> state.cart );
    const labelConfigState = useSelector((state)=> state.labelConfig );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // get all personalized options
    console.log(cart);
    const resetPrintDataHandler = () => {
        //(cart.cart.items.length!==0)
        dispatch(resetLabelConfig());
    }
    const removeItemFromCartHandler = (event) => {
            let acceptAction = window.confirm('Tem certeza que deseja remover este artigo do seu carrinho?');
        if(acceptAction) {
            let item = event.target.getAttribute("data-index");
            //console.log(event.target + item);
            dispatch(removeItemFromCart(item));
        }

    }
    const updateCartQuantiyHandler = (event) => {
        let updItem = event.target.getAttribute("data-index");
        let currentQuantity = event.target.getAttribute("data-quantity");
        let newQtd = document.getElementById("qtd_" + updItem).value;
        if (newQtd < 1){
            alert("A quantidade tem de ser maior que 0");
            document.getElementById("qtd_" + updItem).value = currentQuantity;
            return;
        }
        let cartItemObj = {
          itemNumber: updItem,
          qtd: newQtd,
        };
        dispatch(updateItemQuantityAndTotal(cartItemObj));
    };
    const editItemFromCartHandler = (event) => {
        let item = event.target.getAttribute("data-index")
        dispatch(updateLabelConfig(cart.cart.items[item]));
        dispatch(updateCartItemNumber(item));
        navigate("/tiketa/" + cart.cart.items[item].labelConfig.labelSKU );
    }
   return (
               <React.Fragment>
                   <Row>
                       <Col md={12}>
                           <h1>O teu pedido:</h1>
                           {cart.cart.items.length === 0 ? (
                               <Message variant="info">
                                   O carrinho está vazio.
                               </Message>
                           ) : (
                               <ListGroup variant="flush">
                                   <Row className="cartHeader"><Col md={2}> </Col> <Col md={4}>Artigo</Col> <Col
                                       md={2}>Quantidade</Col> <Col md={1}>Preço</Col> <Col md={1}>SubTotal</Col> <Col
                                       md={1}> </Col> <Col md={1}> </Col> </Row>
                                   {cart.cart.items.map((item, index) => (
                                       <ListGroup.Item key={index + "-" + item.labelConfig.labelSKU}>
                                           <Row>
                                               <Col md={3} className="zoom">
                                                   <Image src={item.labelConfig.imageData} alt={item.labelConfig.title}
                                                          fluid
                                                          rounded/>
                                               </Col>
                                               <Col md={3}>
                                                   <span className="productTitleCheckout"><p>{item.labelConfig.title} </p></span>
                                                                {item.labelConfig.loteDesc !== "" &&
                                                                    <p>Lote : { item.labelConfig.loteDesc}</p>}
                                                               {item.labelConfig.linha1 !== "" &&
                                                                   <p>Linha 1 : {item.labelConfig.linha1}</p>}
                                                               {item.labelConfig.linha2 !== "" &&
                                                                   <p>Linha 2 : {item.labelConfig.linha2}</p>}
                                                               {item.labelConfig.linha3 !== "" &&
                                                                   <p>Linha 3 : {item.labelConfig.linha3}</p>}
                                                               {item.labelConfig.labelType === "ADISC" &&
                                                                   <p>Disciplinas : {item.labelConfig.otherOptionsObjects.map((el)=>el.subject_name).join(" , ")}</p>}
                                                               {item.labelConfig.labelType === "ARP" &&
                                                                   <p>Idioma : {item.labelConfig.otherOptionsObjects.code + " - " +  item.labelConfig.otherOptionsObjects.description} </p>}
                                                               {item.labelConfig.linhaLC !== "" &&
                                                                   <p>Lapis e Canetas : {item.labelConfig.linhaLC}</p>}
                                                               {item.labelConfig.linhaLCM !== "" &&
                                                                   <p>Lapis e Canetas Mini
                                                                       : {item.labelConfig.linhaLCM}</p>}
                                                               {item.labelConfig.txtFont !== "" &&
                                                                   <p>Tipo de Letra : {item.labelConfig.txtFont}</p>}
                                                               {item.labelConfig.labelSimulationType === "themes" &&
                                                                   <p>Tema
                                                                       : {item.labelConfig.themeDesc} ({item.labelConfig.model === "both" ? "Ambos os modelos" : item.labelConfig.model === "right" ? "Modelo da Direita" : "Modelo da Esquerda"})</p>}
                                                               {(item.labelConfig.labelSimulationType === "icons" && item.labelConfig.labelType !== "EACXMED" && item.labelConfig.labelType !== "EPED" ) &&
                                                                   <p>Icon : {item.labelConfig.iconDesc}</p>}
                                                               {(item.labelConfig.labelSimulationType === "icons" && item.labelConfig.labelType !== "EACXMED" && item.labelConfig.labelType !== "EPED") &&
                                                                   <p>Cor de Fundo : {item.labelConfig.bkColorDesc}</p>}
                                                               {((item.labelConfig.labelSimulationType === "icons" && item.labelConfig.labelType !== "EPED") || item.labelConfig.labelType === "EACXMED")&&
                                                                   <p>Cor da Letra
                                                                       : {item.labelConfig.txtColorDesc}</p>}
                                                               { //['BM','EABOL','MP','PI','KRAR','KIT1','KIT2','KIT5','KRD','KPC','KPB','FPP','EFM','EFMN','DCCAT','DCCAA'].includes(item.labelConfig.labelType) &&
                                                                   item.labelConfig.hasPersonalizedOptions &&
                                                                   Object.keys(item.labelConfig.optionsDescriptions).map((el)=> {
                                                                       return (
                                                                           <p>{item.labelConfig.optionsDescriptions[el]} {item.labelConfig.selectedSubOptionsDescriptions[el]}</p>
                                                                       )

                                                                   })
                                                               }
                                                               {item.labelConfig.labelType.substring(0,5) === "EAESP"  &&
                                                                   <p>Tema : {item.labelConfig.despensaTheme}</p>
                                                               }
                                                               {(item.labelConfig.labelType === "EACXMED" || item.labelConfig.labelType.substring(0,5) === "EAESP")  &&
                                                                   <p>Opções : {item.labelConfig.textLabelsObjects.map((el)=>el.label_desc).join(" , ")}</p>
                                                               }
                                                               {item.labelConfig.labelType.substring(0,5) === "EPED"  &&
                                                                   <p>Tema : {item.labelConfig.themeName}</p>
                                                               }
                                               </Col>
                                               <Col md={2}>
                                                   <div className="details-qty qty">
                                                       <input
                                                           type="number"
                                                           id={"qtd_" + index}
                                                           min="1"
                                                           defaultValue={item.labelConfig.quantity}
                                                           className="item-qty"
                                                       />
                                                       <button
                                                           className="update-cart-item"
                                                           onClick={updateCartQuantiyHandler}
                                                           data-index={index}
                                                           data-quantity={item.labelConfig.quantity}
                                                       >
                                                   <span data-index={index}
                                                         data-quantity={item.labelConfig.quantity}>Actualizar</span>
                                                       </button>
                                                   </div>
                                               </Col>
                                               <Col
                                                   md={1}><div className="details-qty qty" >{item.labelConfig.lotePrice !== "" ? item.labelConfig.subOptionsValue !== 0 ? (parseFloat(item.labelConfig.lotePrice) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                                                item.labelConfig.lotePrice :
                                                                item.labelConfig.subOptionsValue !== 0 ?
                                                                    (parseFloat(item.labelConfig.price) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                                                    item.labelConfig.price}€
                                               </div>
                                               </Col>
                                               <Col
                                                   md={1}><div className="details-qty qty" > {(item.labelConfig.quantity * (item.labelConfig.lotePrice !== "" ? item.labelConfig.subOptionsValue !== 0 ? (parseFloat(item.labelConfig.lotePrice) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2):
                                                                item.labelConfig.lotePrice :
                                                                item.labelConfig.subOptionsValue !== 0 ?
                                                                    (parseFloat(item.labelConfig.price) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                                                    item.labelConfig.price)).toFixed(2)}€
                                               </div>
                                               </Col>
                                               <Col md={1}>
                                                   <Button
                                                       type="button"
                                                       variant="light"
                                                       data-index={index}
                                                       onClick={editItemFromCartHandler}
                                                   >
                                                       <i data-index={index} className="fas fa-edit"></i>
                                                   </Button>
                                                    </Col>
                                                    <Col md={1}>
                                                   <Button
                                                       type="button"
                                                       variant="light"
                                                       data-index={index}
                                                       onClick={removeItemFromCartHandler}
                                                   >
                                                       <i data-index={index} className="fas fa-trash"/>
                                                   </Button>
                                                    </Col>
                                           </Row>
                                       </ListGroup.Item>
                                   ))}
                               </ListGroup>
                           )}
                       </Col>

                   </Row>
                   <Row>
                       <Col md={9}>


                       </Col>
                       <Col md={3}>
                           <h2>
                               Total : {(Math.round(Number(cart.cart.totalAmmount) * 100) / 100).toFixed(2)}€
                           </h2>
                       </Col>
                   </Row>
                   <Row>
                       <Col md={4}>
                           <Link to="/" onClick={resetPrintDataHandler} className="actionLink">
                               &lt;&lt; &nbsp; Continuar a Comprar.
                           </Link>
                       </Col>
                       <Col md={5}>

                       </Col>
                       <Col md={3}>
                           <Link to={cart.cart.items.length!==0 ? "/checkout" : ""} onClick={cart.cart.items.length!==0 && resetPrintDataHandler} className="actionLink">
                               Fazer Pedido &nbsp;&gt;&gt;
                           </Link>
                       </Col>
                   </Row>

               </React.Fragment>

    );

}

export default CartPage;
