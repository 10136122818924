import "./css/IconSelector.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";


function IconSelector(props) {
    const [selectedIcon, setSelectedIcon] = useState(props.selectedIcon);
    const iconsGroups = props.categories;
    const icons = props.icons;
    const selectedGroup = props.selectedGroup;

    const selectIconHandler = (event) => {
        let iconSelected1 = event.target.getAttribute("data-icon");
        let iconSelected1Desc = event.target.getAttribute("data-icondesc");
        let iconGroupCode = event.target.getAttribute("data-groupcode");
        setSelectedIcon(iconSelected1);
        props.onSelectedIcon(iconSelected1,iconSelected1Desc,iconGroupCode);
    };
    const getIconsByCategory = (category_code) => {
        return icons.filter((icon) => icon.group_code === category_code);
    };

    return (
        <Tabs defaultActiveKey={selectedGroup} className="nav nav-pills nav-justified">
            {iconsGroups.map(({id, group_code, group_name}) => {
                return (
                    <Tab eventKey={group_code}
                        title={group_name}
                        key={group_code}
                    >
                        <div className="field-selection" >
                            <div
                                className={
                                    props.selectedIcon === "drc00"
                                        ? "single-icon selected"
                                        : "single-icon"
                                }
                                key={"drc00" + id}
                                data-icon="drc00"
                                data-icondesc="Sem desenho"
                                data-groupcode={group_code}
                                onClick={selectIconHandler}
                            >
                                <span data-icon="drc00" data-icondesc="Sem desenho" data-groupcode={group_code}>Sem Desenho</span>
                            </div>
                            {getIconsByCategory(group_code).map(
                                ({icon_code,icon_name}) => {
                                    return (
                                        <div
                                            key={icon_code}
                                            className={
                                                icon_code === props.selectedIcon
                                                    ? "single-icon selected"
                                                    : "single-icon"
                                            }
                                            data-icon={icon_code}
                                            data-icondesc={icon_name}
                                            data-groupcode={group_code}
                                            onClick={selectIconHandler}
                                        >
                                            <img
                                                className="image-responsive"
                                                data-icon={icon_code}
                                                data-icondesc={icon_name}
                                                data-groupcode={group_code}
                                                src={"/media/icons/" + icon_code + ".png"}
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </Tab>
                );
            })}
        </Tabs>
    );
}

export default IconSelector;
