import React, {useEffect, useState} from "react";
import "./css/LoteSelector.css";
import {updateLote} from "../../../actions/labelConfigActions";
import {useDispatch, useSelector} from "react-redux";

//container principal para selecção dos tipos de letra
// argumentos:
// fieldTitle - titulo do campo
// array de objectos com o id(id) e valor(font)

function LoteSelector(props) {
  const [loteSelected, setLoteSelected] = useState(props.selectedLote);
  const labelConfigState = useSelector((state)=> state.labelConfig );
  const dispatch = useDispatch();

  useEffect(()=>{
        //set initial lote
        if(labelConfigState.labelConfig.lotePrice==="") {
            dispatch(updateLote(props.options.length !== 0 ? {lote: props.options[0].lote_id,lotePrice:props.options[0].price,loteDesc: props.options[0].description, loteRef: props.options[0].lote.toString() } : {lote: "",lotePrice:"",loteDesc:""}))
        }
    },[props.options.length]);
  const selectLoteHandler = (event) => {
    let selectedLote = event.target.getAttribute("data-lote");
    let price = event.target.getAttribute("data-price");
    let desc = event.target.getAttribute("data-desc");
    let loteRef = event.target.getAttribute("data-loteref");
    setLoteSelected(selectedLote);
    props.onSelectedLote(selectedLote,price,desc,loteRef);
  };
  return (
    <div style={{display : props.options.length===0 ? "none" : "",}}>
      <label className="field">{props.title}</label>
      <div className="field-selection">
        {props.options.map((el,index) => (
          <div
            key={el.id}
            id={el.lote_id}
            data-lote={el.lote_id}
            data-price={el.price}
            data-desc = {el.description}
            data-loteref = {el.lote}
            className={
              el.lote_id === loteSelected ? "single-font selected" : ((loteSelected==="" || typeof(loteSelected) ==="undefined") && index===0) ? "single-font selected" :"single-font"
            }
            onClick={selectLoteHandler}
          >
            {el.description + " - " + el.price + "€"}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LoteSelector;
