import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Tiketa.css";

function Tiketa({ tiketa }) {
  return (
      <Card className="my-3 p-3  rounded h-100">
           <Link to={`/tiketa/${tiketa.sku}`}>
             <Card.Img src={`/media/${tiketa.image_url}`}  />
          </Link>
        <Card.Body>
            {tiketa.has_lotes===true ? "desde:" : ""}
            <Card.Text as="h3">
            <div className="d-inline mx-3 my-3 price" >{tiketa.price}€</div>
            <Link to={`/tiketa/${tiketa.sku}`} title="Comprar" className="button action tocart primary ">
                <span>Comprar</span>
            </Link>
          </Card.Text>
            <Card.Title as="div">
              <strong >{tiketa.title}</strong>
            </Card.Title>
        </Card.Body>
      </Card>
  );
}

export default Tiketa;
