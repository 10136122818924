import {Col, Image, ListGroup, Row} from "react-bootstrap";
import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import "./OrderDetail.css";

export default function OrderDetail() {
    const cart = useSelector((state)=> state.cart );
    return(
        <ListGroup variant="flush">
                <Row>
                    <Col sm={12} className="headerRow">
                        <span>Resumo da Encomenda</span>
                    </Col>
                </Row>
                <Row className="tableHeader">
                    <Col sm={9}>Artigo</Col>
                    <Col sm={1} className="text-center">Preço</Col>
                    <Col sm={1} className="text-center">Quant.</Col>
                    <Col sm={1} className="text-center">SubTotal</Col>
                </Row>
                {cart.cart.items.map((item,index) => (
                    <ListGroup.Item key={index + "-" + item.labelConfig.labelSKU}>
                        <Row>
                            <Col sm={4} >
                                <Row>
                                    <Col>
                                        <Image src={item.labelConfig.imageData} alt={item.labelConfig.title} fluid rounded className="productImage"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={5}>
                                <Row>
                                    <Col>
                                        <span className="productTitleCheckout">{item.labelConfig.title}</span>
                                    </Col>
                                </Row>
                                {item.labelConfig.loteDesc !== "" &&
                                    <Row>
                                        <Col sm={12}>
                                            <span className="productDescriptionHeader">
                                                Lote:
                                            </span>
                                           <span className="productCheckoutDescription">
                                                {item.labelConfig.loteDesc}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.labelSimulationType === "themes" &&
                                    <Fragment>
                                        <Row>
                                            <Col sm={12} >
                                                 <span className="productDescriptionHeader">
                                                    Tema:
                                                 </span>
                                               <span className="productCheckoutDescription">
                                                   {item.labelConfig.themeDesc}
                                               </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <span className="productDescriptionHeader">
                                                    Modelo:
                                                </span>
                                                <span className="productCheckoutDescription">
                                                    {item.labelConfig.model === "both" ? "Ambos os modelos" : item.labelConfig.model === "right" ? "Modelo da Direita" : "Modelo da Esquerda"  }
                                                </span>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                }
                                {item.labelConfig.labelSimulationType === "icons" && item.labelConfig.labelType !== "EACXMED" && item.labelConfig.labelType !== "EPED" &&
                                    (<Fragment>
                                    <Row>
                                        <Col sm={12} >
                                            <span className="productDescriptionHeader">
                                                Icon:
                                            </span>
                                            <span className="productCheckoutDescription">
                                                {item.labelConfig.iconDesc}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                             <span className="productDescriptionHeader">
                                                Cor da Letra:
                                             </span>
                                            <span className="productCheckoutDescription">
                                                 {item.labelConfig.txtColorDesc}
                                            </span>
                                        </Col>
                                    </Row>
                                        <Row>
                                        <Col sm={12}>
                                            <span className="productDescriptionHeader">
                                                Cor do Fundo:
                                            </span>
                                            <span className="productCheckoutDescription">
                                                {item.labelConfig.bkColorDesc}
                                            </span>
                                        </Col>
                                    </Row>
                                    </Fragment>)
                                }
                                {item.labelConfig.txtFont !== "" &&
                                    <Row>
                                        <Col sm={12}>
                                            <span className="productDescriptionHeader">
                                                Tipo de Letra:
                                            </span>
                                            <span className="productCheckoutDescription">
                                                {item.labelConfig.txtFont}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.linha1 !== "" &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">Linha 1: </span>
                                           <span className="productCheckoutDescription">{item.labelConfig.linha1}</span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.linha2 !== "" &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">Linha 2: </span>
                                            <span className="productCheckoutDescription">{item.labelConfig.linha2}</span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.linha3 !== "" &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">Linha 3: </span>
                                            <span className="productCheckoutDescription">{item.labelConfig.linha3}</span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.labelType === "ADISC" &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">Disciplinas: </span>
                                            <span className="productCheckoutDescription">{item.labelConfig.otherOptionsObjects.map((el)=>el.subject_name).join(" , ")}</span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.labelType === "ARP" &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">Idioma: </span>
                                            <span className="productCheckoutDescription">{item.labelConfig.otherOptionsObjects.description}</span>
                                        </Col>
                                    </Row>
                                }
                                {(["DCCAT","DCCAA"].includes(item.labelConfig.labelType)) &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">{item.labelConfig.optionsDescriptions["DCCA"]}</span>
                                            <span className="productCheckoutDescription">{item.labelConfig.selectedSubOptionsCodes["DCCA"] + " - " +  item.labelConfig.selectedSubOptionsDescriptions["DCCA"]}</span>
                                        </Col>
                                    </Row>
                                }
                                {(["BM","EABOL","PI","MP"].includes(item.labelConfig.labelType)) &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">{item.labelConfig.optionsDescriptions[item.labelConfig.labelType]}</span>
                                            <span className="productCheckoutDescription">{item.labelConfig.selectedSubOptionsCodes[item.labelConfig.labelType] + " - " +  item.labelConfig.selectedSubOptionsDescriptions[item.labelConfig.labelType]}</span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.labelType === "EACXMED" &&
                                    <Fragment>
                                    <Row>
                                        <Col sm={12}>
                                             <span className="productDescriptionHeader">
                                                Cor da Letra:
                                             </span>
                                            <span className="productCheckoutDescription">
                                                 {item.labelConfig.txtColorDesc}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                             <span className="productDescriptionHeader">
                                                Opções:
                                             </span>
                                            <span className="productCheckoutDescription">
                                                 {item.labelConfig.textLabelsObjects.map((el)=>el.label_desc).join(" , ")}
                                            </span>
                                        </Col>
                                    </Row>
                                    </Fragment>
                                }
                                {item.labelConfig.labelType.substring(0,5) === "EAESP" &&
                                    <Fragment>
                                    <Row>
                                        <Col sm={12}>
                                             <span className="productDescriptionHeader">
                                                Tema:
                                             </span>
                                            <span className="productCheckoutDescription">
                                                 {item.labelConfig.despensaTheme}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                             <span className="productDescriptionHeader">
                                                Opções:
                                             </span>
                                            <span className="productCheckoutDescription">
                                                 {item.labelConfig.textLabelsObjects.map((el)=>el.label_desc).join(" , ")}
                                            </span>
                                        </Col>
                                    </Row>
                                    </Fragment>
                                }
                                {item.labelConfig.linhaLC !== "" &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">Lapis e Canetas: </span>
                                            <span className="productCheckoutDescription">{item.labelConfig.linhaLC}</span>
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.linhaLCM !== "" &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">Mini Lapis e Canetas: </span>
                                            <span className="productCheckoutDescription">{item.labelConfig.linhaLCM}</span>
                                        </Col>
                                    </Row>
                                }
                                {//['KRAR','KIT1','KIT2','KIT5','KRD'].includes(item.labelConfig.labelType) &&
                                    item.labelConfig.hasPersonalizedOptions &&
                                    <Row>
                                        <Col sm={12} >
                                            {Object.keys(item.labelConfig.optionsDescriptions).map((el)=> {
                                                return (
                                                    <p ClassName="productItemDescription"><span className="productDescriptionHeader">{item.labelConfig.optionsDescriptions[el]}</span> <span className="productCheckoutDescription">{item.labelConfig.selectedSubOptionsDescriptions[el]}</span></p>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                }
                                {(item.labelConfig.labelType === "EFMN" || item.labelConfig.labelType === "FPP") &&
                                    <Row>
                                        <Col sm={12} >
                                            {Object.keys(item.labelConfig.optionsDescriptions).map((el)=> {
                                                return (
                                                    <p>
                                                        <span className="productDescriptionHeader">{item.labelConfig.optionsDescriptions[el]} </span>
                                                        <span className="productCheckoutDescription">{item.labelConfig.selectedSubOptionsDescriptions[el]}</span>
                                                    </p>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                }
                                {item.labelConfig.labelType.substring(0,5) === "EPED" &&
                                    <Row>
                                        <Col sm={12}>
                                             <span className="productDescriptionHeader">
                                                Tema:
                                             </span>
                                            <span className="productCheckoutDescription">
                                                 {item.labelConfig.themeName}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            <Col sm={1} className="text-center">{item.labelConfig.lotePrice!=="" ?  item.labelConfig.subOptionsValue !== 0 ? (parseFloat(item.labelConfig.lotePrice) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                               item.labelConfig.lotePrice :
                                               item.labelConfig.subOptionsValue !== 0 ?
                                                   (parseFloat(item.labelConfig.price) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                                   item.labelConfig.price}€
                            </Col>
                            <Col sm={1} className="text-center">{item.labelConfig.quantity}</Col>
                            <Col sm={1} className="text-center">{(item.labelConfig.quantity * (item.labelConfig.lotePrice !== "" ? item.labelConfig.subOptionsValue !== 0 ? (parseFloat(item.labelConfig.lotePrice) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                                                item.labelConfig.lotePrice :
                                                                item.labelConfig.subOptionsValue !== 0 ?
                                                                    (parseFloat(item.labelConfig.price) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                                                    item.labelConfig.price)).toFixed(2)}€
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
             <Row className="tableHeader">
                    <Col sm={10}>Subtotal:</Col>
                    <Col sm={2} className="text-center">{cart.cart.totalAmmount} €</Col>
            </Row>
            <Row className="tableHeader">
                    <Col sm={10}>Envio ({cart.cart.shippingMethod==="" ? "Levantamento na Loja":cart.cart.shippingMethod}):</Col>
                    <Col sm={2} className="text-center">{cart.cart.shippingCost} €</Col>
            </Row>
            <Row className="tableHeader">
                    <Col sm={10}>Total:</Col>
                    <Col sm={2} className="text-center">{(parseFloat(cart.cart.totalAmmount) + parseFloat(cart.cart.shippingCost)).toFixed(2) } €</Col>
            </Row>
            </ListGroup>
    );
}