import React, {useEffect, useMemo, useState} from "react";
import {Table, Button} from "react-bootstrap";
import "./StoreOrderListPage.css";
import Message from "../../components/Message";
import Loader from "../../components/Loader";

import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    listOrders,
    listOrdersByStore,
    searchOrder,
    searchOrderByPattern,
    searchOrderByPatternByStore, searchOrderByStore
} from "../../actions/orderListActions";
import {orderListActions} from "../../store/orderListSlice";
import axios from "axios";


function OrderListPage() {
    const dispatch = useDispatch();
    const orderList = useSelector(state => state.orderList);
    const {loading, error, orders} = orderList;
    const userLogin = useSelector((state) => state.userLogin);
    const navigate = useNavigate();
    const date = new Date();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [totalSort,setTotalSort] = useState("");
    const [selectedStatus, setselectedStatus] = useState("Processing");
    const [selectedSearch, setselectedSearch] = useState("orderIdSearch");
    const [sortConfig,setSortConfig] = useState({key: "", direction: ""})
    const [data,setData]=useState([]);
    const statusMap = new Map();
    const modelMap = new Map();

    statusMap.set("Pending", "Pendente de Pagamento");
    statusMap.set("Manufactured", "Em Produção");
    statusMap.set("Shipped", "Enviado");
    statusMap.set("Invoiced", "Enviado");
    statusMap.set("Processing", "Pago");
    statusMap.set("Processing Exported", "Em Produção");
    statusMap.set("Canceled","Cancelado");
    statusMap.set("Closed","Entregue");
    modelMap.set("both", "Ambos");
    modelMap.set("right", "Direita");
    modelMap.set("left", "Esquerda");

    useEffect(()=>{
        //setselectedStatus("Processing");
        dispatch(listOrdersByStore(selectedStatus,userLogin.userInfo.store));
    },[selectedStatus]);

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const ordersStatusSelectHandler = (event) => {
        let status = event.target.options[event.target.options.selectedIndex].value;
        setselectedStatus(status);
        dispatch(listOrdersByStore(status,userLogin.userInfo.store))
    }
    const requestSort = key => {
        let direction= 'ascending';
        if(sortConfig.key===key && sortConfig.direction==='ascending') {
            direction='descending';
        }
        setSortConfig({key,direction});
    }
    useMemo(() => {
        let sort1,sort2;
        let orderedList = [...orders];
        orderedList.sort((a,b)=> {
            if(sortConfig.key==="total") {
                if (parseFloat(a[sortConfig.key]) < parseFloat(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? -1 : 1 ;
                }
                if (parseFloat(a[sortConfig.key]) > parseFloat(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? 1 : -1 ;
                }
                return 0;
            } else if(sortConfig.key==="id") {
                if (parseInt(a[sortConfig.key]) < parseInt(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? -1 : 1 ;
                }
                if (parseInt(a[sortConfig.key]) > parseInt(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? 1 : -1 ;
                }
                return 0;
            } else if(sortConfig.key==="store" || sortConfig.key==="status" || sortConfig.key==="client_invoice_date" || sortConfig.key==="exporting_date"  ) {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1 ;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1 ;
                }
                return 0;
            }
        })
        dispatch(orderListActions.orderListSuccess(orderedList));
    },[sortConfig])

    const orderClickHanlder = (event) => {
        // vai para odetalhe da encomenda
        let order_id=event.target.getAttribute("data-orderid");
        navigate("/order_detail_page", {state: {orderId: order_id, total: 3}});
    }
    const searchButtonHandler = (event) => {
        let searchOption = document.getElementById("searchOption").value;
        setselectedSearch(searchOption);
        if(searchOption==="orderIdSearch") {
            let orderId = document.getElementById("searchText").value;
            if (Number.isNaN(parseInt(orderId))) {
                alert("Tem de inserir um número de encomenda válido!");
                return;
            }
            dispatch(searchOrderByStore(orderId,userLogin.userInfo.store))
        } else {
            let searchString = document.getElementById("searchText").value;
            dispatch(searchOrderByPatternByStore(searchString, searchOption,userLogin.userInfo.store))
        }
    }

    return (
        <div>
                <h1>Listagem de Pedidos</h1>

                {loading ? (
                    <Loader/>
                ) : error ? (
                    <Message variant="danger">{error}</Message>
                ) : (
                    <Table striped borderless hover responsive className="table-sm">
                        <thead>
                        <tr>
                            <td colSpan={2}>
                                 <select id="searchOption" defaultValue={selectedSearch}>
                                    <option  value="orderIdSearch">Nº do Pedido</option>
                                    <option  value='clientEmailSearch'>Email do Cliente</option>
                                    <option  value='clientNameSearch'>Nome do Cliente</option>
                                    <option  value='clientPhoneSearch'>Telemóvel Cliente</option>
                                    <option  value='storeSearch'>Loja</option>
                                    <option  value='createdDateSearch'>Data do Pedido (YYYY-MM-DD)</option>
                                </select>
                            </td>

                            <td><input className="input-text" type="text" id="searchText" /></td>
                            <td><Button id="searchButton" onClick={searchButtonHandler}>Pesquisar</Button></td>
                            <td></td>
                            <td>
                                <select onChange={ordersStatusSelectHandler} defaultValue={selectedStatus}>
                                    <option  value="">Filtros:</option>
                                     <option  value='Pending'>Pedidos Pendentes de Pagamento </option>
                                    <option  value='Processing'>Pedidos Pagos </option>
                                    <option  value='Processing Exported'>Pedidos Em Produção</option>
                                    <option  value='Shipped'>Pedidos Enviados à Loja</option>
                                    <option  value='Closed'>Pedidos Entregues</option>
                                    <option  value='Canceled'>Pedidos Cancelados</option>
                                </select>
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <th onClick={()=>requestSort("id")} className="sortable">nº Encomenda {sortConfig.key==="id" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" }</th>
                            <th onClick={()=>requestSort("status")} className="sortable">Estado {sortConfig.key==="status" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" }</th>
                            <th>Utilizador</th>
                            <th onClick={()=>requestSort("store")} className="sortable">Loja {sortConfig.key==="store" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" } </th>
                            <th onClick={()=>requestSort("total")} className="sortable">Total {sortConfig.key==="total" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" } </th>
                            <th onClick={()=>requestSort("client_invoice_date")} className="sortable">{selectedStatus ==="Processing Exported" ? "Data de faturação/criação" : "Data de faturação/criação"} {sortConfig.key==="client_invoice_date" || sortConfig.key==="exporting_date" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" }</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        {orders?.map((order) => {
                            return (
                                <tr key={order.id}>
                                    <td><a className="orderLink" data-orderid={order.id} onClick={orderClickHanlder}>{order.id}</a></td>
                                    <td>{statusMap.get(order.status)}</td>
                                    <td>{order.user_name}</td>
                                    <td>{order.store_name}</td>
                                    <td>{order.total}€</td>
                                    <td>{order.client_invoice_date.substring(0,4)==='1900' ? "" : new Date(order.client_invoice_date).toLocaleString('pt-PT')}</td>
                                    <td>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                )}

        </div>
    );
}

export default OrderListPage;