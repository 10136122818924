import {bgColorObj, txtColorObj} from "./constants/LabelsColorConstants";
import "./css/AGLabels.css";
import {lineSizes} from "./constants/LabelsConstants";
import React from "react";
import {useSelector} from "react-redux";
import ADISCLabels from "./ADISCLabels";
import Carousel from 'react-bootstrap/Carousel';
import TextLabelsLabels from "./TextLabelsLabels";


export default function TextLabelsCarousel(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, linha4, linha5, linha6, iconSrc, icon;
    linha1=labelConfigState.labelConfig.linha1;
    linha2=labelConfigState.labelConfig.linha2;
    linha3=labelConfigState.labelConfig.linha3;
    linha4="";
    linha5="";
    linha6="";
    console.log(labelConfigState);
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";

    return(
        <div>
            {/*Se já tiver escolhido alguma disciplina aparece o carousel*/}
            {(labelConfigState.labelConfig.textLabelsObjects.length!==0 ) &&
            <Carousel variant="dark" slide={false}>
                {labelConfigState.labelConfig.textLabelsObjects.map((label,index) => (
                    index!==28 ?
                    <Carousel.Item>
                        <div className="d-block w-100" style={{margin:"100px"}} key={index+1}>
                            {label.label_linha2==="" || label.label_linha2===null  &&
                             <TextLabelsLabels linha1={label.label_linha1} linha2={""} linha3={""}  index={index+1}/>
                            }
                            {label.label_linha2!=="" && label.label_linha2!==null  &&
                             <TextLabelsLabels linha1={""} linha2={label.label_linha1} linha3={label.label_linha2}  index={index+1}/>
                            }
                         </div>
                     <Carousel.Caption>
                        <h5>Etiqueta {index + 1}</h5>
                     </Carousel.Caption>
                    </Carousel.Item>
                        :
                        ""
                ))}
            </Carousel> }
            {/*Se ainda não tiver escolhido nenhum item aparece uma etiqueta*/}
            {labelConfigState.labelConfig.textLabelsObjects.length===0 &&  <TextLabelsLabels linha1={linha1} linha2={linha2} linha3={linha3} />}
        </div>
    );
}