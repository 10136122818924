import {Container} from "react-bootstrap";
import {HashRouter as Router, Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

import HomePage from "./pages/HomePage";
import Home from "./pages/Home";
import TiketaPage from "./pages/TiketaPage";
import CartPage from "./pages/cart/CartPage";
import SubCategoryPage from "./pages/SubCategoryPage";
import LoginPage from "./pages/LoginPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import OrderListPage from "./pages/Backoffice/OrderListPage";
import CheckOutPage from "./pages/checkout/CheckOutPage";
import FinalizeOrder from "./pages/checkout/FinalizeOrder";
import PrintOrderPage from "./pages/checkout/PrintOrderPage";
import OrderDetailPage from "./pages/Backoffice/OrderDetailPage";
import StoreOrderListPage from "./pages/Backoffice/StoreOrderListPage";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import SplashPage from "./pages/SplashPage";


function App() {
    const userLogin = useSelector((state) => state.userLogin);
    // anular o botão back do browser, ao carregar volta para a página das categorias
    useEffect(() => {
        const handleBackButton = () => {
          // Navigate to a new page or perform some other action
          window.location.replace('#/');
        };
        // Add an event listener that will be triggered when the user tries to navigate back
        window.addEventListener('popstate', handleBackButton);
        // Return a cleanup function that will remove the event listener when the component is unmounted
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
  }, []);
    return (
        <Router>
                <Header/>
            <main className="py-3">
                <Container>
                    <Routes>
                        <Route path="/" element={<Home/>} exact/>
                        <Route path="/tiketa/category/:category" element={<HomePage/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/splashpage" element={<SplashPage/>}/>
                        <Route path="/tiketa/category/category/:category" element={<SubCategoryPage/>}/>
                        <Route path="/tiketa/:sku" element={<TiketaPage/>}/>
                        <Route path="/cart/" element={<CartPage/>}>
                            <Route path=":id" element={<CartPage/>}/>
                        </Route>
                        <Route path="/change_password" element={<ChangePasswordPage/>}/>
                        <Route path="/admin/order_list/" element={<OrderListPage/>}/>
                        <Route path="/admin/order_list/:status" element={<OrderListPage/>}/>
                        <Route path="/checkout" element={<CheckOutPage/>}/>
                        <Route path="/finalize_order" element={<FinalizeOrder/>}/>
                        <Route path="/print_order_page" element={<PrintOrderPage/>}/>
                        <Route path="/order_detail_page" element={<OrderDetailPage/>}/>
                        <Route path="/admin/store_order_list" element={<StoreOrderListPage/>}/>
                    </Routes>
                </Container>
            </main>
            <Footer/>
        </Router>
    );
}

export default App;
