import {bgColorObj, txtColorObj} from "./constants/LabelsColorConstants";
import "./css/LCMLabels.css";
import {lineSizes} from "./constants/LabelsConstants";
import React from "react";
import {useSelector} from "react-redux";

export default function LCMLabels(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linhaLCM, isKitMember, iconSrc, icon;
    linhaLCM=props.linhaLCM;
    isKitMember=props.isKitMember;
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";
    return(
        <div id="label-tiketa-lcm" className="labelSimulationCard" style={{
                             zoom: isKitMember===1 ? "70%":"100%",
                         }}>
                <div
                    className="label label-lcm"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorEsq,
                        display: labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "right" ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand label-lcm-brand"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconleft iconleft_lcm">
                        <img
                            id="iconLeft"
                            alt="labelIcon"
                            className="iconleft iconleft_lcm"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconEsq + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-sicon label-lcm-l1-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["LCM"]["fontSizeL1_1"],
                            top: lineSizes["LCM"]["topAdjust"],
                        }}
                    >
                        {linhaLCM}
                    </div>
                    <div
                        className="label-l1-cicon label-lcm-l1-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["LCM"]["fontSizeL1_1"],
                            top: lineSizes["LCM"]["topAdjust"],
                        }}
                    >
                        {linhaLCM}
                    </div>

                </div>
                <div
                    className="label label-lcm"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorDir,
                        display: labelConfigState.labelConfig.labelSimulationType === "icons" || (labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "left") ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand-dir label-lcm-brand-dir"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconright iconright_lcm">
                        <img
                            id="iconRight"
                            alt="labelIcon"
                            className="iconright iconright_lcm"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconDir + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-cicon-right label-lcm-l1-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["LCM"]["fontSizeL1_1"],
                            top: lineSizes["LCM"]["topAdjust"],
                        }}
                    >
                        {linhaLCM}
                    </div>
                </div>
            </div>
    );
}