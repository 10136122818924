import React, {useState} from "react";
import "./css/QuantityAndSubmitPanel.css";
import {useSelector} from "react-redux";

export default function QuantityAndSubmitPanel(props) {
    const [quantity, setQuantity] = useState(props.quantity);
    const labelConfigState = useSelector((state)=> state.labelConfig );

    const changeQuantityHandler = (event) => {
        setQuantity(event.target.value);
        event.target.setCustomValidity('');
        props.onEnteredQuantity(event.target.value);
    };
    const invalidHandler = (event) => {
        event.target.value < 1 ? event.target.setCustomValidity('O valor tem de ser maior que zero!') : event.target.setCustomValidity('');
    };
    return (
        <div style={{marginTop: "1rem"}}>
            <label className="field fieldset">Qtd</label>
            <input
                type="number"
                id="qtd"
                name="qty"
                min="1"
                defaultValue={props.quantity}
                className="input-text qty"
                onChange={changeQuantityHandler}
                onInvalid={invalidHandler}
            /> &nbsp;
            <button type="submit" title="Comprar" className="action primary"  >
                <span data-action={labelConfigState.labelConfig.cartItemNumber==="-1" ? "comprar":"atualizar"}>{labelConfigState.labelConfig.cartItemNumber==="-1" ? "Comprar":"Atualizar"}</span>
            </button>
        </div>
    );
}
