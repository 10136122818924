export const paramLabel = {
    EPED: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "2_linha_length": 14,
                "1_linha_min_size": 14.5,
                "2_linha_min_size": 11.5,
                "2_linha_size_ini": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "2_linha_length": 11,
                "1_linha_min_size": 11,
                "2_linha_min_size": 11.5,
                "2_linha_size_ini": 16,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 14,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 16,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "1_linha_min_size": 15.5,
                "2_linha_size_ini": 17,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 17,
                "2_linha_length": 14,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 11,
            },
        },
    },
    RED: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 18,
                "2_linha_length": 9,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 8,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 18,
                "2_linha_length": 7,
                "2_linha_min_size": 10.5,

            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 17,
                "2_linha_length": 14,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 11,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 14,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 11,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 9,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 6,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 15,
                "2_linha_length": 6,
                "2_linha_min_size": 10,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 12,
                "2_linha_min_size": 13,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 9,
                "2_linha_min_size": 14,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 12,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 6,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 15,
                "2_linha_length": 6,
                "2_linha_min_size": 10,
            },
        },
    },
    FPP: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 8,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 14,
                "2_linha_length": 7,
                "2_linha_min_size": 10.5,

            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 17,
                "2_linha_length": 14,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 11,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 14,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 11,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 9,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 6,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 15,
                "2_linha_length": 6,
                "2_linha_min_size": 10,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 12,
                "2_linha_min_size": 13,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 9,
                "2_linha_min_size": 14,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 12,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 6,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 15,
                "2_linha_length": 6,
                "2_linha_min_size": 10,
            },
        },
    },
    ADISC: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini":24, //22
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 20,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 20,//17,
                "1_linha_length": 10,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 11,
                "3_linha_size_ini": 17,
                "3_linha_length": 10,
                "3_linha_min_size": 11,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 15,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 24,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 23,
                "3_linha_length": 13,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 10,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 20,
                "3_linha_length": 13,
                "3_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 23,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 23,
                "3_linha_length": 13,
                "3_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 18,
                "3_linha_length": 13,
                "3_linha_min_size": 14,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 19,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 19,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 16,
                "3_linha_length": 10,
                "3_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 22,
                "2_linha_length": 14,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 22,
                "3_linha_length": 14,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 18,
                "3_linha_length": 12,
                "3_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 23,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 23,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
                "3_linha_size_ini": 23,
                "3_linha_length": 14,
                "3_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 19,
                "2_linha_length": 12,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 19,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 21,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 21,
                "3_linha_length": 13,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 18,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 10,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 18,
                "3_linha_length": 10,
                "3_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 9,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 17,
                "2_linha_length": 9,
                "2_linha_min_size": 10,
                "3_linha_size_ini": 17,
                "3_linha_length": 9,
                "3_linha_min_size": 10,
            },
        },
    },
    ADISC1: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 20,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 11,
                "3_linha_size_ini": 17,
                "3_linha_length": 10,
                "3_linha_min_size": 11,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 14,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 16,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 14,
                "1_linha_min_size": 15.5,
                "2_linha_size_ini": 17,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 14,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 14,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 17,
                "2_linha_length": 14,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 14,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 11,
            },
        },
    },
    TAB: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 37,
                "1_linha_length": 3,
                "2_linha_length": 3,
                "1_linha_min_size": 14.5,
                "2_linha_min_size": 14.5,
                "2_linha_size_ini": 36,
            }, COM_ICON: {
                "1_linha_size_ini": 35,
                "1_linha_length": 3,
                "2_linha_length": 4,
                "1_linha_min_size": 21,
                "2_linha_min_size": 21,
                "2_linha_size_ini": 30,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 42,
                "1_linha_length": 13,
                "1_linha_min_size": 20 ,
                "2_linha_size_ini": 39,
                "2_linha_length": 13,
                "2_linha_min_size": 20,
            }, COM_ICON: {
                "1_linha_size_ini": 38,
                "1_linha_length": 10,
                "1_linha_min_size": 20,
                "2_linha_size_ini": 35,
                "2_linha_length": 8,
                "2_linha_min_size": 23,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 36,
                "1_linha_length": 13,
                "1_linha_min_size": 20 ,
                "2_linha_size_ini": 35,
                "2_linha_length": 13,
                "2_linha_min_size": 20,
            }, COM_ICON: {
                "1_linha_size_ini": 32,
                "1_linha_length": 10,
                "1_linha_min_size": 20,
                "2_linha_size_ini": 32,
                "2_linha_length": 8,
                "2_linha_min_size": 23,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 31,
                "1_linha_length": 13,
                "1_linha_min_size": 20 ,
                "2_linha_size_ini": 30,
                "2_linha_length": 12,
                "2_linha_min_size": 20,
            }, COM_ICON: {
                "1_linha_size_ini": 27,
                "1_linha_length": 10,
                "1_linha_min_size": 20,
                "2_linha_size_ini": 27,
                "2_linha_length": 8,
                "2_linha_min_size": 19,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 37,
                "1_linha_length": 13,
                "1_linha_min_size": 20 ,
                "2_linha_size_ini": 36,
                "2_linha_length": 13,
                "2_linha_min_size": 20,
            }, COM_ICON: {
                "1_linha_size_ini": 32,
                "1_linha_length": 10,
                "1_linha_min_size": 20,
                "2_linha_size_ini": 31,
                "2_linha_length": 8,
                "2_linha_min_size": 19,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 39,
                "1_linha_length": 13,
                "1_linha_min_size": 20 ,
                "2_linha_size_ini": 37,
                "2_linha_length": 13,
                "2_linha_min_size": 20,
            }, COM_ICON: {
                "1_linha_size_ini": 34,
                "1_linha_length": 10,
                "1_linha_min_size": 20,
                "2_linha_size_ini": 32,
                "2_linha_length": 8,
                "2_linha_min_size": 19,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 36,
                "1_linha_length": 13,
                "1_linha_min_size": 20 ,
                "2_linha_size_ini": 34,
                "2_linha_length": 13,
                "2_linha_min_size": 20,
            }, COM_ICON: {
                "1_linha_size_ini": 32,
                "1_linha_length": 10,
                "1_linha_min_size": 20,
                "2_linha_size_ini": 30,
                "2_linha_length": 8,
                "2_linha_min_size": 19,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 30,
                "1_linha_length": 13,
                "1_linha_min_size": 20 ,
                "2_linha_size_ini": 28,
                "2_linha_length": 13,
                "2_linha_min_size": 20,
            }, COM_ICON: {
                "1_linha_size_ini": 27,
                "1_linha_length": 10,
                "1_linha_min_size": 20,
                "2_linha_size_ini": 26,
                "2_linha_length": 8,
                "2_linha_min_size": 19,
            },
        },
    },
    REG: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "2_linha_length": 14,
                "1_linha_min_size": 14.5,
                "2_linha_min_size": 11.5,
                "2_linha_size_ini": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "2_linha_length": 11,
                "1_linha_min_size": 11,
                "2_linha_min_size": 11.5,
                "2_linha_size_ini": 16,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 14,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 16,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "1_linha_min_size": 15.5,
                "2_linha_size_ini": 17,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 20,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14.5,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 17,
                "2_linha_length": 14,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 11,
            },
        },
    },
    MINI: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 8,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 14,
                "2_linha_length": 7,
                "2_linha_min_size": 10.5,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 17,
                "2_linha_length": 14,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 11,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 14,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 18,
                "2_linha_length": 11,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 9,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 6,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 15,
                "2_linha_length": 6,
                "2_linha_min_size": 10,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 12,
                "2_linha_min_size": 13,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 9,
                "2_linha_min_size": 14,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 12,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 8,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 6,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 15,
                "2_linha_length": 6,
                "2_linha_min_size": 10,
            },
        },
    },
    LC: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 14,
                "1_linha_min_size": 13,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 18,
                "1_linha_min_size": 11,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 14,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "1_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 11,
                "1_linha_min_size": 10,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 13,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 16.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14.5,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 16,
                "1_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 13,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 14,
                "1_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 11,
            },
        },
    },
    LCM: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 12,
                "1_linha_min_size": 13,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 16,
                "1_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 13,
                "1_linha_min_size": 13,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 11,
                "1_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 16,
                "1_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 13,
                "1_linha_min_size": 13,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 16,
                "1_linha_min_size": 16.5,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14.5,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 12,
                "1_linha_min_size": 13,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 9,
                "1_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 7,
                "1_linha_min_size": 11,
            },
        },
    },
    IM: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 13,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 13,

            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 15,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 24,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 14,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 14,
                "2_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 23,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 19,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 13,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 20,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 12,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 23,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 23,
                "2_linha_length": 14,
                "2_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 20,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 24,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 23,
                "1_linha_length": 12,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 23,
                "2_linha_length": 12,
                "2_linha_min_size": 17,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 24,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 21,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 21,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 21,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 21,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 9,
                "1_linha_min_size": 13,
                "2_linha_size_ini": 19,
                "2_linha_length": 9,
                "2_linha_min_size": 13,
            },
        },
    },
    BIKE1: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 28,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 26,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 12,
                "1_linha_min_size": 19,
                "2_linha_size_ini": 24,
                "2_linha_length": 12,
                "2_linha_min_size": 19,

            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 39,
                "1_linha_length": 15,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 32,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 36,
                "1_linha_length": 1,
                "1_linha_min_size": 23,
                "2_linha_size_ini": 32,
                "2_linha_length": 14,
                "2_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 29,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 29,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 26,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 13,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 25,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 23,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 23,
                "2_linha_length": 11,
                "2_linha_min_size": 12,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 29,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 23,
                "2_linha_length": 14,
                "2_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 15,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 25,
                "2_linha_length": 14,
                "2_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 30,
                "1_linha_length": 15,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 29,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 28,
                "1_linha_length": 12,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 28,
                "2_linha_length": 12,
                "2_linha_min_size": 17,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 28,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 27,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 26,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 26,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 9,
                "1_linha_min_size": 13,
                "2_linha_size_ini": 26,
                "2_linha_length": 9,
                "2_linha_min_size": 13,
            },
        },
    },
    BIKE2: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 35,
                "1_linha_length": 12,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 26,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 30,
                "1_linha_length": 12,
                "1_linha_min_size": 19,
                "2_linha_size_ini": 30,
                "2_linha_length": 12,
                "2_linha_min_size": 19,

            },
        },
        TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 39,
                "1_linha_length": 15,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 32,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 45,
                "1_linha_length": 1,
                "1_linha_min_size": 23,
                "2_linha_size_ini": 34,
                "2_linha_length": 14,
                "2_linha_min_size": 16,
            },
        },
        TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 39,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 35,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 36,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 36,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
            },
        },
        TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 32,
                "1_linha_length": 13,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 30,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 31,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 29,
                "2_linha_length": 11,
                "2_linha_min_size": 12,
            },
        },
        TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 38,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 35,
                "2_linha_length": 14,
                "2_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 34,
                "1_linha_length": 15,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 33,
                "2_linha_length": 14,
                "2_linha_min_size": 14,
            },
        },
        TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 42,
                "1_linha_length": 15,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 39,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 38,
                "1_linha_length": 12,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 38,
                "2_linha_length": 12,
                "2_linha_min_size": 17,
            },
        },
        TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 38,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 37,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 36,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 36,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        },
        TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 33,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 33,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 30,
                "1_linha_length": 9,
                "1_linha_min_size": 13,
                "2_linha_size_ini": 30,
                "2_linha_length": 9,
                "2_linha_min_size": 13,
            },
        },
    },
    EC: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,

            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 8,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 11,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 8,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 19,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 19,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            },
        },
    },
    ECPE: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 8,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 12,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,

            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 11,
                "2_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 8,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 11,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 8,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 16,
                "2_linha_length": 8,
                "2_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 19,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 19,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
            },
        },
    },
    AG: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 20,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 11,
                "3_linha_size_ini": 17,
                "3_linha_length": 10,
                "3_linha_min_size": 11,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 15,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 24,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 23,
                "3_linha_length": 13,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 10,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 20,
                "3_linha_length": 13,
                "3_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 23,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 23,
                "3_linha_length": 13,
                "3_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 18,
                "3_linha_length": 13,
                "3_linha_min_size": 14,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 19,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 19,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 16,
                "3_linha_length": 10,
                "3_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 22,
                "2_linha_length": 14,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 22,
                "3_linha_length": 14,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 18,
                "3_linha_length": 12,
                "3_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 23,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 23,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
                "3_linha_size_ini": 23,
                "3_linha_length": 14,
                "3_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 19,
                "2_linha_length": 12,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 19,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 21,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 21,
                "3_linha_length": 13,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 18,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 10,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 18,
                "3_linha_length": 10,
                "3_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 9,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 17,
                "2_linha_length": 9,
                "2_linha_min_size": 10,
                "3_linha_size_ini": 17,
                "3_linha_length": 9,
                "3_linha_min_size": 10,
            },
        },
    },
    ANTG: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 20,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 11,
                "3_linha_size_ini": 17,
                "3_linha_length": 10,
                "3_linha_min_size": 11,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 15,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 24,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 23,
                "3_linha_length": 13,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 10,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 20,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 20,
                "3_linha_length": 13,
                "3_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 23,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 23,
                "3_linha_length": 13,
                "3_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 13,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 18,
                "3_linha_length": 13,
                "3_linha_min_size": 14,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 19,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 19,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 16,
                "3_linha_length": 10,
                "3_linha_min_size": 11,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 22,
                "2_linha_length": 14,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 22,
                "3_linha_length": 14,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 18,
                "3_linha_length": 12,
                "3_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 23,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 23,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
                "3_linha_size_ini": 23,
                "3_linha_length": 14,
                "3_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 19,
                "1_linha_length": 12,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 19,
                "2_linha_length": 12,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 19,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 21,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 21,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 21,
                "3_linha_length": 13,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 18,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 10,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 10,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 18,
                "3_linha_length": 10,
                "3_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 9,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 17,
                "2_linha_length": 9,
                "2_linha_min_size": 10,
                "3_linha_size_ini": 17,
                "3_linha_length": 9,
                "3_linha_min_size": 10,
            },
        },
    },
    EFG: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 15,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 24,
                "2_linha_length": 15,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 24,
                "3_linha_length": 15,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 21,
                "1_linha_length": 15,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 21,
                "2_linha_length": 15,
                "2_linha_min_size": 11,
                "3_linha_size_ini": 21,
                "3_linha_length": 15,
                "3_linha_min_size": 11,
            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 28,
                "1_linha_length": 15,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 28,
                "2_linha_length": 15,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 28,
                "3_linha_length": 15,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 15,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 24,
                "2_linha_length": 15,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 24,
                "3_linha_length": 15,
                "3_linha_min_size": 16,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 13,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 26,
                "2_linha_length": 13,
                "2_linha_min_size": 16,
                "3_linha_size_ini": 26,
                "3_linha_length": 13,
                "3_linha_min_size": 16,
            }, COM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 13,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 24,
                "2_linha_length": 13,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 24,
                "3_linha_length": 13,
                "3_linha_min_size": 14,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 22,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 22,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            }, COM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 10,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 22,
                "2_linha_length": 10,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 22,
                "3_linha_length": 10,
                "3_linha_min_size": 12,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 25,
                "1_linha_length": 14,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 25,
                "2_linha_length": 14,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 25,
                "3_linha_length": 14,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 12,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 24,
                "2_linha_length": 12,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 24,
                "3_linha_length": 12,
                "3_linha_min_size": 14,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 26,
                "1_linha_length": 14,
                "1_linha_min_size": 17,
                "2_linha_size_ini": 26,
                "2_linha_length": 14,
                "2_linha_min_size": 17,
                "3_linha_size_ini": 26,
                "3_linha_length": 14,
                "3_linha_min_size": 17,
            }, COM_ICON: {
                "1_linha_size_ini": 25,
                "1_linha_length": 12,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 25,
                "2_linha_length": 12,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 25,
                "3_linha_length": 12,
                "3_linha_min_size": 15,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 25,
                "1_linha_length": 13,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 25,
                "2_linha_length": 13,
                "2_linha_min_size": 15,
                "3_linha_size_ini": 25,
                "3_linha_length": 13,
                "3_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 12,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 24,
                "2_linha_length": 12,
                "2_linha_min_size": 12,
                "3_linha_size_ini": 24,
                "3_linha_length": 12,
                "3_linha_min_size": 12,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 24,
                "1_linha_length": 10,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 24,
                "2_linha_length": 10,
                "2_linha_min_size": 14,
                "3_linha_size_ini": 24,
                "3_linha_length": 10,
                "3_linha_min_size": 14,
            }, COM_ICON: {
                "1_linha_size_ini": 22,
                "1_linha_length": 10,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 22,
                "2_linha_length": 10,
                "2_linha_min_size": 10,
                "3_linha_size_ini": 22,
                "3_linha_length": 10,
                "3_linha_min_size": 10,
            },
        },
    },
    MINI20: {
        TL1: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 9,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 17,
                "2_linha_length": 9,
                "2_linha_min_size": 11,
            }, COM_ICON: {
                "1_linha_size_ini": 14,
                "1_linha_length": 9,
                "1_linha_min_size": 9,
                "2_linha_size_ini": 14,
                "2_linha_length": 9,
                "2_linha_min_size": 9,

            },
        }, TL2: {
            SEM_ICON: {
                "1_linha_size_ini": 20,
                "1_linha_length": 11,
                "1_linha_min_size": 16,
                "2_linha_size_ini": 17,
                "2_linha_length": 14,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 11,
                "1_linha_min_size": 12,
                "2_linha_size_ini": 16,
                "2_linha_length": 11,
                "2_linha_min_size": 12,
            },
        }, TL3: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 11,
                "1_linha_min_size": 14,
                "2_linha_size_ini": 18,
                "2_linha_length": 11,
                "2_linha_min_size": 11.5,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 9,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 10,
            },
        }, TL4: {
            SEM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 10,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 16,
                "2_linha_length": 10,
                "2_linha_min_size": 10,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 7,
                "1_linha_min_size": 8,
                "2_linha_size_ini": 15,
                "2_linha_length": 7,
                "2_linha_min_size": 8,
            },
        }, TL5: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 12,
                "1_linha_min_size": 13,
                "2_linha_size_ini": 17,
                "2_linha_length": 12,
                "2_linha_min_size": 13,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 9,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 10,
            },
        }, TL6: {
            SEM_ICON: {
                "1_linha_size_ini": 18,
                "1_linha_length": 12,
                "1_linha_min_size": 15,
                "2_linha_size_ini": 18,
                "2_linha_length": 12,
                "2_linha_min_size": 15,
            }, COM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 10,
                "1_linha_min_size": 11,
                "2_linha_size_ini": 17,
                "2_linha_length": 10,
                "2_linha_min_size": 11,
            },
        }, TL7: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 11,
                "1_linha_min_size": 13,
                "2_linha_size_ini": 17,
                "2_linha_length": 11,
                "2_linha_min_size": 13,
            }, COM_ICON: {
                "1_linha_size_ini": 16,
                "1_linha_length": 9,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 16,
                "2_linha_length": 9,
                "2_linha_min_size": 10,
            },
        }, TL8: {
            SEM_ICON: {
                "1_linha_size_ini": 17,
                "1_linha_length": 8,
                "1_linha_min_size": 10,
                "2_linha_size_ini": 17,
                "2_linha_length": 8,
                "2_linha_min_size": 10,
            }, COM_ICON: {
                "1_linha_size_ini": 15,
                "1_linha_length": 6,
                "1_linha_min_size": 9,
                "2_linha_size_ini": 15,
                "2_linha_length": 6,
                "2_linha_min_size": 9,
            },
        },
    },
};