import {createSlice} from "@reduxjs/toolkit";

const userInfoFromStorage = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : "";
const authInitialState = {
    userLogin: {userInfo: userInfoFromStorage, loading: false, error: ""},
    // cart: {cartItems: cartItemsFromStorage},
};
const authSlice = createSlice({
    name: 'userAuth',
    initialState: authInitialState,
    reducers: {
        userLoginRequest(state) {
            return {loading: true};
        },
        userLoginSuccess(state, action) {
            return {loading: false, userInfo: action.payload};
        },
        userLoginFail(state, action) {
            return {loading: false, error: action.payload};
        },
        userLogout(state) {
            return {};
        },
    }
});

export const authActions = authSlice.actions;
export default authSlice;
