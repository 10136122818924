import {bgColorObj, txtColorObj} from "./constants/LabelsColorConstants";
import "./css/IMLabels.css";
import {lineSizes} from "./constants/LabelsConstants";
import React from "react";
import {useSelector} from "react-redux";

export default function IMLabels(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, isKitMember, iconSrc, icon;
    linha1=props.linha1;
    linha2=props.linha2;
    linha3=props.linha3;
    isKitMember=props.isKitMember;
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";
    return(
        <div id="label-tiketa-im" className="labelSimulationCard" style={{
                             zoom: isKitMember===1 ? "70%":"100%",
                         }}>
                    <div
                        className="label label-im"
                        style={{
                          background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorEsq,
                          display: labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "right" ? "none" : "",
                        }}
                    >
                    <div
                        className="label-brand label-im-brand"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconleft iconleft_im">
                        <img
                            id="iconLeft"
                            alt="labelIcon"
                            className="iconleft iconleft_im"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconEsq + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-sicon label-im-l1-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["IM"]["fontSizeL1_1"],
                        }}
                    >
                        {linha1}
                    </div>
                    <div
                        className="label-l2-sicon label-im-l2-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["IM"]["fontSizeL1_2"],
                        }}
                    >
                        {linha2}
                    </div>
                    <div
                        className="label-l3-sicon label-im-l3-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["IM"]["fontSizeL2_2"],
                        }}
                    >
                        {linha3}
                    </div>
                    <div
                        className="label-l1-cicon label-im-l1-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["IM"]["fontSizeL1_1"],
                        }}
                    >
                        {linha1}
                    </div>
                    <div
                        className="label-l2-cicon label-im-l2-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["IM"]["fontSizeL1_2"],
                        }}
                    >
                        {linha2}
                    </div>
                    <div
                        className="label-l3-cicon label-im-l3-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["IM"]["fontSizeL2_2"],
                        }}
                    >
                        {linha3}
                    </div>
                </div>
                <div
                    className="label label-im"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorDir,
                        display: labelConfigState.labelConfig.labelSimulationType === "icons" || (labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "left") ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand-dir label-im-brand-dir"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconright iconright_im">
                        <img
                            id="iconRight"
                            alt="labelIcon"
                            className="iconright iconright_im"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconDir + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-cicon-right label-im-l1-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["IM"]["fontSizeL1_1"],
                        }}
                    >
                        {linha1}
                    </div>
                    <div
                        className="label-l2-cicon-right label-im-l2-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["IM"]["fontSizeL1_2"],
                        }}
                    >
                        {linha2}
                    </div>
                    <div
                        className="label-l3-cicon-right label-im-l3-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["IM"]["fontSizeL2_2"],
                        }}
                    >
                        {linha3}
                    </div>
                </div>
            </div>
    );
}