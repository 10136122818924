import {bgColorObj, txtColorObj} from "./constants/LabelsColorConstants";
import "./css/EFGLabels.css";
import {lineSizes} from "./constants/LabelsConstants";
import React from "react";
import {useSelector} from "react-redux";

export default function EFGLabels(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, linha4, linha5, linha6, isKitMember, iconSrc, icon;
    linha1=props.linha1;
    linha2=props.linha2;
    linha3=props.linha3;
    linha4=props.linha4;
    linha5=props.linha5;
    linha6=props.linha6;
    isKitMember = props.isKitMember;
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";
    return(
        <div id="label-tiketa-efg" className="labelSimulationCard" style={{
                             zoom: isKitMember===1 ? "70%":"100%",
        }}>
                <div
                    className="label label-efg"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorEsq,
                        display: labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "right" ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand_efg"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconleft iconleft_efg">
                        <img
                            id="iconLeft"
                            alt="labelIcon"
                            className="iconleft iconleft_efg"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconEsq + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-sicon label-efg-l1-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["EFG"]["fontSizeL1_1"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha1}
                    </div>
                    <div
                        className="label-l2-sicon label-efg-l2-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["EFG"]["fontSizeL1_2"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha2}
                    </div>
                    <div
                        className="label-l3-sicon label-efg-l3-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["EFG"]["fontSizeL2_2"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha3}
                    </div>
                    <div
                        className="label-efg-l4-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["EFG"]["fontSizeL1_3"],
                        }}
                    >
                        {linha4}
                    </div>
                    <div
                        className="label-efg-l5-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["EFG"]["fontSizeL2_3"],
                        }}
                    >
                        {linha5}
                    </div>
                    <div
                        className="label-efg-l6-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["EFG"]["fontSizeL3_3"],
                        }}
                    >
                        {linha6}
                    </div>
                    <div
                        className="label-l1-cicon label-efg-l1-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL1_1"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha1}
                    </div>
                    <div
                        className="label-l2-cicon label-efg-l2-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL1_2"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha2}
                    </div>
                    <div
                        className="label-l3-cicon label-efg-l3-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL2_2"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha3}
                    </div>
                    <div
                        className="label-efg-l4-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL1_3"],
                        }}
                    >
                        {linha4}
                    </div>
                    <div
                        className="label-efg-l5-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL2_3"],
                        }}
                    >
                        {linha5}
                    </div>
                    <div
                        className="label-efg-l6-cicon"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL3_3"],
                        }}
                    >
                        {linha6}
                    </div>

                </div>
                <div
                    className="label label-efg"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorDir,
                        display: labelConfigState.labelConfig.labelSimulationType === "icons" || (labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "left") ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand-dir_efg"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconright iconright_efg">
                        <img
                            id="iconRight"
                            alt="labelIcon"
                            className="iconright iconright_efg"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconDir + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div
                        className="label-l1-cicon-right label-efg-l1-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL1_1"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha1}
                    </div>
                    <div
                        className="label-l2-cicon-right label-efg-l2-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL1_2"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha2}
                    </div>
                    <div
                        className="label-l3-cicon-right label-efg-l3-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL2_2"],
                        }}
                    >
                        {(linha4 !== "" || linha5 !== "" || linha6 !== "") ? "" : linha3}
                    </div>
                    <div
                        className="label-efg-l4-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL1_3"],
                        }}
                    >
                        {linha4}
                    </div>
                    <div
                        className="label-efg-l5-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL2_3"],
                        }}
                    >
                        {linha5}
                    </div>
                    <div
                        className="label-efg-l6-cicon-right"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorDir,
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            fontSize: lineSizes["EFG"]["fontSizeL3_3"],
                        }}
                    >
                        {linha6}
                    </div>
                </div>
            </div>
    );
}