import {createSlice} from "@reduxjs/toolkit";

const passwordUpdateSlice = createSlice({
    name: 'passwordUpdate',
    initialState: {},
    reducers: {
        passwordUpdateRequest() {
            return {loading: true};
        },
        passwordUpdateSuccess(state, action) {
            return {loading: false, success: true, userInfo: action.payload};
        },
        passwordUpdateFail(state, action) {
            return {loading: false, error: action.payload};
        },
        passwordUpdateReset() {
            return {};
        },
        default() {
            return {};
        },
    }
});

export const passwordUpdateActions = passwordUpdateSlice.actions;
export default passwordUpdateSlice;
