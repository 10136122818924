import "./css/ThemeSelector.css";
import React, { useState } from "react";

export default function ThemeSelector(props) {
  const [themeSel, setThemeSel] = useState(props.selectedTheme);

  const selectThemeHandler = (event) => {
    let selectedTheme = event.target.getAttribute("data-theme");
    let iconDir = event.target.getAttribute("data-icondir");
    let iconEsq = event.target.getAttribute("data-iconesq");
    let backColorDir = event.target.getAttribute("data-backcolordir");
    let backColorEsq = event.target.getAttribute("data-backcoloresq");
    let fontColorDir = event.target.getAttribute("data-fontcolordir");
    let fontColorEsq = event.target.getAttribute("data-fontcoloresq");
    let selectedThemeDesc = event.target.getAttribute("data-themedesc");

    setThemeSel(selectedTheme);
    props.onSelectedTheme(
      selectedTheme,
      iconEsq,
      backColorEsq,
      fontColorEsq,
      iconDir,
      backColorDir,
      fontColorDir,
      selectedThemeDesc,
    );
  };

  return (
    <div>
      <label className="field">Temas:</label>
      <div className="temas field-selection">
        {props.themes.map(
          ({
            theme_code,
            theme_desc,
            theme_selector,
            left_icon,
            left_bg_color,
            left_font_color,
            right_icon,
            right_bg_color,
            right_font_color,
          }) => {
            return (
              <img
                key={theme_code}
                src={theme_selector}
                alt="Tema 1"
                className={
                  theme_code === themeSel ? "single-theme selected" : "single-theme"
                }
                onClick={selectThemeHandler}
                data-theme={theme_code}
                data-themedesc={theme_desc}
                data-iconesq={left_icon}
                data-backcoloresq={left_bg_color}
                data-fontcoloresq={left_font_color}
                data-icondir={right_icon}
                data-backcolordir={right_bg_color}
                data-fontcolordir={right_font_color}
              />
            );
          }
        )}
      </div>
    </div>
  );
}
