import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import "./ClientDataForm.css";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {resetCart, updateClientData,updateShippingData} from "../../actions/cartActions";
import axios from "axios";
import MessageModal from "../../components/UI/MessageModal";
import {labelType} from "../../components/simulator/labels/constants/LabelsConstants";


export default function ClientData() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state)=> state.cart );
    const userLogin = useSelector((state) => state.userLogin);
    const [data,setData]=useState([]);
    const [ship,setShip]=useState("LM");
    const [shippingRates, setShippingRates] = useState([]);
    const [allShippingRates, setAllShippingRates] = useState([]);
    const history = useNavigate();
    const {userInfo} = useSelector((state) => state.userLogin);
    let errorNumber="";


    useEffect(() => {
        if (userInfo) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            async function fetchShippingRates() {
                const {data} = await axios.get(`/api/get_shipping_rates_by_value/?order_value=${cart.cart.totalAmmount}`, config);
                setShippingRates(data);
                setAllShippingRates(data);
            }
            fetchShippingRates();
        } else {
            history("/login/");
        }
    },[])
    const placeOrderHandler = (event) => {
        event.preventDefault();
        if(checkFields() )  {
            if (checkNIF(document.getElementById("clientNif").value) ){
                let clientData = {
                    clientName: document.getElementById("clientName").value,
                    clientEmail: document.getElementById("clientEmailAddress").value,
                    clientPhone: document.getElementById("clientPhoneNumber").value,
                }
                dispatch(updateClientData(clientData));
                // vai tentar gravar na base de dados
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${userLogin.userInfo.token}`
                    }
                }
                let arrayoflines = [];
                cart.cart.items.map((item, index) => (
                    arrayoflines.push({
                        "sku": item.labelConfig.labelSKU,
                        "lote_id": item.labelConfig.lote === "" ? "null" : item.labelConfig.lote,
                        "price": item.labelConfig.lote === "" ?
                                    item.labelConfig.subOptionsValue === 0 ?
                                        item.labelConfig.price
                                        :
                                        parseFloat(parseFloat(item.labelConfig.price) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2)
                                :
                                    item.labelConfig.subOptionsValue === 0 ?
                                        item.labelConfig.lotePrice
                                        :
                                        parseFloat(parseFloat(item.labelConfig.lotePrice) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) ,
                        "icon_code": item.labelConfig.icon,
                        "theme_code": item.labelConfig.theme,
                        "background_color": item.labelConfig.bkColor,
                        "font_color": item.labelConfig.txtColor,
                        "font": item.labelConfig.txtFont,
                        "linha1": item.labelConfig.linha1 === "" ? "null" : item.labelConfig.linha1,
                        "linha2": item.labelConfig.linha2 === "" ? "null" : item.labelConfig.linha2,
                        "linha3": item.labelConfig.linha3 === "" ? "null" : item.labelConfig.linha3,
                        "linhaLC": item.labelConfig.linhaLC === "" ? "null" : item.labelConfig.linhaLC,
                        "linhaLCM": item.labelConfig.linhaLCM === "" ? "null" : item.labelConfig.linhaLCM,
                        "product_up_sell_1": null,
                        "product_up_sell_2": null,
                        "quantity": item.labelConfig.quantity,
                        "subtotal": item.labelConfig.lotePrice === "" ?
                                            item.labelConfig.subOptionsValue === 0 ?
                                                item.labelConfig.price :
                                                parseFloat(parseFloat(item.labelConfig.price) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) :
                                            item.labelConfig.subOptionsValue === 0 ? item.labelConfig.lotePrice : parseFloat(parseFloat(item.labelConfig.lotePrice) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2) , //item.labelConfig.price : item.labelConfig.lotePrice  ,
                        "total": item.labelConfig.lotePrice === "" ?
                                            item.labelConfig.subOptionsValue === 0 ?
                                                (item.labelConfig.quantity * item.labelConfig.price).toFixed(2) :
                                                (item.labelConfig.quantity * parseFloat(parseFloat(item.labelConfig.price) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2)).toFixed(2) :
                                            item.labelConfig.subOptionsValue === 0 ? (item.labelConfig.quantity * item.labelConfig.lotePrice).toFixed(2) : (item.labelConfig.quantity * parseFloat(parseFloat(item.labelConfig.lotePrice) + parseFloat(item.labelConfig.subOptionsValue)).toFixed(2)).toFixed(2) ,//(item.labelConfig.quantity*item.labelConfig.price).toFixed(2) : (item.labelConfig.quantity*item.labelConfig.lotePrice).toFixed(2),
                        "image_blob": item.labelConfig.imageData,
                        "label_simulation_type": item.labelConfig.labelSimulationType,
                        "theme_model": item.labelConfig.model,
                        "theme_desc": item.labelConfig.labelSKU==="EPED" ? item.labelConfig.themeName : item.labelConfig.themeDesc,
                        "icon_desc": item.labelConfig.iconDesc,
                        "lote_desc": item.labelConfig.loteDesc === "" ? "null" : item.labelConfig.loteDesc,
                        "prod_desc": item.labelConfig.labelSKU==="PI" ? item.labelConfig.selectedSubOptionsDescriptions[item.labelConfig.labelType] : item.labelConfig.title,
                        "font_color_desc": item.labelConfig.txtColorDesc,
                        "bk_color_desc": item.labelConfig.bkColorDesc,
                        "order_line_number": index + 1,
                        "other_options_codes": item.labelConfig.otherOptionsCodes,
                        "other_options_objects": JSON.stringify(item.labelConfig.otherOptionsObjects),
                        "image_simulation_path": "",
                        "lote_ref": item.labelConfig.loteRef,
                        "sub_options_value": item.labelConfig.subOptionsValue,
                        "selected_sub_options_codes": JSON.stringify(item.labelConfig.selectedSubOptionsCodes),
                        "selected_sub_options_descriptions": JSON.stringify(item.labelConfig.selectedSubOptionsDescriptions),
                        "options_descriptions": JSON.stringify(item.labelConfig.optionsDescriptions),
                        "text_labels_objects": JSON.stringify(item.labelConfig.textLabelsObjects),
                        "despensa_theme": item.labelConfig.despensaTheme,
                    })
                ));
                const order = {
                    "user_id": userLogin.userInfo.id,
                    "store_id": userLogin.userInfo.store,
                    "order_number": 2,
                    "client_order_number": "null",
                    "status": userLogin.userInfo.is_kiosk_store ? "Pending" : "Processing", // alterei aqui se for kiosk store entra como "Pending"
                    "deliver_in_store": userLogin.userInfo.is_kiosk_store ? false : true, // se o pedido foi feito numa loja a entrega será feita na loja
                    "store_id_to_deliver": userLogin.userInfo.store, // id da loja para entrega, pode ser alterado para escolher outra (para já = à da encomenda)
                    "total": parseFloat(parseFloat(cart.cart.totalAmmount) + parseFloat(cart.cart.shippingCost)).toFixed(2),// total da encomenda, incluindo portes e descontos
                    "pos_store_reference": "null",
                    "client_name": document.getElementById("clientName").value,//cart.cart.client_data.client_name,
                    "client_address": document.getElementById("clientAddress").value === "" ? "null" : document.getElementById("clientAddress").value,
                    "client_postal_code": (document.getElementById("postalCode1").value === "" ? "0000" : document.getElementById("postalCode1").value) + "-" + (document.getElementById("postalCode2").value === "" ? "000" : document.getElementById("postalCode2").value),
                    "client_city": document.getElementById("clientCity").value === "" ? "null" : document.getElementById("clientCity").value,
                    "client_email_address": document.getElementById("clientEmailAddress").value === "" ? "null" : document.getElementById("clientEmailAddress").value,//cart.cart.client_data.client_email,
                    "client_phone_number": document.getElementById("clientPhoneNumber").value,//cart.cart.client_data.client_phone,
                    "order_lines": arrayoflines,
                    "exporting_date": "01-01-1900 00:00:00",
                    "shipping_date": "01-01-1900 00:00:00",
                    "shipping_ref": "null",
                    "invoice_date": "01-01-1900 00:00:00",
                    "invoice_number": "null",
                    "client_invoice_date": "01-01-1900 00:00:00",
                    "close_date": "01-01-1900 00:00:00",
                    "cancel_date": "01-01-1900 00:00:00",
                    "manufatured_date": "01-01-1900 00:00:00",
                    "client_nif": userLogin.userInfo.is_kiosk_store ? (document.getElementById("clientNif").value === "" ? "999999990" : document.getElementById("clientNif").value) : "999999990",
                    "subtotal": cart.cart.totalAmmount, // subtotal da encomenda (total dos artigos no carrinho de compras)
                    "client_postal_code_location": document.getElementById("postalCodeCity").value === "" ? "null" : document.getElementById("postalCodeCity").value,
                    "delivery_cost": cart.cart.shippingCost, // custo do envio
                    "delivery_method": cart.cart.shippingMethod, // Método de envio
                    "delivery_type": document.getElementById("shipSelect").options[document.getElementById("shipSelect").selectedIndex].value==="LM" ? "Levantamento em mãos" : "Entrega ao domicilio",
                    "client_dest_country": "PT", // pais destino do pedido
                    "payment_method": "", // método de pagamento
                    "client_invoice_ref": "" // referencia da fatura do cliente
                }

                async function saveOrder() {
                    const {data} = await axios.post('/api/order/new_order',
                        order, config
                    ).catch((error) => {
                        document.getElementById("backdrop").style.visibility = "";
                        if (error.response) {
                            errorNumber = error.response.status;
                            setTimeout(() => {
                                document.getElementById("backdrop").style.visibility = "hidden";
                            }, 1500);
                            return {data: {id: "Erro - " + error.response.status}};
                        } else if (error.request) {
                            errorNumber = error.request;
                            setTimeout(() => {
                                document.getElementById("backdrop").style.visibility = "hidden";
                            }, 1500);
                            return {data: {id: "Erro - " + error.request}};
                        } else {
                            errorNumber = error.message;
                            setTimeout(() => {
                                document.getElementById("backdrop").style.visibility = "hidden";
                            }, 1500);
                            return {data: {id: "Erro - " + error.message}};
                        }
                    })
                    setData(data);
                    if (typeof data !== 'undefined' && data.length !== 0 && String(data?.id).substring(0, 4) !== "Erro") {
                        dispatch(resetCart());
                        //navigate("/finalize_order",{ state: { order: data?.id, total: data?.total} }); alterei para saltar a pagina do finalize order
                        navigate("/print_order_page", {state: {orderId: data?.id, total: data?.total}});
                    }
                }

                saveOrder()
            } else   {
                alert("Número de contribuinte incorreto!");
            }
         } else {
            alert("Por favor preencha os campos obrigatórios!");
        }
    }
    const checkFields = () => {
        if(document.getElementById("clientName").value!=="" && document.getElementById("clientPhoneNumber").value!=="") {
            if (document.getElementById("shipSelect").options[document.getElementById("shipSelect").selectedIndex].value === "ED") {
                if(document.getElementById("clientAddress").value!=="" && document.getElementById("postalCode1").value!==""  && document.getElementById("postalCode2").value!==""  && document.getElementById("postalCodeCity").value!=="") {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
    }   }
    const selectShippmentTypeHandler = (event) => {
         let select = event.target;
         let valueCod = select.options[select.selectedIndex].value;
         setShip(valueCod);
         console.log("entrou aqui" + valueCod);
         if (valueCod === "ED") {
            let shippingData = {
                clientAddress: document.getElementById("clientAddress").value,
                clientPostalCode: document.getElementById("postalCode1").value + "-" + document.getElementById("postalCode2").value,
                clientCity: document.getElementById("postalCodeCity").value,
                shippingCost: document.getElementById("shipMethod").options[document.getElementById("shipMethod").selectedIndex].getAttribute("data-ship-cost"),
                shippingMethod: document.getElementById("shipMethod").options[document.getElementById("shipMethod").selectedIndex].getAttribute("data-ship-method-name"),
            }
            console.log(shippingData);
            dispatch(updateShippingData(shippingData));
         } else {
             let shippingData = {
                clientAddress: "",
                clientPostalCode: "",
                clientCity: "",
                shippingCost: 0,
                shippingMethod: "Levantamento na Loja",
            }
            dispatch(updateShippingData(shippingData));
         }

    }
    const getAvailableShippingMethods = (event) => {
        var cpValue = event.target.value;
        if(!(/^\d+$/.test(cpValue))) {
            alert("Por favor preencha o campo código postal com um código válido!!");
        }
        if(cpValue > 9999 || cpValue < 1) {
            alert("Por favor preencha o campo código postal com um código válido!!(1<=código postal<=9999)");
        }
        setShippingRates(allShippingRates.filter(item => {return item.shipping_postal_code_end >= cpValue;}));
    }
    const checkLength = (event) => {
        event.target.value=event.target.value.slice(0,event.target.dataset.maxlength);
    }
     const changeshipMethod = (event) => {
        console.log("Mudou o envio");
        let shippingData = {
                clientAddress: document.getElementById("clientAddress").value,
                clientPostalCode: document.getElementById("postalCode1").value + "-" + document.getElementById("postalCode2").value,
                clientCity: document.getElementById("postalCodeCity").value,
                shippingCost: document.getElementById("shipMethod").options[document.getElementById("shipMethod").selectedIndex].getAttribute("data-ship-cost"),
                shippingMethod: document.getElementById("shipMethod").options[document.getElementById("shipMethod").selectedIndex].getAttribute("data-ship-method-name"),
        }
        console.log(shippingData);
        dispatch(updateShippingData(shippingData));
    }


    const checkNIF = (nif) =>  {
        if (nif==="") {
            nif="999999990"
        }
        if(!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0,1)) &&
           !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0,2)))
            return false;

        let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;

        let modulo11 = total - parseInt(total / 11) * 11;
        let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

        return nif[8] == comparador

    }
    return(
        <Fragment>
            <div id="backdrop" className="backdrop" style={{visibility: "hidden"}}><MessageModal title="Erro" message={"Ocorreu um erro a gravar a sua encomenda (" + data.id + ")"}/></div>
            <form onSubmit={placeOrderHandler} >
                     <Row>
                        <Col sm={7} className="headerRow">
                                <span>Dados do Cliente:</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={7}>
                            <label className="field required">Nome:</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <input className="input-text" autoComplete="off" id="clientName" style={{width: "100%"}}/>
                        </Col>
                    </Row>
                { userLogin.userInfo.is_kiosk_store &&
                    <Fragment>
                        <Row>
                            <Col sm={7}>
                                <label className="field">Número de contribuinte:</label>
                            </Col>
                        </Row>
                        <Row>
                        <Col sm={10}>
                        <input className="input-text" autoComplete="off" id="clientNif" style={{width: "100%"}}/>
                        </Col>
                        </Row>
                    </Fragment>
                }
                    <Row>
                        <Col sm={7}>
                            <label className="field ">Endereço de Email:</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <input className="input-text" autoComplete="off" id="clientEmailAddress" style={{width: "100%"}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={7}>
                            <label className="field required">Contato Telefónico:</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <input className="input-text" autoComplete="off" id="clientPhoneNumber" style={{width: "100%"}}/>
                        </Col>
                    </Row>
                { userLogin.userInfo.is_kiosk_store &&
                    <Fragment>
                        <Row>
                            <Col sm={7}>
                                <label className="field required">Entrega:</label>
                            </Col>
                        </Row>
                        <Row>
                        <Col sm={10}>
                            <select  name="ship" id="shipSelect" title="" onChange={selectShippmentTypeHandler} defaultValue={"LM"}>
                                <option value='LM'>Levantamento na Loja</option>
                                <option value='ED'>Entrega numa Morada</option>
                            </select>
                        </Col>
                        </Row>
                    </Fragment>
                }
                    <div style={{display: userLogin.userInfo.is_kiosk_store && ship==="ED" ? "" : "none"}}>
                        <Row>
                            <Col sm={7}>
                                <label className="field required">Morada:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10}>
                                <input className="input-text" autoComplete="off" id="clientAddress" style={{width: "100%"}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={7}>
                                <label className="field required">Código Postal:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10}>
                                <input type="number"  data-maxlength="4" onInput={checkLength} className="input-text" onBlur={getAvailableShippingMethods} placeholder="XXXX" autoComplete="off" id="postalCode1" style={{width: "20%"}}/>-<input type="number" data-maxlength="3" onInput={checkLength} className="input-text" placeholder="XXX" autoComplete="off" id="postalCode2" style={{width: "15%"}}/> &nbsp; <input className="input-text" placeholder="Localidade" autoComplete="off" id="postalCodeCity" style={{width: "62%"}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={7}>
                                <label className="field">Distrito/Concelho:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10}>
                                <input className="input-text" autoComplete="off" id="clientCity" style={{width: "100%"}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={7}>
                                <label className="field required">Método de Envio:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10}>
                                <div>
                                    <select id="shipMethod" onChange={changeshipMethod}>
                                        {shippingRates.map(
                                            ({id,shipping_cost,shipping_method_name}) => {
                                                return (
                                                    <option
                                                        value={id}
                                                        key={id}
                                                        data-ship-id = {id}
                                                        data-ship-cost={shipping_cost}
                                                        data-ship-method-name={shipping_method_name}
                                                    >
                                                        {shipping_method_name + ( shipping_cost==="0.00" ? " " :  " + " + shipping_cost + "€")}
                                                    </option>
                                                );
                                            }
                                            )}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </div>
                     <Row>
                        <Col sm={4}>
                            &nbsp;
                          </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                                <Button type="submit" to="/place_order" >
                                Encomendar &nbsp;&gt;&gt;
                                </Button>
                        </Col>
                    </Row>
                </form>
        </Fragment>

    );
}