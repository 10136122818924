import "./css/SchoolSubjectSelector.css"
import {Fragment, useState} from "react";

export default function SchoolSubjectSelector(props) {
    const disciplinas = props.options;
    const [arraySelectedSubject, setArraySelectedSubject] = useState(props.arraySelectedSubject);
    console.log(arraySelectedSubject);

    const addSubjectHandler = (event) => {
        let aSelectedSubject4Update = arraySelectedSubject;
        let newSubjectObject ="";
        if(arraySelectedSubject.length<props.options2Select) {
            newSubjectObject = {
                    subject_name: event.target.getAttribute("data-subj-name"),
                    subject_code: event.target.getAttribute("data-subj-code")
            }
            setArraySelectedSubject((prevState) => prevState.concat(newSubjectObject) );
        }
        //console.log(arraySelectedSubject);
        let array4Update = arraySelectedSubject.concat(newSubjectObject);
        props.onSelectedSubjects(array4Update);
    }
    const removeSubjectHandler = (event) => {
        let newArray = arraySelectedSubject.filter((value,index)=>{return index!==parseInt(event.target.getAttribute("data-subj-index"))}); //value.subject_code!==event.target.getAttribute("data-subj-code")
        //console.log(newArray);
        /*setArraySelectedSubject(arraySelectedSubject => arraySelectedSubject.filter((value,index)=>{
            return index!==parseInt(event.target.getAttribute("data-subj-index")) //value.subject_code!==event.target.getAttribute("data-subj-code")
        }));*/
       setArraySelectedSubject(newArray);
        props.onSelectedSubjects(newArray);
    }
    return(
        <Fragment>
            <label className="field">Selecione as disciplinas :</label>
            <div className="subjectSelector">
                {props.options.map(
                    ({
                        subject_code,
                        subject_name,
                        subject_group,
                        subject_order,
                    }) => {
                        return (
                            <div
                                key={subject_code}
                                className="subjectDiv"
                                data-subj-code={subject_code}
                                data-subj-name={subject_name}
                                onClick={addSubjectHandler}
                            >
                                {subject_name}
                            </div>
                        );
                    }
                )
                }
            </div>
            <label className="field"> Disciplinas Selecionadas: {arraySelectedSubject.length}/{props.options2Select}</label>
            <div className="subjectSelector">
                {arraySelectedSubject.length === 0 &&
                    <div className="subjectDiv">Sem Opções Selecionadas</div>
                }
                {arraySelectedSubject.map(({subject_name,subject_code}, index) => {
                        return (
                            <div
                                key={99 + index}
                                className="subjectDiv"
                                data-subj-code={subject_code}
                                data-subj-name={subject_name}
                                data-subj-index={index}
                                onClick={removeSubjectHandler}
                            >
                                {subject_name} | X
                            </div>
                        );
                    }
                )}
            </div>
        </Fragment>
    );
}