export function isLandscape(verticalLayout) {
    const orientation = window.matchMedia("(orientation: landscape)");
    if(orientation.matches && verticalLayout) {
        //console.log(orientation);
        return true;
    } else if(!orientation.matches && verticalLayout) {
        //console.log(orientation);
        return false;
    }else {
        return true;
    }
}