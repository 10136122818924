import "./css/BackgroundAndTextColorSelector.css";
import React, {useState} from "react";

const bkColors = [
    {id: "whiteBk", classCss: "single-color color-add branco",desc: "Branco"},
    {id: "redBk", classCss: "single-color color-add vermelhobk",desc: "Vermelho"},
    {id: "yellowBk", classCss: "single-color color-add amarelo",desc: "Amarelo"},
    {id: "orangeBk", classCss: "single-color color-add laranja",desc: "Laranja"},
    {id: "lhtGreyBk", classCss: "single-color color-add cinzacl",desc: "Cinzento"},
    {id: "lhtPinkBk", classCss: "single-color color-add rosacl",desc: "Rosa Claro"},
    {id: "pinkBk", classCss: "single-color color-add rosabk",desc: "Rosa"},
    {id: "dkBlueBk", classCss: "single-color color-add azules",desc: "Azul Escuro"},
    {id: "blueBk", classCss: "single-color color-add azulbk",desc: "Azul"},
    {id: "lhtBlueBk", classCss: "single-color color-add azulcl",desc: "Azul Claro"},
    {id: "dkGreenBk", classCss: "single-color color-add verdees",desc: "Verde Escuro"},
    {id: "greenBk", classCss: "single-color color-add verdebk",desc: "Verde"},
    {id: "lhtGreenBk", classCss: "single-color color-add verdecl",desc: "Verde Claro"},
    {id: "dkPurpleBk", classCss: "single-color color-add roxoes-inverted",desc: "Roxo Escuro"},
    {id: "purpleBk", classCss: "single-color color-add roxo",desc: "Roxo"},
];

function BackgroundColorSelector(props) {
    const [bkColorSel, setBkColorSel] = useState(props.selectedBkColor);

    const selectBkColorHandler = (event) => {
        let selectedBkColor = event.target.getAttribute("data-bkcolor");
        let selectedBkColorDesc = event.target.getAttribute("data-bkcolordesc");
        setBkColorSel(selectedBkColor);
        props.onSelectedBkColor(selectedBkColor,selectedBkColorDesc);
    };

    return (
        <div>
            <label className="field">Cor do Fundo:</label>
            <div className="field-selection">
                {bkColors.map(({id, classCss,desc}) => {
                    return (
                        <div
                            key={id}
                            id={id}
                            data-bkcolor={id}
                            data-bkcolordesc={desc}
                            className={
                                id === bkColorSel
                                    ? props.disableColors.indexOf(id) > -1
                                        ? classCss + " not-allowed"
                                        : classCss + " selected"
                                    : props.disableColors.indexOf(id) > -1
                                        ? classCss + " not-allowed"
                                        : classCss
                            }
                            onClick={selectBkColorHandler}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
}

export default BackgroundColorSelector;
