import "./css/PersonalizedSubOptionSelector.css";

import React, {Fragment, useEffect, useState} from "react";

function PersonalizedSubOptionSelector(props) {
    const subOptions = props.subOptions;
    const optionCode = props.optionCode;
    const subOptionsSelected= props.selectedSubOptions;
    const [selectSubOption, setSelectSubOption] = useState([]);
    useEffect(()=>{
        //set initial lote
        setSelectSubOption(props.selectedSubOptions)
    },[props.selectedSubOptions]);

    const getSubOptionsByOptionCode = (option_code) => {
        return subOptions.filter((subOption) => subOption.option_code === option_code);
    };
    const selectSubOptionHandler = (event) => {
        // se for RADIO
        const subOptionCode = event.target.getAttribute("data-suboption-code");
        const optionCode = event.target.getAttribute("data-option");
        const optionValue = event.target.getAttribute("data-value");

        // opção selecionada antes do clique
        let prevSelectedSubOption=selectSubOption;
        setSelectSubOption(event.target.getAttribute("data-suboption-code"));
        console.log(optionCode + "-" + prevSelectedSubOption + "=>" + subOptionCode + "-" + optionValue + " " + props.optionType);
        props.onSelectedSubOption(subOptionCode,optionCode,optionValue,prevSelectedSubOption);
    }
    const changeSubOptionHandler = (event) => {
        // se for SELECT
        const optionSelected = event.target.options[event.target.selectedIndex];
        console.log(optionSelected.value);
        const subOptionCode = optionSelected.getAttribute("data-suboption-code");
        const optionCode = optionSelected.getAttribute("data-option");
        const optionValue = optionSelected.getAttribute("data-value");

        // opção selecionada antes do clique
        let prevSelectedSubOption=selectSubOption;
        setSelectSubOption(optionSelected.getAttribute("data-suboption-code"));
        console.log(optionCode + "-" + prevSelectedSubOption + "=>" + subOptionCode + "-" + optionValue + " " + props.optionType);
        props.onSelectedSubOption(subOptionCode,optionCode,optionValue,prevSelectedSubOption);
    }
    return (
        <Fragment>
            {props.optionType === "radio" &&
                   <div className="field-selection" id={optionCode}>
                                {getSubOptionsByOptionCode(optionCode).map(
                                    ({sub_option_desc,sub_option_code,is_default_selected,sub_option_value,}) => {
                                        return (
                                          <div
                                            key={sub_option_code}
                                            data-suboption-code = {sub_option_code}
                                            data-value={sub_option_value}
                                            data-option={optionCode}
                                            className={sub_option_code===subOptionsSelected? "single-font selected" : "single-font"}
                                            onClick={selectSubOptionHandler}
                                          >
                                              {sub_option_desc + ( sub_option_value==="0.00" ? " " :  " + " + sub_option_value + "€")}
                                          </div>
                                        );
                                    }
                                )}
                   </div>
            }
            {props.optionType ==="select" &&
                <div>
                    <select id={optionCode} onChange={changeSubOptionHandler}>
                        {getSubOptionsByOptionCode(optionCode).map(
                            ({sub_option_desc,sub_option_code,is_default_selected,sub_option_value,}) => {
                                return (
                                    <option
                                        value={sub_option_code}
                                        key={sub_option_code}
                                        data-suboption-code = {sub_option_code}
                                        data-value={sub_option_value}
                                        data-option={optionCode}
                                        selected={sub_option_code===subOptionsSelected? true : false}
                                    >
                                        {sub_option_desc + ( sub_option_value==="0.00" ? " " :  " + " + sub_option_value + "€")}
                                    </option>
                                );
                            }
                            )}
                    </select>
                </div>
            }
        </Fragment>
    );

}

export default PersonalizedSubOptionSelector;
