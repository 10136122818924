import { labelConfigActions } from '../store/labelConfigSlice';
import {addItem2Cart} from "./cartActions";


export const setLabelSKUandLabelType = (sku) => (dispatch) => {
    dispatch(labelConfigActions.updateSKUandLabelType(sku));
}
export const updateLinha1 = (linha1) => (dispatch) => {
    dispatch(labelConfigActions.updateLinha1(linha1));
}
export const updateLinha2 = (linha2) => (dispatch) => {
    dispatch(labelConfigActions.updateLinha2(linha2));
}
export const updateLinha3 = (linha3) => (dispatch) => {
    dispatch(labelConfigActions.updateLinha3(linha3));
}
export const updateLinhaLC = (linhaLC) => (dispatch) => {
    dispatch(labelConfigActions.updateLinhaLC(linhaLC));
}
export const updateLinhaLCM = (linhaLCM) => (dispatch) => {
    dispatch(labelConfigActions.updateLinhaLCM(linhaLCM));
}
export const updateSelectedIcon = (icon) => (dispatch) => {
    dispatch(labelConfigActions.updateSelectedIcon(icon));
}
export const updateThemeAttrib = (themeAttrib) => (dispatch) => {
    dispatch(labelConfigActions.updateThemeAttrib(themeAttrib));
}
export const updateLabelTitle4Cart = (title) => (dispatch) => {
    dispatch(labelConfigActions.updateLabelTitle4Cart(title));
}
export const updateModel = (model) => (dispatch) => {
    dispatch(labelConfigActions.updateModel(model));
}
export const updateTextColor = (colorObj) => (dispatch) => {
    dispatch(labelConfigActions.updateTextColor(colorObj));
}
export const updateBkColor = (bkColor) => (dispatch) => {
    dispatch(labelConfigActions.updateBkColor(bkColor));
}
export const updateTxtFont = (txtFont) => (dispatch) => {
    dispatch(labelConfigActions.updateTxtFont(txtFont));
}
export const updateLote = (loteConfig) => (dispatch) => {
    dispatch(labelConfigActions.updateLote(loteConfig));
}
export const updateLabelSimulationType = (labelSimulationType) => (dispatch) => {
    dispatch(labelConfigActions.updateLabelSimulationType(labelSimulationType));
}
export const updateQuantity = (quantity) => (dispatch) => {
    dispatch(labelConfigActions.updateQuantity(quantity));
}
export const resetLabelConfig = () => (dispatch) => {
    dispatch(labelConfigActions.resetLabelConfig());
}
export const updateImageData = (imageData) => (dispatch) => {
    dispatch(labelConfigActions.updateImageData(imageData));
}
export const updateLabelConfig = (labelData) => (dispatch) => {
    dispatch(labelConfigActions.updateLabelConfig(labelData));
}
export const updateCartItemNumber = (itemNumber) => (dispatch) => {
    dispatch(labelConfigActions.updateCartItemNumber(itemNumber));
}
export const updatePrice = (price) => (dispatch) => {
    dispatch(labelConfigActions.updatePrice(price));
}
export const updateSubjects = (subjectsConfig) => (dispatch) => {
    dispatch(labelConfigActions.updateSubjects(subjectsConfig));
}
export const updateARP = (ARPObj) => (dispatch) => {
    dispatch(labelConfigActions.updateARP(ARPObj));
}
export const updatesubOptionsValue = (subOptionsValue) => (dispatch) => {
    dispatch(labelConfigActions.updateSubOptionsValue(subOptionsValue));
}
export const updateSelectedSubOptionsCodes = (selectedSubOptionsCodes) => (dispatch) => {
    dispatch(labelConfigActions.updateSelectedSubOptionsCodes(selectedSubOptionsCodes));
}
export const updateSelectedSubOptionsDescriptions = (selectedSubOptionsDescriptions) => (dispatch) => {
    dispatch(labelConfigActions.updateSelectedSubOptionsDescriptions(selectedSubOptionsDescriptions));
}
export const updateOptionsDescriptions = (optionsDescriptions) => (dispatch) => {
    dispatch(labelConfigActions.updateOptionsDescriptions(optionsDescriptions));
}
export const resetFontAndSimulationType = () => (dispatch) => {
    dispatch(labelConfigActions.resetFontAndSimulationType());
}
export const updateTextLabelsObjects = (textLabelsConfig) => (dispatch) => {
    dispatch(labelConfigActions.updateTextLabelsObjects(textLabelsConfig));
}
export const updateDespensaThemeAttrib = (despensaThemeAttrib) => (dispatch) => {
    dispatch(labelConfigActions.updateDespensaThemeAttrib(despensaThemeAttrib));
}
export const updateDespensaBackgroundImage = (labelBackgroundImage) => (dispatch) => {
    dispatch(labelConfigActions.updateDespensaBackgroundImage(labelBackgroundImage));
}
export const updateHasPersonalizedOptions = (hasPersonalizedOptions) => (dispatch) => {
    dispatch(labelConfigActions.updateHasPersonalizedOptions(hasPersonalizedOptions));
}
export const updateIsKit = (isKit) => (dispatch) => {
    dispatch(labelConfigActions.updateIsKit(isKit));
}