import React, {useEffect, useMemo, useState} from "react";
import {CSVLink} from "react-csv";
import {Table, Button} from "react-bootstrap";
import "./OrderListPage.css";
import Message from "../../components/Message";
import Loader from "../../components/Loader";

import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Checkbox from "../../components/Checkbox";
import {listOrders, searchOrder, searchOrderByPattern} from "../../actions/orderListActions";
import {orderListActions} from "../../store/orderListSlice";
//axios.defaults.xsrfCookieName = 'csrftoken'
//axios.defaults.xsrfHeaderName = 'X-CSRFToken'

function OrderListPage(props) {
    const {status} = useParams();
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const orderList = useSelector(state => state.orderList);
    const {loading, error, orders} = orderList;
    const navigate = useNavigate();
    const date = new Date();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    //const [totalSort,setTotalSort] = useState("");
    const [ordersToExport, setOrdersToExport] = useState([]);
    const [selectedStatus, setselectedStatus] = useState(status);
    const [selectedSearch, setselectedSearch] = useState("orderIdSearch");
    const [sortConfig,setSortConfig] = useState({key: "", direction: ""})
    const [data,setData]=useState([]);
    const statusMap = new Map();
    const modelMap = new Map();
    // tem de ir buscar este informação dinamicamente à BD
    const prod3Linhas = ['AG','EFG','KRAE','KRAC','KRAP','KRAB','KIT2','KIT5','KRAR','TGC'];
    const prodHasLC = ['KRAE','KRAC','KRAB','KRAR'];
    const prodHasLCM = ['KRAP'];
    statusMap.set("Pending", "Pendente de Pagamento");
    statusMap.set("Processing", "Pago");
    statusMap.set("Processing Exported", "Em Produção");
    statusMap.set("Manufactured", "Produzido");
    statusMap.set("Shipped", "Enviado");
    statusMap.set("Invoiced", "Faturado");
    statusMap.set("Canceled","Cancelado");
    statusMap.set("Closed","Fechado");
    modelMap.set("both", "Ambos");
    modelMap.set("right", "Direita");
    modelMap.set("left", "Esquerda");


    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(orders.map(li => li.id.toString()));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const sendOrderUpdate = () => {
        const keysToExport = Object.values(isCheck)
        const config = {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${userLogin.userInfo.token}`,
                    'X-CSRFToken': getCookie('csrftoken')
                }
        }
        let data2update = {
            user_id:userLogin.userInfo.id,
            id_list: keysToExport,
        }
        async function updateOrders() {
            const {data} = await axios.put('/api/order/update_exported_orders/', data2update , config).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
            // estas chamadas tem de ser realizadas aqui dentro caso contrario não actualiza a tabela
            dispatch(listOrders("Processing Exported"));
            setselectedStatus("Processing Exported");
            setIsCheck([]);
        }
        updateOrders();
    }

    const orderShippedHandler = () => {
        const keysToExport = Object.values(isCheck)
        const shipReference = document.getElementById("shipRef").value;
        const config = {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${userLogin.userInfo.token}`,
                    'X-CSRFToken': getCookie('csrftoken')
                }
        }
        let data2update = {
            user_id:userLogin.userInfo.id,
            id_list: keysToExport,
            shipRef: shipReference,
        }
        async function updateOrders2Shipped() {
            const {data} = axios.put('/api/order/update_shipped_orders/', data2update , config).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 //return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
        }
        updateOrders2Shipped();
        // actualiza a tabela com os pedidos no novo estado e tira a selecção dis pedidos
        setselectedStatus("Shipped");
        dispatch(listOrders("Shipped"));
        setIsCheck([]);
    }
    const orderInvoicedHandler = () => {
        const keysToExport = Object.values(isCheck)
        const invoiceReference = document.getElementById("invoiceRef").value;
        const config = {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${userLogin.userInfo.token}`,
                    'X-CSRFToken': getCookie('csrftoken')
                }
        }
        let data2update = {
            user_id:userLogin.userInfo.id,
            id_list: keysToExport,
            invoiceRef: invoiceReference,
        }
        async function updateOrders2Invoiced() {
            const {data} = axios.put('/api/order/update_invoiced_orders/', data2update , config).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 //return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
        }
        updateOrders2Invoiced();
        // actualiza a tabela com os pedidos no novo estado e tira a selecção dis pedidos
        setselectedStatus("Invoiced");
        dispatch(listOrders("Invoiced"));
        setIsCheck([]);
    }
    const orderManufacturedHandler = () => {
        const keysToExport = Object.values(isCheck)
        const config = {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${userLogin.userInfo.token}`,
                    'X-CSRFToken': getCookie('csrftoken')
                }
        }
        let data2update = {
            user_id:userLogin.userInfo.id,
            id_list: keysToExport,
        }
        async function updateOrders2Manuf() {
            const {data} = axios.put('/api/order/update_manufactured_orders/', data2update , config).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 //return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
        }
        updateOrders2Manuf();
        // actualiza a tabela com os pedidos no novo estado e tira a selecção dis pedidos
        setselectedStatus("Manufactured");
        dispatch(listOrders("Manufactured"));
        setIsCheck([]);
    }

    //const ordersExportAndChangeStateHandler = () => {
    //    sendOrderUpdate();

    //}
    const ordersExportHandler = () => {
        getOrdersToExport();
    }
    const replaceNullByEmptyString= (stringValue) => {
        return stringValue ==="null" ? "" :  stringValue;
    }
    const getOptProd = (line,number) => {
        switch (number) {
            case 0:
                if(line.sku==="EPED") {
                    return "txtcolor";
                }else if(line.label_simulation_type!=="" && line.sku !=="ARP") {
                    return line.label_simulation_type.substring(0,line.label_simulation_type.length - 1);
                } else {
                    return "999END";
                }
            case 1:
                if(line.sku==="EPED") {
                    return "color";
                }else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        return line.icon_desc;
                    } else {
                        return line.theme_desc;
                    }
                } else {
                    return "";
                }
            case 2:
                if(line.sku==="EPED") {
                    return "text1";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        return "txtcolor";
                    } else {
                        return "model";
                    }
                } else {
                    return "";
                }
            case 3:
                if (line.sku==="EPED") {
                    return replaceNullByEmptyString(line.linha1);
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        return line.font_color_desc;
                    } else {
                        return modelMap.get(line.theme_model);
                    }
                } else {
                    return "";
                }
            case 4:
                if (line.sku==="EPED") {
                    return "text2";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        return "background";
                    } else {
                        if(line.sku==="ALC")
                            return "text_pencil";
                        if(line.sku==="ALCM")
                            return "text_pencil_mini";
                        return "text1";
                    }
                } else {
                    return "";
                }
            case 5:
                if (line.sku==="EPED") {
                    return replaceNullByEmptyString(line.linha2);
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        return line.bk_color_desc;
                    } else {
                        if(line.sku==="ALC")
                            return replaceNullByEmptyString(line.linhaLC);
                        if(line.sku==="ALCM")
                            return replaceNullByEmptyString(line.linhaLCM);
                        return replaceNullByEmptyString(line.linha1);
                    }
                } else {
                    return "";
                }
            case 6:
                if (line.sku==="EPED") {
                    return "font";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        if(line.sku==="ALC")
                            return "text_pencil";
                        if(line.sku==="ALCM")
                            return "text_pencil_mini";
                        return "text1";
                    } else {
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "font";
                        return "text2";
                    }
                } else {
                    return "";
                }
            case 7:
                if (line.sku==="EPED") {
                    return line.font;
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        if(line.sku==="ALC")
                            return replaceNullByEmptyString(line.linhaLC);
                        if(line.sku==="ALCM")
                            return replaceNullByEmptyString(line.linhaLCM);
                        return replaceNullByEmptyString(line.linha1);
                    } else {
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return line.font;
                        return replaceNullByEmptyString(line.linha2);
                    }
                } else {
                    return "";
                }
            case 8:
                if (line.sku==="EPED") {
                    return "999END";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "font";
                        return "text2";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "999END";
                        if(prod3Linhas.includes(line.sku))
                            return "text3";
                        return "font";
                    }
                } else {
                    return "";
                }
            case 9:
                if (line.sku==="EPED") {
                    return "";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return line.font;
                        return replaceNullByEmptyString(line.linha2);
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku))
                            return replaceNullByEmptyString(line.linha3);
                        return line.font;
                    }
                } else {
                    return "";
                }
            case 10:
                if (line.sku==="EPED") {
                    return "";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "999END";
                        if(prod3Linhas.includes(line.sku))
                            return "text3";
                        return "font";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "text_pencil_mini";
                            if(prodHasLC.includes(line.sku))
                                return "text_pencil";
                            return "font";
                        } else {
                            return "999END";
                        }
                        return "999END";
                    }
                } else {
                    return "";
                }
            case 11:
                if (line.sku==="EPED") {
                    return "";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku))
                            return replaceNullByEmptyString(line.linha3);
                        return line.font;
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return replaceNullByEmptyString(line.linhaLCM);
                            if(prodHasLC.includes(line.sku))
                                return replaceNullByEmptyString(line.linhaLC);
                            return line.font;
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 12:
                if (line.sku==="EPED") {
                    return "";
                } else if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "text_pencil_mini";
                            if(prodHasLC.includes(line.sku))
                                return "text_pencil";
                            return "font"
                        } else {
                            return "999END";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "font";
                            if(prodHasLC.includes(line.sku))
                                return "text_pencil_mini";
                            return "999END";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 13:
                if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return replaceNullByEmptyString(line.linhaLCM);
                            if(prodHasLC.includes(line.sku))
                                return replaceNullByEmptyString(line.linhaLC);
                            return line.font
                        } else {
                            return "";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return line.font;
                            if(prodHasLC.includes(line.sku))
                                return replaceNullByEmptyString(line.linhaLCM);
                            return "";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 14:
                if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "font";
                            if(prodHasLC.includes(line.sku))
                                return "text_pencil_mini";
                            return "999END"
                        } else {
                            return "";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "999END";
                            if(prodHasLC.includes(line.sku))
                                return "font";
                            return "";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 15:
                if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return line.font;
                            if(prodHasLC.includes(line.sku))
                                return replaceNullByEmptyString(line.linhaLCM);
                            return ""
                        } else {
                            return "";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return line.font;
                            return "";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 16:
                if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "999END";
                            if(prodHasLC.includes(line.sku))
                                return "font";
                            return ""
                        } else {
                            return "";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return "999END";
                            return "";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 17:
                if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return line.font;
                            return ""
                        } else {
                            return "";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return "";
                            return "";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 18:
                if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return "999END";
                            return ""
                        } else {
                            return "";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return "";
                            return "";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
            case 19:
                if (line.label_simulation_type!=="") {
                    if (line.label_simulation_type === "icons") {
                        // etiquetas escolha a gosto desenho
                        if(line.sku==="ALC" || line.sku==="ALCM")
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return "";
                            return ""
                        } else {
                            return "";
                        }
                        return "";
                    } else {
                        // etiquetas Temas
                        if(line.sku==="ALC" || line.sku==="ALCM" )
                            return "";
                        if(prod3Linhas.includes(line.sku)) {
                            if (prodHasLCM.includes(line.sku))
                                return "";
                            if(prodHasLC.includes(line.sku))
                                return "";
                            return "";
                        } else {
                            return "";
                        }
                        return "";
                    }
                } else {
                    return "";
                }
        }
    }

    const getOtherOptions = (sku,options,loteId) => {
        console.log("Entrou aqui" + sku + "-" + options)
        if(options!=="") {
            let stringResult = "-"
            if(sku!=="ARP") {
                let dataArray = options.split("-");
                dataArray.forEach((element, index, dataArray) => {
                    stringResult = stringResult + "E" + ("0" + (index + 1)).slice(-2) + element + "-";
                })
                return stringResult.slice(0, -1);
            } else if(sku==="ARP" && loteId==='LT26') { // lote do reforço repositivo redondos
                // TODO: Retirar o LT26 para reconhecer as redondas
                if(options==="PT")
                    return stringResult + "4";
                if(options==="ES")
                    return stringResult + "5";
                if(options==="EN")
                    return stringResult + "6";
            } else if(sku==="ARP") {
                if (options === "PT")
                    return stringResult + "1";
                if (options === "ES")
                    return stringResult + "3";
                if (options === "EN")
                    return stringResult + "2";
            }
        }
        return "";
    }

     const getTextLabelsOptions = (sku,options,fontColor,despensaTheme) => {
        // tratar os objectos que contem as labels das EACXMED
         if(options!=="" && sku==="EACXMED") {
             let stringResult = "-" + fontColor + "-" ;
             stringResult = stringResult + JSON.parse(options).map((el)=>el.label_code).join("-");
             return stringResult;
        } else if(options!=="" && sku.substring(0,5)==="EAESP") {
             // trata as etiquetas da despensa
             let stringResult = "-" + despensaTheme + "-" ;
             stringResult = stringResult + JSON.parse(options).map((el)=>el.label_code).join("-");
             return stringResult;
         }
        return "";
    }
    const getSubOptions = (sku, subOptions) => {
        let stringResult=""
        if(sku!=="BM" && sku!=="PI" )
            stringResult = "-";
        const parsedSubOptions = JSON.parse(subOptions);
        console.log(sku);
        console.log(parsedSubOptions);
        if(sku==="KRD") {
            Object.keys(parsedSubOptions).reverse().map((el) => {
                    stringResult = stringResult + parsedSubOptions[el] + "-";
            })
        } if(sku==="KPB" || sku==="KPC" ) {
            Object.keys(parsedSubOptions).map((el) => {
                stringResult = stringResult + parsedSubOptions[el].substring(0,4) + "-";
            })
        }
        else {
            Object.keys(parsedSubOptions).map((el) => {
                stringResult = stringResult + parsedSubOptions[el] + "-";
            })
        }
        return stringResult.slice(0, -1);
    }
    const getSubOptionsDescriptions = (sku,lote, subOptions) => {
        let stringResult=""
        stringResult = "-";
        const parsedSubOptions = JSON.parse(subOptions);
        Object.keys(parsedSubOptions).map((el,index)=> {
            if (index===0)
                stringResult = stringResult +  parsedSubOptions[el] + "-" + sku+lote + "-";
            else
                if (parsedSubOptions[el]!=="Sem Caneta")
                    stringResult = stringResult +  parsedSubOptions[el] + "-";
        })
        return stringResult.slice(0, -1);
    }
    const getSKU2Export = (sku) => {
        if(sku==="EF")
            return "TR";
        if(sku==="EFG" || sku==="TGC" )
            return "TG";
        if(sku==="BM")
            return "";
        // Compatibilidade com o ficheiro do magento KPB e KPC
        if(sku==="KPB")
            return "KPB-TR-AR-EC"
        if(sku==="KPC")
            return "KPC-TR-AR-EC-IM"
        return sku;
    }
    const getProductSKU = (line) => {
        if(line.sku==="EPED") {
            return getSKU2Export(line.sku) + (10 - parseInt(line.despensa_theme.substring(5))).toString() + "-" + getSKU2Export(line.sku) + (10 - parseInt(line.despensa_theme.substring(5))).toString() + line.lote_ref;
        } else if(line.lote_ref !== "null" && line.lote_ref!==null && line.sku!=="ARP" && line.sku!=="ADISC" && line.sku!=="EFMN" && line.sku!=="EFM" && line.sku!=="BM" && line.sku!=="BA" && line.sku!=="PI" && line.sku!=="TGC") {
            return getSKU2Export(line.sku) + "-" + getSKU2Export(line.sku) + line.lote_ref;
        } else if(line.sku==="TGC") {
            // esta exceção foi feita para estar compativel com o ficheiro exportado pelo magento
            return line.sku + "-" + getSKU2Export(line.sku) + line.lote_ref;
        } else if(line.sku==="EFM" || line.sku==="EFMN" ) {
            // Manuscritas
            return getSKU2Export(line.sku) + getSubOptionsDescriptions(line.sku,line.lote_ref, line.selected_sub_options_descriptions);
        } else if (line.selected_sub_options_codes!=='""') {
            if(line.sku==="PI") {
                return getSubOptions(line.sku, line.selected_sub_options_codes);
            }  else {
                //KRAR
                return getSKU2Export(line.sku) + getSubOptions(line.sku, line.selected_sub_options_codes);
            }
        } else if (line.text_labels_objects !== null && line.text_labels_objects !== '[]' ) {
            // autocolantes para caixa de medicamentos
            return getSKU2Export(line.sku) + getTextLabelsOptions(line.sku, line.text_labels_objects, line.font_color_desc, line.despensa_theme);
        } else {
            // Autocolantes das disciplinas e ARP
            return getSKU2Export(line.sku) + getOtherOptions(line.sku,line.other_options_codes,line.lote_id);
        }
        //return line.lote_ref !== "null" && line.lote_ref!==null && line.sku!=="ARP" ? getSKU2Export(line.sku) + "-" + getSKU2Export(line.sku) + line.lote_ref : getSKU2Export(line.sku) + getOtherOptions(line.sku,line.other_options_codes);
    }
    const getOrdersToExport = () => {
        console.log()
        const keysToExport = Object.values(isCheck);
        const ordersToExport_ = orders.filter(order => keysToExport.includes(order.id.toString()));
        // tratar os dados para ficar um order line details por linha
        let data2Export = [];
        ordersToExport_.forEach(order => {
            order.order_lines.forEach(line => {
                data2Export.push({
                    "ID Encomenda": order.id,
                    "ID Cliente":"",
                    "Email Cliente":order.client_email_address,
                    "Primeiro Nome":order.client_name,
                    "Ultimo nome":"",
                    "Prefixo":"",
                    "Nome do Meio":"",
                    "Sufixo":"",
                    "Taxa de IVA":"",
                    "Data do Invoice":order.client_invoice_date,
                    "Tax Ammout":"",
                    "Desconto":"",
                    "Código Cupão":"",
                    "Envio com IVA":"",
                    "Método de Envio": order.deliver_in_store ? "Loja Note" : order.delivery_method === "Levantamento na Loja" ? order.delivery_type : order.delivery_method, // o teste tem de ser pelo campo deliver_in_store
                    "Total Final":order.total,
                    "ID Loja":order.store_id,
                    "Status":order.status,
                    "State":"",
                    "Total Pago":order.total,
                    "Quantidade Encomendada1":line.quantity,
                    "IP Remoto":"",
                    "Total Faturado":order.total,
                    "Prefixo Morada de Faturação":"",
                    "Primeiro Nome Morada de Faturação":"",
                    "Nome do Meio Morada de Faturação":"",
                    "Ultimo Nome Morada de Faturação":"",
                    "Sufixo Morada de Faturação":"",
                    "Morada de Faturação":"",
                    "Cidade da Morada de Faturação":"",
                    "Região Morada de Faturação":"",
                    "País Morada de Faturação":"",
                    "Código Postal Morada de Faturação":"",
                    "Empresa Morada de Faturação":"",
                    "Prefixo Morada de Envio":"",
                    "Primeiro Nome Morada de Envio":order.deliver_in_store ? (order.store_id  + "-" + order.store_name) : order.client_name,
                    "Nome do Meio Morada de Envio":"",
                    "Ultimo Nome Morada de Envio":"",
                    "Sufixo Morada de Envio":"",
                    "Morada de Envio":order.deliver_in_store ? order.store_address : order.client_address,
                    "Cidade da Morada de Envio":order.deliver_in_store ? "": order.client_postal_code_location,
                    "Região Morada de Envio":"",
                    "País Morada de Envio": order.client_dest_country,
                    "Código Postal Morada de Envio":order.deliver_in_store ? order.store_postal_code : order.client_postal_code,
                    "Empresa Morada de Envio":"",
                    "Método de Pagamento":order.payment_method ==="null" ? "Em Loja- POS": order.payment_method ,
                    "SKU do Produto":getProductSKU(line),
                    "Nome do Produto":line.prod_desc,
                    "Quantidade Encomendada":line.quantity,
                    "Preço Original":"",
                    "Telefone":order.client_phone_number,
                    "ID Fatura Moloni":order.client_invoice_number,
                    "Pickup Chronopost":"",
                    "Fatura Moloni":order.client_invoice_ref,
                    "Opções de Produto":getOptProd(line,0),
                    "op1":getOptProd(line,1),
                    "op2":getOptProd(line,2),
                    "op3":getOptProd(line,3),
                    "op4":getOptProd(line,4),
                    "op5":getOptProd(line,5),
                    "op6":getOptProd(line,6),
                    "op7":getOptProd(line,7),
                    "op8":getOptProd(line,8),
                    "op9":getOptProd(line,9),
                    "op10":getOptProd(line,10),
                    "op11":getOptProd(line,11),
                    "op12":getOptProd(line,12),
                    "op13":getOptProd(line,13),
                    "op14":getOptProd(line,14),
                    "op15":getOptProd(line,15),
                    "op16":getOptProd(line,16),
                    "op17":getOptProd(line,17),
                    "op18":getOptProd(line,18),
                    "op19":getOptProd(line,19),
                })
            })
        })
        setOrdersToExport(data2Export);
    };

    const handleClick = (e) => {
        const {id, checked} = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id.toString()));
        }
    };
    const ordersStatusSelectHandler = (event) => {
        let status = event.target.options[event.target.options.selectedIndex].value;
        setselectedStatus(status);
        if (userLogin.userInfo && (userLogin.userInfo.isAdmin || userLogin.userInfo.isStaff)) {
            dispatch(listOrders(status))
        } else {
            navigate("/login");
        }

    }
    useEffect(()=>{
        dispatch(listOrders(selectedStatus));
       //console.log("UseEffect");
        // console.log(status);
    },[status]);
    const requestSort = key => {
        let direction= 'ascending';
        if(sortConfig.key===key && sortConfig.direction==='ascending') {
            direction='descending';
        }
        setSortConfig({key,direction});
    }
    useMemo(() => {
        let sort1,sort2;
        let orderedList = [...orders];
        orderedList.sort((a,b)=> {
            if(sortConfig.key==="total") {
                if (parseFloat(a[sortConfig.key]) < parseFloat(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? -1 : 1 ;
                }
                if (parseFloat(a[sortConfig.key]) > parseFloat(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? 1 : -1 ;
                }
                return 0;
            } else if(sortConfig.key==="id") {
                if (parseInt(a[sortConfig.key]) < parseInt(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? -1 : 1 ;
                }
                if (parseInt(a[sortConfig.key]) > parseInt(b[sortConfig.key])) {
                    return sortConfig.direction === 'ascending' ? 1 : -1 ;
                }
                return 0;
            } else if(sortConfig.key==="store_name" || sortConfig.key==="status" || sortConfig.key==="client_invoice_date" || sortConfig.key==="exporting_date" || sortConfig.key==="created_date"  ) {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1 ;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1 ;
                }
                return 0;
            }
        })
        dispatch(orderListActions.orderListSuccess(orderedList));
    },[sortConfig])

    const orderClickHanlder = (event) => {
        // vai para odetalhe da encomenda
        let order_id=event.target.getAttribute("data-orderid");
        navigate("/order_detail_page", {state: {orderId: order_id, total: 3}});
    }
    const searchButtonHandler = (event) => {
        let searchOption = document.getElementById("searchOption").value;
        setselectedSearch(searchOption);
        if(searchOption==="orderIdSearch") {
            let orderId = document.getElementById("searchText").value;
            if (Number.isNaN(parseInt(orderId))) {
                alert("Tem de inserir um número de encomenda válido!");
                return;
            }
            if (userLogin.userInfo && userLogin.userInfo.isAdmin) {
                dispatch(searchOrder(orderId))
            } else {
                navigate("/login");
            }
        } else {
            let searchString = document.getElementById("searchText").value;
            if (userLogin.userInfo && userLogin.userInfo.isAdmin) {
                dispatch(searchOrderByPattern(searchString, searchOption))
            } else {
                navigate("/login");
            }
        }
    }

    return (
        <div>
                <h1>Listagem de pedidos</h1> <h2>Estado : {statusMap.get(selectedStatus)}</h2>

                {loading ? (
                    <Loader/>
                ) : error ? (
                    <Message variant="danger">{error}</Message>
                ) : (
                    <Table striped borderless hover responsive className="table-sm">
                        <thead>
                        <tr>
                            <td colSpan={2}>
                                 <select id="searchOption" defaultValue={selectedSearch}>
                                    <option  value="orderIdSearch">Nº de Encomenda</option>
                                    <option  value='clientEmailSearch'>Email do Cliente</option>
                                    <option  value='clientNameSearch'>Nome do Cliente</option>
                                    <option  value='clientPhoneSearch'>Telemóvel Cliente</option>
                                    <option  value='storeSearch'>Loja</option>
                                    <option  value='createdDateSearch'>Data da Encomenda (YYYY-MM-DD)</option>
                                </select>
                            </td>

                            <td><input className="input-text" type="text" id="searchText" /></td>
                            <td><Button id="searchButton" onClick={searchButtonHandler}>Pesquisar</Button></td>
                            <td></td>
                            <td>
                                <select onChange={ordersStatusSelectHandler} defaultValue={selectedStatus}>
                                    <option  value="">Filtros:</option>
                                    <option  value='Pending'>Pedidos Pendentes</option>
                                    <option  value='Processing'>Pedidos a Exportar </option>
                                    <option  value='Processing Exported'>Pedidos Exportados</option>
                                    <option  value='Manufactured'>Pedidos Produzidos</option>
                                    <option  value='Closed'>Pedidos Fechados</option>
                                    <option  value='Canceled'>Pedidos Cancelados</option>
                                    <option  value='Shipped'>Pedidos Enviados à Loja</option>
                                    <option  value='Invoiced'>Pedidos Faturados à Loja</option>
                                </select>
                            </td>
                            <td>
                                <Checkbox
                                    type="checkbox"
                                    name="selectAll"
                                    id="selectAll"
                                    handleClick={handleSelectAll}
                                    isChecked={isCheckAll}
                                />
                                &nbsp;Select All ({isCheck.length} Selected)
                            </td>
                        </tr>
                        <tr>
                            <th onClick={()=>requestSort("id")} className="sortable">nº Encomenda {sortConfig.key==="id" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" }</th>
                            <th onClick={()=>requestSort("status")} className="sortable">Estado {sortConfig.key==="status" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" }</th>
                            <th>Cliente</th>
                            <th onClick={()=>requestSort("store_name")} className="sortable">Loja {sortConfig.key==="store_name" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" } </th>
                            <th onClick={()=>requestSort("total")} className="sortable">Total {sortConfig.key==="total" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" } </th>
                            <th onClick={()=>requestSort(selectedStatus ==="Processing Exported" ? "exporting_date" : selectedStatus ==="Pending" ? "created_date": "client_invoice_date")} className="sortable">{selectedStatus ==="Processing Exported" ? "Data de exportação" : selectedStatus ==="Pending" ? "Data da Encomenda" : "Data de faturação"} {sortConfig.key==="client_invoice_date" || sortConfig.key==="exporting_date" ? sortConfig.direction==="ascending" ? <i className="fa-solid fa-sort-up"/>: <i className="fa-solid fa-sort-down"/> : "" }</th>
                             <th>Select</th>
                        </tr>
                        </thead>

                        <tbody>
                        {orders?.map((order) => {
                            return (
                                <tr key={order.id}>
                                    <td><a className="orderLink" data-orderid={order.id} onClick={orderClickHanlder}>{order.id}</a></td>
                                    <td>{statusMap.get(order.status)}</td>
                                    <td><a className="orderLink" data-orderid={order.id} onClick={orderClickHanlder}>{order.client_name}</a></td>
                                    <td>{order.store_name}</td>
                                    <td>{order.total}€</td>
                                    <td>
                                        {selectedStatus ==="Processing" ?
                                            order.client_invoice_date.substring(0,4)==='1900' ? "" : new Date(order.client_invoice_date).toLocaleString('pt-PT')
                                                                        :
                                            selectedStatus ==="Pending" ?
                                                order.created_date.substring(0,4)==='1900' ? "" : new Date(order.created_date).toLocaleString('pt-PT')
                                                                        :
                                                order.exporting_date.substring(0,4)==='1900' ? "" : new Date(order.exporting_date).toLocaleString('pt-PT')
                                        }
                                    </td>
                                    <td><Checkbox
                                        key={order.id}
                                        name={order.id}
                                        id={order.id}
                                        handleClick={handleClick}
                                        isChecked={isCheck?.includes(order.id.toString())}
                                    />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                )}
                {isCheck && isCheck.length ?
                    <div>
                        {(selectedStatus==="Processing" ) ?
                         <a className="button action tocart primary " onClick={sendOrderUpdate}> Alterar para Exportadas</a> :"" } &nbsp;
                        {selectedStatus==="Processing" || selectedStatus==="Shipped" || selectedStatus==="Pending" || selectedStatus==="Processing Exported" ?
                        <CSVLink
                            onClick={ordersExportHandler}
                            data={Object.values(ordersToExport)}
                            separator=";"
                            filename={'orders_export_' + date.toLocaleString('pt-PT').replace(',','_') + '.csv'}
                            className="button action tocart primary "
                        > Exporta para ficheiro</CSVLink> : "" } &nbsp;
                        {selectedStatus==="Processing Exported" ?
                        <a className="button action tocart primary " onClick={orderManufacturedHandler}> Alterar para Produzidos</a>
                        : ""}
                        {selectedStatus==="Manufactured" ?
                            <input className="input-text2" type="text" id="shipRef" />
                        : ""}
                        {selectedStatus==="Manufactured" ?
                            <a className="button action tocart primary " onClick={orderShippedHandler}> Alterar para Enviadas</a>
                        : ""}
                         {selectedStatus==="Shipped" ?
                            <input className="input-text2" type="text" id="invoiceRef" />
                        : ""}
                        {selectedStatus==="Shipped" ?
                            <a className="button action tocart primary " onClick={orderInvoicedHandler}>Faturar</a>
                        : ""}

                    </div> :
                    <Button disabled>Selecione as Encomendas</Button>
                }
        </div>
    );
}

export default OrderListPage;