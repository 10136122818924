const fontSizes = {
  "Tema 1": {
    "EAESPM": {
      "linha1": {
        1: 36,
        13: 30,
        14: 29,
        18: 24,
        21: 22,
        22: 20
      },
      "linha2": {
        1: 28,
        12: 26,
        18: 24,
        19: 22
      },
      "linha3": {
        1: 24,
        12: 24,
        18: 23,
        19: 22
      }
    },
    "EAESPG": {
      "linha1": {
        1: 60,
        14: 50,
        18: 40,
        20: 38,
        21: 35
      },
      "linha2": {
        1: 50,
        14: 50,
        18: 40,
        20: 38,
        21: 35
      },
      "linha3": {
        1: 30,
        12: 30,
        18: 30,
        19: 30
      }
    },
    "EAESPR": {
      "linha1": {
        1: 37,
        12: 27,
        18: 24,
        20: 21,
        21: 21
      },
      "linha2": {
          1: 37,
          12: 34,
          18: 34,
          19: 29
      },
      "linha3": {
          1: 30,
          12: 30,
          18: 30,
          19: 30
      },
    }
  },
  "Tema 2": {
    "EAESPM": {
      "linha1": {
        1: 33,
        12: 29,
        18: 24,
        19: 22
      },
      "linha2": {
        1: 29,
        13: 27,
        18: 24,
        19: 22
      },
      "linha3": {
        1: 19,
        12: 19,
        18: 17,
        19: 17
      }
    },
    "EAESPG": {
      "linha1": {
        1: 48,
        13: 44,
        20: 37,
        21: 31,
      },
      "linha2": {
        1: 48,
        13: 44,
        19: 37,
        20: 31
      },
      "linha3": {
        1: 22,
        12: 22,
        19: 22,
        20: 22
      }
    },
    "EAESPR": {
      "linha1": {
        1: 40,
        12: 28,
        18: 24,
        19: 21,
        20: 20
      },
      "linha2": {
          1: 40,
          12: 27,
          18: 24,
          19: 21,
          20: 20
      },
      "linha3": {
          1: 18,
          12: 18,
          18: 18,
          19: 18
      },
    }
  },
  "Tema 3": {
    "EAESPM": {
      "linha1": {
        1: 40,
        8: 38,
        17: 31,
        18: 27
      },
      "linha2": {
        1: 32,
        12: 32,
        17: 31,
        18: 27
      },
      "linha3": {
        1: 25,
        12: 25,
        17: 25,
        18: 25
      }
    },
    "EAESPG": {
      "linha1": {
        1: 55,
        12: 50,
        18: 48,
        19: 42,
      },
      "linha2": {
        1: 55,
        12: 50,
        18: 48,
        19: 42
      },
      "linha3": {
        1: 28,
        12: 28,
        18: 28,
        19: 28
      }
    },
    "EAESPR": {
      "linha1": {
        1: 44,
        12: 32,
        18: 30,
        20: 24,
        21: 24
      },
      "linha2": {
          1: 36,
          12: 29,
          18: 26,
          19: 24
      },
      "linha3": {
          1: 21,
          12: 21,
          18: 21,
          19: 21
      },
    }
  },
  "Tema 4": {
    "EAESPM": {
      "linha1": {
        1: 29,
        12: 25,
        18: 22,
        22: 21,
        23: 19
      },
      "linha2": {
        1: 29,
        12: 25,
        18: 23,
        22: 21,
        23: 20
      },
      "linha3": {
        1: 18,
        12: 18,
        18: 18,
        19: 18
      }
    },
    "EAESPG": {
      "linha1": {
        1: 50,
        12: 46,
        18: 37,
        19: 30,
      },
      "linha2": {
        1: 50,
        12: 46,
        18: 37,
        19: 30
      },
      "linha3": {
        1: 20,
        12: 20,
        18: 20,
        19: 20
      }
    },
    "EAESPR": {
      "linha1": {
        1: 36,
        12: 22,
        18: 22,
        20: 20,
        21: 20
      },
      "linha2": {
          1: 29,
          12: 25,
          18: 23,
          19: 21
      },
      "linha3": {
          1: 18,
          12: 18,
          18: 18,
          19: 18
      },
    }
  },
  "Tema 5": {
    "EAESPM": {
      "linha1": {
        1: 31,
        14: 27,
        18: 22,
        23: 21,
        24: 20
      },
      "linha2": {
        1: 31,
        14: 27,
        18: 23,
        19: 19
      },
      "linha3": {
        1: 20,
        12: 20,
        18: 20,
        19: 20
      }
    },
    "EAESPG": {
      "linha1": {
        1: 50,
        14: 42,
        18: 38,
        20: 34,
        22: 30,
        23: 26
      },
      "linha2": {
        1: 50,
        14: 42,
        18: 38,
        20: 34,
        22: 30,
        23: 26
      },
      "linha3": {
        1: 24,
        12: 24,
        18: 24,
        19: 24
      }
    },
    "EAESPR": {
      "linha1": {
        1: 41,
        12: 31,
        18: 26,
        20: 21,
        21: 21
      },
      "linha2": {
          1: 41,
          12: 29,
          18: 26,
          19: 21
      },
      "linha3": {
          1: 20,
          12: 20,
          18: 20,
          19: 20
      },
    }
  },
  "Tema 6": {
    "EAESPM": {
      "linha1": {
        1: 30,
        12: 23,
        15: 18,
        18: 16,
        23: 16,
        24: 16
      },
      "linha2": {
        1: 30,
        12: 23,
        18: 19,
        19: 19
      },
      "linha3": {
        1: 19,
        12: 19,
        18: 19,
        19: 19
      }
    },
    "EAESPG": {
      "linha1": {
        1: 40,
        12: 34,
        15: 30,
        18: 27,
        20: 26,
        22: 24,
        23: 21
      },
      "linha2": {
        1: 40,
        12: 34,
        15: 30,
        18: 27,
        20: 26,
        22: 24,
        23: 21
      },
      "linha3": {
        1: 23,
        12: 23,
        18: 21,
        19: 20
      }
    },
    "EAESPR": {
      "linha1": {
        1: 34,
        12: 24,
        18: 21,
        20: 19,
        21: 19
      },
      "linha2": {
          1: 34,
          12: 24,
          18: 21,
          19: 19
      },
      "linha3": {
          1: 18,
          12: 18,
          18: 18,
          19: 18
      },
    }
  },
  "Tema 7": {
    "EAESPM": {
      "linha1": {
        1: 30,
        12: 21,
        18: 16,
        20: 15,
        23: 15,
        24: 15
      },
      "linha2": {
        1: 30,
        12: 20,
        18: 18,
        19: 18
      },
      "linha3": {
        1: 19,
        12: 19,
        18: 19,
        19: 19
      }
    },
    "EAESPG": {
      "linha1": {
        1: 50,
        12: 40,
        14: 35,
        19: 30,
        22: 28,
        23: 26
      },
      "linha2": {
        1: 50,
        12: 40,
        14: 35,
        19: 30,
        22: 28,
        23: 26
      },
      "linha3": {
        1: 24,
        12: 24,
        18: 20,
        19: 20
      }
    },
    "EAESPR": {
      "linha1": {
        1: 34,
        12: 24,
        18: 22,
        20: 20,
        21: 20
      },
      "linha2": {
          1: 34,
          12: 24,
          18: 22,
          19: 20
      },
      "linha3": {
          1: 18,
          12: 18,
          18: 18,
          19: 18
      },
    }
  },
  // ...
}

export default function DespensaFontSizesCalculator(tema, linha, etiqueta, nChar) {
  let fontSize = fontSizes[tema][etiqueta][linha];
  for (let i = nChar; i >= 1; i--) {
    if (fontSize[i]) {
      console.log(tema + "-" +linha+ "-" +etiqueta + "-" + nChar + "=" + fontSize[i] + "-" + i);
      return fontSize[i];
    }
  }
  return fontSize[1];
}
