import {
    Row,
    Col,
    ListGroup,
    Image,
    Form,
    Button,
    Card, Accordion,
} from "react-bootstrap";
import "./CheckOutPage.css";
import React, {Fragment} from "react";
import ClientData from "./ClientDataForm";
import OrderDetail from "./OrderDetail";
import {useSelector} from "react-redux";

function CheckOutPage(props) {
const userLogin = useSelector((state) => state.userLogin);
    return(
        <Fragment>
            <Row>
            <Col md={12}>
                <h1>O teu pedido:</h1>
            </Col>
            </Row>
            {!userLogin.userInfo.vertical_layout &&
                <Row>
                    <Col sm={7}>
                        <ClientData/>
                    </Col>
                    <Col sm={5}>
                        <OrderDetail/>
                    </Col>
                </Row>
            }
            {userLogin.userInfo.vertical_layout &&
                <div>
                        <Row>
                            <Col sm={12}>
                                <ClientData/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <OrderDetail/>
                            </Col>
                        </Row>
                </div>
            }
        </Fragment>


    );
}

export default CheckOutPage;