export const labelType = {
    AR: "REG",
    EF: "REG",
    AM: "MINI",
    ALC: "LC",
    ALCM: "LCM",
    IM: "IM",
    EC: "EC",
    AG: "AG",
    TGC: "AG",
    ADISC: "ADISC",
    ARP: "ARP",
    TM: "MINI",
    EFG: "EFG",
    KRCR: "KRCR",
    KRAE: "KRAE",
    KRAC: "KRAC",
    KRAP: "KRAP",
    KRAB: "KRAB",
    KES1: "KES1",
    KES2: "KES2",
    KES3: "KES3",
    ECI: "EC",
    ARI: "REG",
    TRI: "REG",
    KRAR: "KRAR",
    TMR: "RED",
    AMR: "RED",
    EPR: "RED",
    ETR: "RED",
    CR: "RED",
    ECPE: "ECPE",
    DCCAA: "DCCAA",
    DCCAT: "DCCAT",
    BM: "BM",
    EABOL: "EABOL",
    EFMN: "EFMN",
    EFM: "EFM",
    EACXMED: "EACXMED",
    EAESPM: "EAESPM",
    EAESPR: "EAESPR",
    EAESPG: "EAESPG",
    EPED: "EPED",
    PI: "PI",
    MP: "MP",
    FPP: "FPP",
    TAB: "TAB",
    TIQ: "TAB",
    KIT1: "KIT1",
    KIT2: "KIT2",
    KIT5: "KIT5",
    KRD: "KRD",
    KCFB: "KCFB",
    KCFC: "KCFC",
    KPB: "KPB",
    KPC: "KPC",
    EBICFC: "KEBICFC",
    KQRS: "KQRS",
    BIKE1: "BIKE1",
    BIKE2: "BIKE2",
    ANTG: "ANTG",

};
export const lineSizes = {
        TAB: {
            fontSizeL1_1: "26pt",
            fontSizeL1_2: "26pt",
            fontSizeL2_2: "26pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        REG: {
            fontSizeL1_1: "16pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        EPED: {
            fontSizeL1_1: "16pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        MINI: {
            fontSizeL1_1: "16pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        RED: {
            fontSizeL1_1: "16pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        FPP: {
            fontSizeL1_1: "16pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        LC: {
            fontSizeL1_1: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        LCM: {
            fontSizeL1_1: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        IM: {
            fontSizeL1_1: "20pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        EC: {
            fontSizeL1_1: "20pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        AG: {
            fontSizeL1_1: "20pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            fontSizeL1_3: "16pt",
            fontSizeL2_3: "16pt",
            fontSizeL3_3: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        EFG: {
            fontSizeL1_1: "20pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            fontSizeL1_3: "16pt",
            fontSizeL2_3: "16pt",
            fontSizeL3_3: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        MINI20: {
            fontSizeL1_1: "16pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        ADISC: {
            fontSizeL1_1: "20pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            fontSizeL1_3: "16pt",
            fontSizeL2_3: "16pt",
            fontSizeL3_3: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
        ECPE: {
                fontSizeL1_1: "20pt",
                fontSizeL1_2: "16pt",
                fontSizeL2_2: "16pt",
                topAdjust: "0%",
                bottomAdjust: "0%",
        },
        BIKE1: {
                fontSizeL1_1: "20pt",
                fontSizeL1_2: "16pt",
                fontSizeL2_2: "16pt",
                topAdjust: "0%",
                bottomAdjust: "0%",
        },
        BIKE2: {
                fontSizeL1_1: "20pt",
                fontSizeL1_2: "16pt",
                fontSizeL2_2: "16pt",
                topAdjust: "0%",
                bottomAdjust: "0%",
        },
        ANTG: {
            fontSizeL1_1: "20pt",
            fontSizeL1_2: "16pt",
            fontSizeL2_2: "16pt",
            fontSizeL1_3: "16pt",
            fontSizeL2_3: "16pt",
            fontSizeL3_3: "16pt",
            topAdjust: "0%",
            bottomAdjust: "0%",
        },
    };