import React, {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {Form, Button, Row, Col, Container, CarouselItem, Image} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import {login} from '../actions/userActions';
import "./SplashPage.css";
import Carousel from "react-bootstrap/Carousel";
import backdrop from "bootstrap/js/src/util/backdrop";
import MessageModal from "../components/UI/MessageModal";
import classes from "../components/UI/MessageModal.module.css";
import Card from "../components/UI/Card";

export default function SplashPage () {
    const navigate = useNavigate();
    return (
        <div>
        <div onClick={()=>{navigate("/")}} className="backdrop">
            <Card className={classes.modal}>
             <header className={classes.header}>
             </header>
             <div className={classes.content}>
           <h4><center>Toque para iniciar o pedido</center></h4>
        </div>
        <footer className={classes.actions}>
        </footer>
      </Card>
        </div>
            <Carousel variant="dark" slide={true} indicators={false} controls={false} interval={2000}>
                    <CarouselItem className="carousel-item active">
                        <img src="/media/categoria_aulas.png" className="d-block w-100" alt="..."/>
                    </CarouselItem>
                    <CarouselItem className="carousel-item">
                        <img src="/media/principais-categorias-pt-roupa.jpg" className="d-block w-100" alt="..."/>
                    </CarouselItem>
                    <CarouselItem className="carousel-item">
                        <img src="/media/principais-categorias-pt-objetos.jpg" className="d-block w-100" alt="..."/>
                    </CarouselItem>
                    <CarouselItem className="carousel-item">
                         <img src="/media/principais-categorias-pt-idosos.jpg" className="d-block w-100" alt="..."/>
                    </CarouselItem>
                    <CarouselItem className="carousel-item">
                         <img src="/media/principais-categorias-pt-despensa.jpg" className="d-block w-100" alt="..."/>
                    </CarouselItem>
                    <CarouselItem className="carousel-item">
                         <img src="/media/principais-categorias-pt-cal_ado.jpg" className="d-block w-100" alt="..."/>
                    </CarouselItem>
            </Carousel>
            </div>

    )
}