import React, {Fragment, useEffect, useState} from "react";
import {Row, Col} from "react-bootstrap";
import Tiketa from "../components/Tiketa";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Category from "../components/Category";
import {isLandscape} from "../utils/tiketaUtils";

function HomePage() {
    const {category} = useParams();
    const dispatch = useDispatch();

    const [tiketas, setTiketas] = useState([]);
    const {userInfo} = useSelector((state) => state.userLogin);
    const history = useNavigate();
    const [subCategories, setSubCategories] = useState([]);

    useEffect(() => {
            if (userInfo) {
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
                async function fetchTiketas() {
                    const {data} = await axios.get(
                        `/api/get_category_products_4_store/?category_code=${category}&store_id=${userInfo.store}`, config
                    );
                    setTiketas(data);
                }
                 async function fetchCategories() {
                     const {data} = await axios.get(
                         `/api/get_all_lv2_categories_of_lv1_4_store/?category_code=${category}&store_id=${userInfo.store}`, config
                     ).catch((error) => {
                         console.log(error.response.status);
                         history("/login/");
                     });
                     setSubCategories(data);
                 }
                 fetchTiketas();
                 fetchCategories();
            } else {
                // TODO: custom error message here
                history("/login/");
            }

    }, [category]);
    return (


        subCategories.length === 0 ? // se não tiver subcategorias lista os produtos, senão lista as subcategorias
        (
            <Fragment>
                <Link to={"/"}>
                            Voltar
                </Link>
                <Row className="row-cols-1 row-cols-md-2 g-4 ">
                    {tiketas.map((filteredTiketa) => (
                        <Col key={filteredTiketa.id} sm={12} md={6} lg={4} xl={isLandscape(userInfo.vertical_layout)? "3" :"4"}>
                            <Tiketa tiketa={filteredTiketa}/>
                        </Col>
                    ))}
                </Row>
            </Fragment>) :
        (
            <Fragment>
                <Link to={"/"}>
                            Voltar
                </Link>
                <Row>
                    {subCategories.map((category) => (
                        <Col sm={12} md={6} lg={4} xl={isLandscape(userInfo.vertical_layout)? "3" :"4"} key={category.category_code}>
                            <Category category={category}/>
                        </Col>
                    ))}
                </Row>
            </Fragment>
        )
    );
}

export default HomePage;
