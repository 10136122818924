import React, {useCallback, useRef} from "react";
import {useSelector} from "react-redux";
import "./css/FeetLabels.css";
import {bgColorObj,txtColorObj} from "./constants/LabelsColorConstants"
import {lineSizes} from "./constants/LabelsConstants";


export default function FeetLabels(props) {

    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, iconSrc, icon,isKitMember;
    linha1=props.linha1;
    linha2=props.linha2;
    linha3=props.linha3;
    isKitMember=props.isKitMember;
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";

    return(
        <div id="label-tiketa-ftlb" className="labelSimulationCard" style={{
                             zoom: isKitMember===1 ? "70%":"100%",

                         }}>
                <div
                    className="label-ftlb"
                    style={{zIndex:1,
                        backgroundImage: "url(/media/pe_preto.png)",
                        backgroundRepeat: "no-repeat"}}
                >

                    <div
                        className="label-ftlb-brand"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div
                        className="label-l1-sicon label-ftlb-l1-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["ECPE"]["fontSizeL1_1"],
                        }}
                    >
                        {linha1}
                    </div>
                    <div
                        className="label-l2-sicon label-ftlb-l2-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["ECPE"]["fontSizeL1_2"],
                        }}
                    >
                        {linha2}
                    </div>
                    <div
                        className="label-l3-sicon label-ftlb-l3-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "" : "none",
                            fontSize: lineSizes["ECPE"]["fontSizeL2_2"],
                        }}
                    >
                        {linha3}
                    </div>

                </div>

        </div>
    );

}
