import axios from "axios";
import {orderListActions} from "../store/orderListSlice";


export const listOrders = (status) => async (dispatch, getState) => {
    try {
        dispatch(orderListActions.orderListRequest([]))
        const {
            userLogin: {userInfo},
        } = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        console.log(status);
        const {data} = await axios.get(`/api/orders/list/?status=${status}`,
            config
        )
        const dataToProcess = data;
        //console.log("estou aqui");
        //console.log(dataToProcess);//.filter(x => x.processed === 'false')
        dispatch(orderListActions.orderListSuccess(dataToProcess))
    } catch (error) {
        dispatch(orderListActions.orderListFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}
export const listOrdersByStore = (status,store) => async (dispatch, getState) => {
    try {
        dispatch(orderListActions.orderListRequest([]))
        const {
            userLogin: {userInfo},
        } = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const {data} = await axios.get(`/api/orders/list_by_store/?status=${status}&store=${store}`,
            config
        )
        const dataToProcess = data;//.filter(x => x.processed === 'false')
        dispatch(orderListActions.orderListSuccess(dataToProcess))
    } catch (error) {
        dispatch(orderListActions.orderListFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}
export const searchOrder = (orderId) => async (dispatch, getState) => {
    try {
        dispatch(orderListActions.orderListRequest([]))
        const {
            userLogin: {userInfo},
        } = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.get(`/api/get_order/?id=${orderId}`,
            config
        )
        const dataToProcess = data;//.filter(x => x.processed === 'false')
        dispatch(orderListActions.orderListSuccess(dataToProcess))
    } catch (error) {
        dispatch(orderListActions.orderListFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}
export const searchOrderByStore = (orderId,store) => async (dispatch, getState) => {
    try {
        dispatch(orderListActions.orderListRequest([]))
        const {
            userLogin: {userInfo},
        } = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.get(`/api/get_order_by_store/?id=${orderId}&store=${store}`,
            config
        )
        const dataToProcess = data;//.filter(x => x.processed === 'false')
        dispatch(orderListActions.orderListSuccess(dataToProcess))
    } catch (error) {
        dispatch(orderListActions.orderListFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}
export const searchOrderByPattern = (searchPattern,criteria) => async (dispatch, getState) => {
    try {
        dispatch(orderListActions.orderListRequest([]))
        const {
            userLogin: {userInfo},
        } = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.get(`/api/get_order_by_search_pattern/?searchPattern=${searchPattern}&criteria=${criteria}`,
            config
        )
        const dataToProcess = data;//.filter(x => x.processed === 'false')
        dispatch(orderListActions.orderListSuccess(dataToProcess))
    } catch (error) {
        dispatch(orderListActions.orderListFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}
export const searchOrderByPatternByStore = (searchPattern,criteria,store) => async (dispatch, getState) => {
    try {
        dispatch(orderListActions.orderListRequest([]))
        const {
            userLogin: {userInfo},
        } = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.get(`/api/get_order_by_search_pattern_by_store/?searchPattern=${searchPattern}&criteria=${criteria}&store=${store}`,
            config
        )
        const dataToProcess = data;//.filter(x => x.processed === 'false')
        dispatch(orderListActions.orderListSuccess(dataToProcess))
    } catch (error) {
        dispatch(orderListActions.orderListFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}

