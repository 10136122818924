import React, {Fragment, useEffect, useState,useRef} from "react";
import {resetLabelConfig} from "../../actions/labelConfigActions";
import {useLocation} from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import axios from "axios";
import {Button, Col, Image, ListGroup, Row} from "react-bootstrap";
import "./OrderDetailPage.css";
import {useNavigate} from "react-router-dom";
import OrderInformation from "./OrderInformation";
const statusMap = new Map();

statusMap.set("Pending", "Pendente de Pagamento");
statusMap.set("Processing", "Em Processamento");
statusMap.set("Processing Exported", "Em Produção");
statusMap.set("Manufactured", "Produzido");
statusMap.set("Shipped", "Enviado");
statusMap.set("Invoiced", "Faturado");
statusMap.set("Canceled", "Cancelado");
statusMap.set("Closed", "Fechado");


export default function OrderDetailPage(props) {
    const { state } = useLocation();
    const {orderId,total} = state;
    const [order, setOrder] = useState([]);
    const [orderStatusHistory, setOrderStatusHistory] = useState([]);
    const userLogin = useSelector((state) => state.userLogin);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data,setData]=useState([]);
    const [invoiceReceiptURL,setInvoiceReceiptURL] = useState("") // comentar

    const multibancoRef=useRef(null);
    const numerarioRef = useRef(null);
     function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const config = {
         headers: {
             'Content-type': 'application/json',
             'Authorization': `Bearer ${userLogin.userInfo.token}`,
             'X-CSRFToken': getCookie('csrftoken')
         }
    }
    useEffect(()=>{
        async function fetchOrder() {
                const {data} = undefined || await axios.get(`/api/get_order/?id=${orderId}`, config);
                setOrder(data);
         }
         fetchOrder();
         async function fetchStatusHistory() {
                const {data} = undefined || await axios.get(`/api/order/get_order_status_history/?id=${orderId}`, config);
                setOrderStatusHistory(data);
         }
         fetchStatusHistory();
    },[orderId,data]);
    const printOrderHandler = (event) => {
        event.preventDefault();
        window.print();
    }
    const backButtonClickHandler = () => {
        dispatch(resetLabelConfig());
        if(userLogin.userInfo.isAdmin || userLogin.userInfo.isStaff)
            navigate("/admin/order_list/Processing");
        else
            navigate("/admin/store_order_list");
    }

    const invoiceButtonClickHandler = (event) => {

        let data2update = {
            user_id: userLogin.userInfo.id,
            id: orderId,
            paymentMethodName: event.target.value,
        }
        numerarioRef.current.disabled = true;
        multibancoRef.current.disabled = true;
        //event.currentTarget.innerText="A gerar a Fatura";
        async function invoiceOrder() {

            const {data} = await axios.put('/api/order/invoice_order/',data2update, config).catch((error)=>{
                if (error.response) {
                    //alert(error.response.data[0].code + " " + error.response.data[0].description )
                    alert("Ocoreu um erro na criação da fatura,verifique os logs.")
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    numerarioRef.current.disabled = false;
                    multibancoRef.current.disabled = false;
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
        }
        invoiceOrder();

    }
    const cancelButtonClickHandler = () => {
        let acceptAction = window.confirm("Tem certeza que deseja cancelar este pedido?");
        if(acceptAction) {
            let data2update = {
                user_id: userLogin.userInfo.id,
                id: orderId,
            }

            async function cancelOrder() {
                const {data} = await axios.put('/api/order/cancel_order/', data2update, config).catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        return {data: {id: "Erro - " + error.response.status}};
                    } else if (error.request) {
                        console.log(error.request);
                        return {data: {id: "Erro - " + error.request}};
                    } else {
                        console.log('Error', error.message);
                        return {data: {id: "Erro - " + error.message}};
                    }
                    //return {data: {id:"Erro - " + error.config}};
                })
                setData(data);
            }

            cancelOrder();
        }
    }
    const goProcessingButtonClickHandler = () => {
        let acceptAction = window.confirm('Tem certeza que deseja avançar este pedido para "Em processamento?"');
        if(acceptAction) {
            let data2update = {
                user_id: userLogin.userInfo.id,
                id: orderId,
                newStatus: "Processing",
            }

            async function updateOrderState() {
                const {data} = await axios.put('/api/order/update_order_state/', data2update, config).catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        return {data: {id: "Erro - " + error.response.status}};
                    } else if (error.request) {
                        console.log(error.request);
                        return {data: {id: "Erro - " + error.request}};
                    } else {
                        console.log('Error', error.message);
                        return {data: {id: "Erro - " + error.message}};
                    }
                    //return {data: {id:"Erro - " + error.config}};
                })
                setData(data);
            }
            updateOrderState();
        }
    }
    const clientHandDeliveryButtonClickHandler = () => {
        let data2update = {
            user_id:userLogin.userInfo.id,
            id: orderId,
        }
        async function closeOrder() {
            const {data} = await axios.put('/api/order/close_order/',data2update, config).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 //return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
        }
        closeOrder();
    }
    const deliveryButtonClickHandler = () => {
        let mailRef=""
        if(document.getElementById("mailRef")) { // DPD náo te mailref
            mailRef = document.getElementById("mailRef").value;
            if (mailRef.trim() === "") {
                alert("Tem de preencher o código do registo!");
                return;
            }
        } else {
            mailRef="DPD";
        }
        let data2update = {
            user_id:userLogin.userInfo.id,
            id: orderId,
            mailRef: mailRef,
        }
        async function closeOrderDeliverByMail() {
            const {data} = await axios.put('/api/order/close_order_delivery_by_mail/',data2update, config).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 //return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
        }
        closeOrderDeliverByMail();
    }
    const updateClientDataHandler = (clientData2Update) => {
        //fazer update dos dados do cliente na order
        async function updateClientData() {
            const {data} = await axios.put('/api/order/update_order_client_data/',clientData2Update, config).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    //alert("Ocorreu um erro ao atualizar os dados do cliente!")
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 //return {data: {id:"Erro - " + error.config}};
            })
            setData(data);
        }
        updateClientData();
    }
    const isDeliveryInHandsButtonVisible = (order) => {
        //((order[0]?.status === "Shipped" || order[0]?.status === "Invoiced" || (userLogin.userInfo && userLogin.userInfo.isAdmin)) && order[0]?.status !== "Canceled" )
        //if((order.status === "Shipped" || order.status === "Invoiced" || (userLogin.userInfo && userLogin.userInfo.isAdmin)) && order.status !== "Canceled" && order.status !== "Closed" && order.delivery_type==="Levantamento em mãos" ) {
        if(((order.status ==="Manufactured" || userLogin.userInfo && userLogin.userInfo.isAdmin) && order.delivery_type==="Levantamento em mãos" ) ) {
            return true;
        } else {
            return false;
        }
    }
    const isDeliveryButtonVisible = (order) => {
        //((order[0]?.status === "Shipped" || order[0]?.status === "Invoiced" || (userLogin.userInfo && userLogin.userInfo.isAdmin)) && order[0]?.status !== "Canceled" )
        //if((order.status === "Shipped" || order.status === "Invoiced" || (userLogin.userInfo && userLogin.userInfo.isAdmin)) && order.status !== "Canceled" && order.status !== "Closed" && order.delivery_type!=="Levantamento em mãos" ) {
        if(((order.status ==="Manufactured" || userLogin.userInfo && userLogin.userInfo.isAdmin)  && order.delivery_type!=="Levantamento em mãos" ) ) {
            return true;
        } else {
            return false;
        }
    }
    return(
        <Fragment>
            <form onSubmit={printOrderHandler}>
                <ListGroup variant="flush" className=".d-print-inline-flex">
                    <Row>
                        <Col sm={4} className="headerRow">
                            <span>Pedido nº{orderId}</span>
                        </Col>

                        <Col sm={6} className="headerRow">
                             <span>Estado:&nbsp;{statusMap.get(order[0]?.status)}</span>
                        </Col>
                        <Col sm={2} className="headerRow">
                             <Button type="button" onClick={backButtonClickHandler} className="printButton">
                                 <i aria-hidden="true">&nbsp;</i>Voltar
                             </Button>
                        </Col>
                    </Row>
                    <Row>
                         <Col sm={2} className="headerRow">
                            {order[0]?.status === "Pending" &&
                                <Button type="button" id="NUM" value="Numerário" onClick={invoiceButtonClickHandler} className="printButton" ref={numerarioRef}>
                                    <i aria-hidden="true">&nbsp;</i>Numerário
                                </Button>
                            }
                        </Col>

                        <Col sm={2} className="headerRow">
                            {order[0]?.status === "Pending" &&
                                <Button type="button" id="MB" value="Multibanco" onClick={invoiceButtonClickHandler} className="printButton" ref={multibancoRef}>
                                    <i aria-hidden="true">&nbsp;</i>Multibanco
                                </Button>
                            }
                        </Col>
                        <Col sm={2} className="headerRow">
                            {order[0]?.status === "Pending" && userLogin.userInfo && userLogin.userInfo.isAdmin &&
                                <Button type="button" onClick={goProcessingButtonClickHandler} className="printButton">
                                    <i aria-hidden="true">&nbsp;</i>>> Processing
                                </Button>
                            }
                        </Col>
                        <Col sm={2} className="headerRow">
                            {((order[0]?.status === "Pending" ||  (userLogin.userInfo && userLogin.userInfo.isAdmin)) && order[0]?.status !== "Canceled" )  &&
                                <Button type="button" onClick={cancelButtonClickHandler} className="printButton">
                                    <i aria-hidden="true">&nbsp;</i>Cancelar Encomenda
                                </Button>
                            }
                        </Col>
                        <Col sm={2} className="headerRow">

                            {order[0] && isDeliveryButtonVisible(order[0]) && order[0].delivery_method==="CTT-Correio Registado"  &&
                                <input className="input-text" placeholder="Referencia do Envio" id="mailRef" />
                            }
                        </Col>
                        <Col sm={2} className="headerRow">
                            {order[0] && isDeliveryInHandsButtonVisible(order[0])  &&
                                <Button type="button" onClick={clientHandDeliveryButtonClickHandler} className="printButton">
                                    <i aria-hidden="true">&nbsp;</i>Entregar a Encomenda
                                </Button>
                            }
                            {order[0] && isDeliveryButtonVisible(order[0])  &&
                                <Button type="button" onClick={deliveryButtonClickHandler} className="printButton">
                                    <i aria-hidden="true">&nbsp;</i>Enviar a Encomenda
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row>
                            {order[0] &&
                            <OrderInformation order={order[0]} onUpdateClientData={updateClientDataHandler}/>
                            }

                        <Col sm={6}>
                            <Row className="tableHeader">
                                <Col sm={2}>User</Col>
                                <Col sm={3}>Estado Ant.</Col>
                                <Col sm={3}>Estado</Col>
                                <Col sm={3}>Data</Col>
                            </Row>
                            {orderStatusHistory.length!==0 && orderStatusHistory.map((statusItem,index) => (
                            <Row className="tableRow" key={index}>
                                <Col sm={2}>{statusItem.user_name}</Col>
                                <Col sm={3}>{statusMap.get(statusItem.old_status)}</Col>
                                <Col sm={3}>{statusMap.get(statusItem.new_status)}</Col>
                                <Col sm={3}>{new Date(statusItem.created_date).toLocaleString('pt-PT')}</Col>
                            </Row>
                                ))}
                            <Row className="tableHeader"></Row>
                        </Col>
                    </Row>
                    <Row><Col sm={12}>&nbsp;</Col></Row>
                    <Row className="tableHeader">
                        <Col sm={6}>Item</Col>
                        <Col sm={2}>Preço</Col>
                        <Col sm={2}>Quant.</Col>
                        <Col sm={2}>SubTotal</Col>
                    </Row>
                    {order.length!==0 && order[0].order_lines.map((item,index) => (
                        <ListGroup.Item key={index + "-" + item.SKU}>
                            <Row>
                                <Col sm={6} >
                                    <Row>
                                        <Col sm={4}>
                                           {/* <img src="media/simulador/images/image_22_1.png" />*/}
                                            <Image src={item.image_blob} alt={item.prod_desc} fluid rounded className="productImageL"/>
                                        </Col>

                                    <Col sm={8}>
                                  <Row>
                                        <Col>
                                            <span className="productTitleCheckout printText">{item.prod_desc}</span>
                                        </Col>
                                    </Row>
                                    {(item.lote_desc !== "" && item.lote_desc !== "null") &&
                                        <Row>
                                            <Col sm={12}>
                                                <span className="productDescriptionHeader printText">
                                                    Lote:
                                                </span>
                                               <span className="productCheckoutDescription printText">
                                                    {item.lote_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.label_simulation_type === "themes" &&
                                        <Fragment>
                                            <Row>
                                                <Col sm={12} >
                                                     <span className="productDescriptionHeader printText">
                                                        Tema:
                                                     </span>
                                                   <span className="productCheckoutDescription printText">
                                                       {item.theme_desc}
                                                   </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <span className="productDescriptionHeader printText">
                                                        Modelo:
                                                    </span>
                                                    <span className="productCheckoutDescription printText">
                                                        {item.theme_model === "both" ? "Ambos os modelos" : item.theme_model === "right" ? "Modelo da Direita" : "Modelo da Esquerda"  }
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                    {item.label_simulation_type === "icons" && item.sku !== "EACXMED" && item.sku !== "EPED" &&
                                        (<Fragment>
                                        <Row>
                                            <Col sm={12} >
                                                <span className="productDescriptionHeader printText">
                                                    Icon:
                                                </span>
                                                <span className="productCheckoutDescription printText">
                                                    {item.icon_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                 <span className="productDescriptionHeader printText">
                                                    Cor da Letra:
                                                 </span>
                                                <span className="productCheckoutDescription printText">
                                                     {item.font_color_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                            <Row>
                                            <Col sm={12}>
                                                <span className="productDescriptionHeader printText">
                                                    Cor do Fundo:
                                                </span>
                                                <span className="productCheckoutDescription printText">
                                                    {item.bk_color_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                        </Fragment>)
                                    }
                                        {item.font !== "" &&
                                            <Row>
                                                <Col sm={12}>
                                                <span className="productDescriptionHeader printText">
                                                    Tipo de Letra:
                                                </span>
                                                    <span className="productCheckoutDescription printText">
                                                    {item.font}
                                                </span>
                                                </Col>
                                            </Row>
                                        }
                                    {item.linha1 !== "" && item.linha1 !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Linha 1: </span>
                                               <span className="productCheckoutDescription printText">{item.linha1}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.linha2 !== "" && item.linha2 !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Linha 2: </span>
                                                <span className="productCheckoutDescription printText">{item.linha2}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.linha3 !== "" && item.linha3 !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Linha 3: </span>
                                                <span className="productCheckoutDescription printText">{item.linha3}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.sku === "ADISC" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Disciplinas: </span>
                                                <span className="productCheckoutDescription printText">{JSON.parse(item.other_options_objects).map((el)=>el.subject_name).join(" , ")}</span>
                                            </Col>
                                        </Row>
                                    }
                                     {item.sku === "ARP" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Idioma: </span>
                                                <span className="productCheckoutDescription printText">{JSON.parse(item.other_options_objects).description}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.linhaLC !== "" && item.linhaLC !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Lapis e Canetas: </span>
                                                <span className="productCheckoutDescription printText">{item.linhaLC}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.linhaLCM !== "" && item.linhaLCM !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Mini Lapis e Canetas: </span>
                                                <span className="productCheckoutDescription printText">{item.linhaLCM}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.selected_sub_options_descriptions !== "" &&
                                                Object.keys(JSON.parse(item.options_descriptions)).map((el)=> {
                                                    return (
                                                        <Row>
                                                            <Col sm={12} >
                                                                <span className="productDescriptionHeader printText"> {JSON.parse(item.options_descriptions)[el]} </span>
                                                                <span className="productCheckoutDescription printText"> :  {JSON.parse(item.selected_sub_options_descriptions)[el]} </span>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                    }
                                    {item.text_labels_objects !== "" && item.text_labels_objects !== "[]" && item.sku.substring(0,5) !=="EAESP"    &&
                                        <Fragment>
                                            <Row>
                                                <Col sm={12}>
                                                     <span className="productDescriptionHeader printText">
                                                        Cor da Letra:
                                                     </span>
                                                    <span className="productCheckoutDescription printText">
                                                        {item.font_color_desc}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} >
                                                    <span className="productDescriptionHeader printText">Opções: </span>
                                                    <span className="productCheckoutDescription printText">{JSON.parse(item.text_labels_objects).map((el)=>el.label_desc).join(" , ")}</span>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                    {item.text_labels_objects !== "" && item.sku ==="EPED"    &&
                                            <Row>
                                                <Col sm={12}>
                                                     <span className="productDescriptionHeader printText">
                                                        Tema:
                                                     </span>
                                                    <span className="productCheckoutDescription printText">
                                                        {item.theme_desc}
                                                    </span>
                                                </Col>
                                            </Row>
                                    }
                                    {item.text_labels_objects !== "" && item.text_labels_objects !== "[]" && item.sku.substring(0,5) ==="EAESP"    &&
                                        <Fragment>
                                            <Row>
                                                <Col sm={12}>
                                                     <span className="productDescriptionHeader printText">
                                                        Tema:
                                                     </span>
                                                    <span className="productCheckoutDescription printText">
                                                        {item.despensa_theme}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} >
                                                    <span className="productDescriptionHeader printText">Opções: </span>
                                                    <span className="productCheckoutDescription printText">{JSON.parse(item.text_labels_objects).map((el)=>el.label_desc).join(" , ")}</span>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                    </Col>
                                        </Row>
                                </Col>
                                <Col sm={2}>{item.price!=="" ? item.price : item.price }€</Col>
                                <Col sm={2}>{item.quantity}</Col>
                                <Col sm={2}>{(item.quantity * (item.subtotal!=="" ? item.subtotal : item.price )).toFixed(2)}€</Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                     <Row className="tableHeader">
                        <Col sm={10}>Subtotal:</Col>
                        <Col sm={2}>{order.length!==0 && order[0].subtotal} €</Col>
                     </Row>
                    <Row className="tableHeader">
                        <Col sm={10}>Envio:</Col>
                        <Col sm={2}>{order.length!==0 && order[0].delivery_cost} €</Col>
                     </Row>
                    <Row className="tableHeader">
                        <Col sm={10}>Total:</Col>
                        <Col sm={2}>{order.length!==0 && order[0].total} €</Col>
                     </Row>
                </ListGroup>
            </form>
            <div>
            </div>
        </Fragment>
    );
}