import React, {useState} from "react";
import "./css/InputFieldSelector.css";

function InputFieldSelector(props) {
    const fieldId = props.id;

    const [objectFieldValue, setObjectFieldValue] = useState(props.fieldValue);

    const changeHandler = (event) => {
        setObjectFieldValue({
            fieldId: fieldId, fieldValue: event.target.value,
        });
        props.onEnteredText(event.target.value);
    };
    const keyDownHandler = (event) => {
        // Para impedir que o form seja submetido ao carregar no enter
        if(event.key === "Enter") {
            event.preventDefault();
        }
    }

    return (<div style={props.style}>
            <label className="field">{props.title}</label>
            <div>
                <input
                    type="text"
                    maxLength={props.maxlength}
                    id={props.id}
                    className="input-text"
                    value={objectFieldValue.fieldValue}
                    onChange={changeHandler}
                    onKeyDown={keyDownHandler}
                />
            </div>
        </div>);
}

export default InputFieldSelector;
