
import "./css/AGLabels.css";

import React from "react";
import {useSelector} from "react-redux";

import Carousel from 'react-bootstrap/Carousel';

import DespensaLabels from "./DespensaLabels";


export default function DespensaLabelsCarousel(props) {
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3;
    linha1=labelConfigState.labelConfig.linha1;
    linha2=labelConfigState.labelConfig.linha2;
    linha3=labelConfigState.labelConfig.linha3;

    console.log(labelConfigState);
    console.log(labelConfigState.labelConfig.labelSKU);

    return(
        <div>
            {/*Se já tiver escolhido algum produto aparece o carousel*/}
            {(labelConfigState.labelConfig.textLabelsObjects.length!==0 ) &&
            <Carousel variant="dark" slide={false}>
                {labelConfigState.labelConfig.textLabelsObjects.map((label,index) => (
                    ((index!==10 && labelConfigState.labelConfig.labelSKU==="EAESPM") || (index!==8 && (labelConfigState.labelConfig.labelSKU==="EAESPR" || labelConfigState.labelConfig.labelSKU==="EAESPG") ))  ?
                    <Carousel.Item>
                        <div className="d-block w-100" style={{margin:"100px"}} key={index+1}>
                            {(label.label_linha2==="" || label.label_linha2===null || label.label_linha2==="NULL")  &&
                                <DespensaLabels linha1={label.label_linha1} linha2={""} linha3={""} index={index+1}/>
                            }
                            {label.label_linha2!=="" && label.label_linha2!==null && label.label_linha2!=="NULL"  &&
                                <DespensaLabels linha1={""} linha2={label.label_linha1} linha3={label.label_linha2} index={index+1}/>
                            }
                         </div>
                     <Carousel.Caption>
                        <h5>Etiqueta {index + 1}</h5>
                     </Carousel.Caption>
                    </Carousel.Item>
                        :
                        ""
                ))}
            </Carousel> }
            {/*Se ainda não tiver escolhido nenhum item aparece uma etiqueta*/}
            {labelConfigState.labelConfig.textLabelsObjects.length===0 &&  <DespensaLabels linha1={linha1} linha2={linha2} linha3={linha3} />}
        </div>
    );
}