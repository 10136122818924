import "./css/ProductDescription.css";


function ProductDescription(props) {
    return (
        <div>
            <div className="productTitle">{props.title}</div>
            <div className="productDescription">
                {props.description.split('#').map((i, index) => {
                    return <p key={index}>{i}</p>
                })
                }
            </div>
            <div className="productPrice">
                {props.productPrice}€
            </div>
        </div>
    );
}

export default ProductDescription;
