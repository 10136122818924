import {createSlice} from "@reduxjs/toolkit";
import {updateLabelConfig} from "../actions/labelConfigActions";
import store from "./index";
import {labelType} from "../components/simulator/labels/constants/LabelsConstants";


const labelConfigInitialState = {
    labelConfig: {
        linha1: "",
        linha2: "",
        linha3: "",
        linhaLC: "",
        linhaLCM: "",
        icon: "drc00",
        bkColor: "whiteBk",
        txtColor: "blackTx",
        theme: "theme1",
        model: "both",
        txtFont: "TL1",
        disableBkColors: "",
        lote: "",
        iconEsq: "drc38",
        backColorEsq: "rgb(255,255,255)",
        fontColorEsq: "rgb(233,117,117)",
        iconDir: "drc159",
        backColorDir: "rgb(228,242,241)",
        fontColorDir: "rgb(233,117,117)",
        labelSimulationType: "themes",
        quantity: 1,
        labelSKU: "",
        labelType: "",
        lotePrice: "",
        labelTitle: "",
        imageData: "",
        loteDesc: "",
        txtColorDesc: "Preto",
        bkColorDesc: "Branco",
        iconDesc: "Sem desenho",
        cartItemNumber: "-1",
        groupCode:"4",
        price: 0,
        themeDesc: "Tema 1 - Fadas",
        otherOptionsObjects : [],
        otherOptionsCodes : "",
        loteRef : "",
        subOptionsValue : 0,
        selectedSubOptionsCodes : "",
        selectedSubOptionsDescriptions : "",
        optionsDescriptions : "",
        textLabelsObjects : [],
        despensaTheme: "Tema 1",
        themeName: "Tema 1",
        fontLinha1: "TLE1",
        fontLinha2: "TLE2",
        fontColor: "tema1Tx",
        labelBackgroundImage: "",
        labelBackgroundImage2: "",
        hasPersonalizedOptions: false,
        isKit: false,
    },
};

const labelConfigSlice=createSlice({
    name: 'labelConfig',
    initialState: labelConfigInitialState,
    reducers : {
        updateSKUandLabelType(state, action) {
            state.labelConfig.labelSKU = action.payload;
            state.labelConfig.labelType = labelType[action.payload];
        },
        updateIsKit(state, action) {
            state.labelConfig.isKit = action.payload;
        },
        updateLinha1(state, action) {
            state.labelConfig.linha1 = action.payload;
        },
        updateLinha2(state, action) {
            state.labelConfig.linha2 = action.payload;
        },
        updateLinha3(state, action) {
            state.labelConfig.linha3 = action.payload;
        },
        updateLinhaLC(state, action) {
            state.labelConfig.linhaLC = action.payload;
        },
        updateLinhaLCM(state, action) {
            state.labelConfig.linhaLCM = action.payload;
        },
        updateSelectedIcon(state, action) {
            state.labelConfig.icon = action.payload.icon;
            state.labelConfig.iconDesc = action.payload.iconDesc;
            state.labelConfig.groupCode = action.payload.iconGroupCode;
        },
        updateThemeAttrib(state, action) {
            state.labelConfig.theme = action.payload.theme;
            state.labelConfig.themeDesc = action.payload.themeDesc;
            state.labelConfig.iconEsq = action.payload.iconEsq;
            state.labelConfig.backColorEsq = action.payload.backColorEsq;
            state.labelConfig.fontColorEsq = action.payload.fontColorEsq;
            state.labelConfig.iconDir = action.payload.iconDir;
            state.labelConfig.backColorDir = action.payload.backColorDir;
            state.labelConfig.fontColorDir = action.payload.fontColorDir;
        },
        updateLabelTitle4Cart(state, action) {
            state.labelConfig.title = action.payload;
        },
        updateModel(state, action) {
            state.labelConfig.model = action.payload;
        },
        updateTextColor(state, action) {
            state.labelConfig.txtColor = action.payload.txtColor;
            state.labelConfig.disableBkColors = action.payload.disabledBkColors;
            state.labelConfig.bkColor = action.payload.bkColor;
            state.labelConfig.txtColorDesc = action.payload.colorDesc;
            state.labelConfig.bkColorDesc = action.payload.bkColorDesc;
        },
        updateBkColor(state, action) {
            state.labelConfig.bkColor = action.payload.bkColor;
            state.labelConfig.bkColorDesc = action.payload.bkColorDesc;
        },
        updateTxtFont(state, action) {
            state.labelConfig.txtFont = action.payload;
        },
        updateLote(state, action) {
            state.labelConfig.lote = action.payload.lote;
            state.labelConfig.lotePrice = action.payload.lotePrice;
            state.labelConfig.loteDesc = action.payload.loteDesc;
            state.labelConfig.loteRef = action.payload.loteRef;
        },
        updateLabelSimulationType(state, action) {
            state.labelConfig.labelSimulationType = action.payload;
        },
        updateQuantity(state, action) {
            state.labelConfig.quantity = action.payload;
        },
        updateImageData(state, action) {
            state.labelConfig.imageData = action.payload;
        },
        updateLabelConfig(state,action) {
            state.labelConfig=action.payload.labelConfig;
        },
        resetLabelConfig(state) {
            state.labelConfig.linha1 = "";
            state.labelConfig.linha2 = "";
            state.labelConfig.linha3 = "";
            state.labelConfig.linhaLC = "";
            state.labelConfig.linhaLCM = "";
            state.labelConfig.icon = "drc00";
            state.labelConfig.bkColor = "whiteBk";
            state.labelConfig.txtColor = "blackTx";
            state.labelConfig.theme = "theme1";
            state.labelConfig.model = "both";
            state.labelConfig.txtFont = "TL1";
            state.labelConfig.disableBkColors = "";
            state.labelConfig.lote = "";
            state.labelConfig.iconEsq = "drc38";
            state.labelConfig.backColorEsq = "rgb(255,255,255)";
            state.labelConfig.fontColorEsq = "rgb(233,117,117)";
            state.labelConfig.iconDir = "drc159";
            state.labelConfig.backColorDir = "rgb(228,242,241)";
            state.labelConfig.fontColorDir = "rgb(233,117,117)";
            state.labelConfig.labelSimulationType = "themes";
            state.labelConfig.quantity = 1;
            state.labelConfig.labelSKU = "";
            state.labelConfig.labelType = "";
            state.labelConfig.lotePrice = "";
            state.labelConfig.labelTitle = "";
            state.labelConfig.imageData = "";
            state.labelConfig.loteDesc = "";
            state.labelConfig.txtColorDesc = "Preto";
            state.labelConfig.bkColorDesc = "Branco";
            state.labelConfig.iconDesc = "Sem desenho";
            state.labelConfig.cartItemNumber = "-1";
            state.labelConfig.groupCode = "4";
            state.labelConfig.price = 0;
            state.labelConfig.themeDesc = "Tema 1 - Fadas";
            state.labelConfig.otherOptionsObjects = [];
            state.labelConfig.otherOptionsCodes = "";
            state.labelConfig.loteRef = "";
            state.labelConfig.subOptionsValue = 0;
            state.labelConfig.selectedSubOptionsCodes = "";
            state.labelConfig.selectedSubOptionsDescriptions = "";
            state.labelConfig.OptionsDescriptions = "";
            state.labelConfig.textLabelsObjects=[];
            state.labelConfig.despensaTheme = "Tema 1";
            state.labelConfig.themeName = "Tema 1" ;
            state.labelConfig.fontLinha1 = "TLE1" ;
            state.labelConfig.fontLinha2 = "TLE2" ;
            state.labelConfig.fontColor = "tema1Tx" ;
            state.labelConfig.labelBackgroundImage = "" ;
            state.labelConfig.labelBackgroundImage2 = "" ;
            state.labelConfig.hasPersonalizedOptions= false;
            state.labelConfig.isKit = false ;
        },
        updateCartItemNumber(state, action) {
            //console.log(action.payload)
            state.labelConfig.cartItemNumber = action.payload;
        },
        updatePrice(state, action) {
            state.labelConfig.price = action.payload;
        },
        updateSubjects(state,action) {
            state.labelConfig.otherOptionsObjects = action.payload;
            state.labelConfig.otherOptionsCodes = action.payload.map((el) => el.subject_code).join("-");
        },
        updateARP(state,action) {
            state.labelConfig.otherOptionsObjects = action.payload;
            state.labelConfig.otherOptionsCodes = action.payload.code;
            state.labelConfig.labelSimulationType="";
            state.labelConfig.txtFont="";
        },
        resetFontAndSimulationType(state,) {
            state.labelConfig.labelSimulationType="";
            state.labelConfig.txtFont="";
        },
        updateSubOptionsValue(state, action) {
            state.labelConfig.subOptionsValue = action.payload;
        },
        updateSelectedSubOptionsCodes(state, action) {
            state.labelConfig.selectedSubOptionsCodes = action.payload;
        },
        updateSelectedSubOptionsDescriptions(state, action) {
            state.labelConfig.selectedSubOptionsDescriptions = action.payload;
        },
        updateOptionsDescriptions(state, action) {
            state.labelConfig.optionsDescriptions = action.payload;
        },
        updateTextLabelsObjects(state,action) {
            state.labelConfig.textLabelsObjects = action.payload;
            //state.labelConfig.otherOptionsCodes = action.payload.map((el) => el.subject_code).join("-");
        },
        updateDespensaThemeAttrib(state, action) {
            // vou ter de adicionar quais os atributos importantes para construção da simulação da label
            state.labelConfig.despensaTheme = action.payload.theme;
            state.labelConfig.themeName = action.payload.themeName;
            state.labelConfig.fontLinha1 = action.payload.fontLinha1;
            state.labelConfig.fontLinha2 = action.payload.fontLinha2;
            state.labelConfig.fontColor = action.payload.fontColor;
            state.labelConfig.labelBackgroundImage = action.payload.labelBackgroundImage;
            state.labelConfig.labelBackgroundImage2 = action.payload.labelBackgroundImage2;
        },
        updateDespensaBackgroundImage(state,action) {
            state.labelConfig.labelBackgroundImage = action.payload;
        },
        updateHasPersonalizedOptions(state,action) {
            state.labelConfig.hasPersonalizedOptions = action.payload;
        },
    }
});

export const labelConfigActions = labelConfigSlice.actions;
export default labelConfigSlice;