import "./LabelSimulation.css";

import React, {useCallback, useRef} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {toPng} from "html-to-image";
import {useSelector} from "react-redux";
import RegularLabels from "./labels/RegularLabels";
import LCLabels from "./labels/LCLabels";
import LCMLabels from "./labels/LCMLabels";
import {paramLabel} from "./labels/constants/LabelsFontSizesConstants";
import {labelType,lineSizes} from "./labels/constants/LabelsConstants";
import MiniLabels from "./labels/MiniLabels";
import IMLabels from "./labels/IMLabels";
import ShoesSRLabels from "./labels/ShoesSRLabels";
import AGLabels from "./labels/AGLabels";
import EFGLabels from "./labels/EFGLabels";
import ADISCCarousel from "./labels/ADISCCarousel";
import MiniRoundLabels from "./labels/MiniRoundLabels";
import FeetLabels from "./labels/FeetLabels";
import TextLabelsCarousel from "./labels/TextLabelsCarousel";
import DespensaLabelsCarousel from "./labels/DespensaLabelsCarousel";
import ShoesLRLabels from "./labels/ShoesLRLabels";
import TABLabels from "./labels/TABLabels";

import BIKE1Labels from "./labels/BIKE1Labels";
import BIKE2Labels from "./labels/BIKE2Labels";



export default function LabelSimulation(props) {
    const tiketaComp = props.tiketaComp.split(","); // composição dos kits
    const elementRef = useRef();
    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, linhaLC, linhaLCM, iconSrc, icon;
    let linha4, linha5, linha6
    linha4 = "";
    linha5 = "";
    linha6 = "";
    let c_s_icon;
    //console.log(labelConfigState.labelConfig.selectedSubOptionsCodes["IM"]);
    if (labelConfigState.labelConfig.linha2 !== "") {
        linha2 = labelConfigState.labelConfig.linha1;
        linha3 = labelConfigState.labelConfig.linha2;
        linha1 = "";
    } else {
        linha1 = labelConfigState.labelConfig.linha1;
    }
    // etiquetas com 3 linhas
    if (labelConfigState.labelConfig.linha3 !== "" && (labelType[labelConfigState.labelConfig.labelSKU] === "AG" || labelType[labelConfigState.labelConfig.labelSKU] === "EFG" || tiketaComp.indexOf("AG") !== -1 || tiketaComp.indexOf("EFG") !== -1)) {
        linha4 = labelConfigState.labelConfig.linha1;
        linha5 = labelConfigState.labelConfig.linha2;
        linha6 = labelConfigState.labelConfig.linha3;
    }

    if (labelConfigState.labelConfig.linhaLC !== "") {
        linhaLC = labelConfigState.labelConfig.linhaLC;
    }
    if (labelConfigState.labelConfig.linhaLCM !== "") {
        linhaLCM = labelConfigState.labelConfig.linhaLCM;
    }
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";
    // structure with font sizes for each label type (each one result of setFontSize calculations)

    // set max font size, for each label type according to user line input
    const setFontSize = (labelType, configuration) => {
        console.log("Entrou aqui");
        // *****************************
        // set fontsize for labels .....
        // TODO: talvez se possa usar offsetHeight para melhor a função
        //   ver  : https://stackoverflow.com/questions/687998/auto-size-dynamic-text-to-fill-fixed-size-container
        //******************************
        let linha1Temp = configuration.linha1;
        let linha2Temp = configuration.linha2;
        let linha3Temp = configuration.linha3;
        let linhaLCTemp = configuration.linhaLC;
        let linhaLCMTemp = configuration.linhaLCM;
        if (configuration.icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons") {
            c_s_icon = "SEM_ICON";
        } else {
            c_s_icon = "COM_ICON";
        }
        if (labelType === "AG" || labelType === "EFG" || labelType==="ADISC") {
            if (linha2Temp === "" && linha3Temp === "") {
                // 1 linha
                if (linha1Temp.length > paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_length"]) {
                    lineSizes[labelType]["fontSizeL1_1"] = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_size_ini"] - (linha1Temp.length - paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_min_size"]) + "pt";
                } else {
                    lineSizes[labelType]["fontSizeL1_1"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_size_ini"] + "pt";
                }
            } else if (linha1Temp !== "" && linha2Temp !== "" && linha3Temp === "") {
                // 2 linhas (linha 1 e linha2)
                if (Math.max(configuration.linha1.length, configuration.linha2.length) > paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_length"]) {
                    lineSizes[labelType]["fontSizeL1_2"] = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] - (Math.max(configuration.linha1.length, configuration.linha2.length) - paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_min_size"]) + "pt";
                    lineSizes[labelType]["fontSizeL2_2"] = lineSizes[labelType]["fontSizeL1_2"];
                } else {
                    lineSizes[labelType]["fontSizeL1_2"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] + "pt";
                    lineSizes[labelType]["fontSizeL2_2"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] + "pt";
                }
            } else if (linha1Temp !== "" && linha2Temp !== "" && linha3Temp !== "") {
                // 3 linhas (linha1, linha2 e linha3)
                if (Math.max(configuration.linha1.length, configuration.linha2.length, configuration.linha3.length) > paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_length"]) {
                    lineSizes[labelType]["fontSizeL1_3"] = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] - (Math.max(configuration.linha1.length, configuration.linha2.length, configuration.linha3.length) - paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_min_size"]) + "pt";
                    lineSizes[labelType]["fontSizeL2_3"] = lineSizes[labelType]["fontSizeL1_3"];
                    lineSizes[labelType]["fontSizeL3_3"] = lineSizes[labelType]["fontSizeL1_3"];
                } else {
                    lineSizes[labelType]["fontSizeL1_3"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] + "pt";
                    lineSizes[labelType]["fontSizeL2_3"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] + "pt";
                    lineSizes[labelType]["fontSizeL3_3"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["3_linha_size_ini"] + "pt";
                }
            }
        } else if(!["ARP","DCCAA","DCCAT","BM","EABOL","EFM","EFMN","EACXMED","EAESPM","EAESPR","EAESPG","PI","MP"].includes(labelType)) {

            if (labelType === "LC") {
                linha1Temp = linhaLCTemp;
            }
            if (labelType === "LCM") {
                linha1Temp = linhaLCMTemp;
            }
            console.log(labelType + "-" + configuration.txtFont + "- " + c_s_icon );
            lineSizes[labelType]["fontSizeL1_1"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_size_ini"];
            lineSizes[labelType]["fontSizeL1_2"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"];
            lineSizes[labelType]["fontSizeL2_2"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"];
            if (linha2Temp.trim().length === 0 || labelType === "LC" || labelType === "LCM") {
                // 1 linha
                if (linha1Temp.length > paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_length"]) {
                    lineSizes[labelType]["fontSizeL1_1"] = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_size_ini"] - (linha1Temp.length - paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_min_size"]) + "pt";
                } else {
                    lineSizes[labelType]["fontSizeL1_1"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["1_linha_size_ini"] + "pt";
                }
                // set top
                lineSizes[labelType]["topAdjust"] = (
                    (paramLabel[labelType][labelConfigState.labelConfig.txtFont]["SEM_ICON"]["1_linha_size_ini"] -
                        lineSizes[labelType]["fontSizeL1_1"].substring(0, lineSizes[labelType]["fontSizeL1_1"].length - 2)
                    ) > 0 ? 10 + paramLabel[labelType][labelConfigState.labelConfig.txtFont]["SEM_ICON"]["1_linha_size_ini"]
                        - lineSizes[labelType]["fontSizeL1_1"].substring(0, lineSizes[labelType]["fontSizeL1_1"].length - 2) : 0) + "%";
            } else {
                // 2 linhas
                if (Math.max(configuration.linha1.length, configuration.linha2.length) > paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_length"]) {
                    lineSizes[labelType]["fontSizeL1_2"] = Math.max(paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] - (Math.max(configuration.linha1.length, configuration.linha2.length) - paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_length"]) / 2, paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_min_size"]) + "pt";
                    lineSizes[labelType]["fontSizeL2_2"] = lineSizes[labelType]["fontSizeL1_2"];
                } else {
                    lineSizes[labelType]["fontSizeL1_2"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] + "pt";
                    lineSizes[labelType]["fontSizeL2_2"] = paramLabel[labelType][configuration.txtFont][c_s_icon]["2_linha_size_ini"] + "pt";
                }
            }
        }
    };
    // set the font size for each kit label's type
    if(labelType[labelConfigState.labelConfig.labelSKU].charAt(0) === "K"){
        if(tiketaComp.indexOf("REG")!==-1) {
            console.log(labelConfigState.labelConfig);
            setFontSize("REG", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("MINI")!==-1) {
            setFontSize("MINI", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("EC")!==-1) {
            setFontSize("EC", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("IM")!==-1) {
            setFontSize("IM", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("AG")!==-1) {
            setFontSize("AG", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("LC")!==-1) {
            setFontSize("LC", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("LCM")!==-1) {
            setFontSize("LCM", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("EFG")!==-1) {
            setFontSize("EFG", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("BIKE1")!==-1) {
            setFontSize("BIKE1", labelConfigState.labelConfig);
        }
        if(tiketaComp.indexOf("BIKE2")!==-1) {
            setFontSize("BIKE2", labelConfigState.labelConfig);
        }
    } else {
     setFontSize(labelType[labelConfigState.labelConfig.labelSKU], labelConfigState.labelConfig);
    }

    return (<div className="col d-flex justify-content-center align-middle labelSimulationCard1"  >
        {/**************************-LABEL PRESENTATION-*******************/}
        <Card className="labelSimulationCard"
              style={{display: labelType[labelConfigState.labelConfig.labelSKU].charAt(0) === "K" ? "none" : ""}} ref={props.labelImgSingle} >
            {labelType[labelConfigState.labelConfig.labelSKU]==="ARP" &&
                <div>
                    <img id="productImage" src={"/media/TIK_ARP_1_" + labelConfigState.labelConfig.otherOptionsObjects.code + "_" + labelConfigState.labelConfig.lote +  "_8_96.png"} />
                </div>
            }
            {(labelType[labelConfigState.labelConfig.labelSKU]==="DCCAA" || labelType[labelConfigState.labelConfig.labelSKU]==="DCCAT") &&
                <div>
                    <img id="productImage" src={"/media/TIK_DIC_" + labelConfigState.labelConfig.selectedSubOptionsCodes["DCCA"] + ".png"} />
                </div>
            }
            {(["BM","EABOL","PI","MP"].includes(labelType[labelConfigState.labelConfig.labelSKU])) &&
                <div>
                    <img id="productImage" src={"/media/" + labelConfigState.labelConfig.selectedSubOptionsCodes[labelConfigState.labelConfig.labelSKU] + ".png"} />
                </div>
            }
            {(labelType[labelConfigState.labelConfig.labelSKU]==="EFMN" || labelType[labelConfigState.labelConfig.labelSKU]==="EFM") &&
                <div>
                    <img id="productImage" src={"/media/" +labelType[labelConfigState.labelConfig.labelSKU] +"/" + labelConfigState.labelConfig.selectedSubOptionsCodes[labelType[labelConfigState.labelConfig.labelSKU]] + ".png"} />
                </div>
            }
            {/* Etiquetas Regulares */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "REG" && <RegularLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/>}
            {/* Etiquetas Lapis e Canetas */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "LC" && <LCLabels linhaLC={linhaLC} isKitMember={0}/> }
            {/* Etiquetas Lapis e Canetas Mini */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "LCM" && <LCMLabels linhaLCM={linhaLCM} isKitMember={0}/> }
            {/* Etiquetas tamanho Mini */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "MINI" && <MiniLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/> }
            {/* Identificadores */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "IM" && <IMLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/> }
            {/* Etiquetas calçado */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "EC" && <ShoesSRLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/> }
            {/* Etiquetas Grandes */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "AG" && <AGLabels linha1={linha1} linha2={linha2} linha3={linha3} linha4={linha4} linha5={linha5} linha6={linha6} isKitMember={0}/> }
            {/* Etiquetas Batas */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "EFG" && <EFGLabels linha1={linha1} linha2={linha2} linha3={linha3} linha4={linha4} linha5={linha5} linha6={linha6} isKitMember={0}/>}
            {/* Etiquetas Disciplinas */}
            {/*labelType[labelConfigState.labelConfig.labelSKU] === "ADISC" && <ADISCLabels linha1={linha1} linha2={linha2} linha3={linha3} linha4={linha4} linha5={linha5} linha6={linha6} isKitMember={0}/> */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "ADISC" && <ADISCCarousel/> }
            {/* Etiquetas Redondas Mini */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "RED" && <MiniRoundLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/>}
            {/* Fitas de Pescoço */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "FPP" && <MiniRoundLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/>}
            {/* Etiquetas em Forma de Pezinhos */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "ECPE" && <FeetLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/>}
            {/* Etiquetas para Despensa */}
            {(labelType[labelConfigState.labelConfig.labelSKU].substring(0,5) === "EAESP" ) && <DespensaLabelsCarousel/> }
            {/* Etiquetas para Caixa de Medicamentos */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "EACXMED" && <TextLabelsCarousel/> }
            {/* Etiquetas para sapatos esquerda/direita */}
            {labelType[labelConfigState.labelConfig.labelSKU] === "EPED" && <ShoesLRLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/>}
            {/* Etiquetas Para carros e portas */}
            {labelType[labelConfigState.labelConfig.labelType] === "TAB" && <TABLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/>}
        </Card>
        {/**************************-KIT PRESENTATION-*******************/}
        {/*labelType[labelConfigState.labelConfig.labelSKU].charAt(0) === "K"*/}
        <div style={{display: labelType[labelConfigState.labelConfig.labelSKU].charAt(0) === "K" ? "" : "none"}} ref={props.labelImgKit}>
            <Row className="rowKit"
                 style={{display: tiketaComp.indexOf("REG") === -1 && tiketaComp.indexOf("AG") === -1 ? "none" : ""}}>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("REG") === -1 ? "none" : ""}}>
                    {/* Etiquetas Regulares */}
                    <RegularLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={1}/>
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("AG") === -1 ? "none" : ""}}>
                    {/* Etiquetas Grandes */}
                    <AGLabels linha1={linha1} linha2={linha2} linha3={linha3} linha4={linha4} linha5={linha5} linha6={linha6} isKitMember={1}/>
                </Col>
            </Row>
            <Row className="rowKit"
                 style={{display: tiketaComp.indexOf("EFG") === -1 && tiketaComp.indexOf("MINI") === -1 ? "none" : ""}}>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("EFG") === -1 ? "none" : ""}}>
                    {/* Etiquetas Batas */}
                    <EFGLabels linha1={linha1} linha2={linha2} linha3={linha3} linha4={linha4} linha5={linha5} linha6={linha6} isKitMember={1}/>
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("MINI") === -1 ? "none" : ""}}>
                    {/* Etiquetas tamanho Mini */}
                    <MiniLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={1}/>
                </Col>
            </Row>
            <Row className="rowKit"
                 style={{display: (tiketaComp.indexOf("IM") === -1 && !['IMS','IMD'].includes(labelConfigState.labelConfig.selectedSubOptionsCodes["IM"])) && tiketaComp.indexOf("EC") === -1 ? "none" : ""}}>
                <Col className="columnKit" style={{display: (tiketaComp.indexOf("IM") === -1 && !['IMS','IMD'].includes(labelConfigState.labelConfig.selectedSubOptionsCodes["IM"])) ? "none" : ""}}>
                    {/* Identificadores */}
                    <IMLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={1}/>
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("EC") === -1 ? "none" : ""}}>
                    {/* Etiquetas calçado */}
                    <ShoesSRLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={1}/>
                </Col>
                {/* Pulseira Kit Praia Básico */}
                <Col className="columnKit" style={{display: tiketaComp.indexOf("PI") === -1 ? "none" : ""}}>
                    {/* Pulseira 1 */
                    console.log(labelConfigState.labelConfig)}

                    <img className="imgKit" id="productImage" src={"/media/" + (labelConfigState.labelConfig.selectedSubOptionsCodes.length===0 || tiketaComp.indexOf("PI") === -1? labelConfigState.labelConfig.selectedSubOptionsCodes["PI"] : labelConfigState.labelConfig.selectedSubOptionsCodes["PI"].substring(0,4)) + ".png"} />
                </Col>
            </Row>
            <Row className="rowKit"
                 style={{display: tiketaComp.indexOf("LC") === -1 && tiketaComp.indexOf("LCM") === -1 ? "none" : ""}}>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("LC") === -1 ? "none" : tiketaComp.indexOf("LC") === tiketaComp.indexOf("LCM") ? "none" : ""}}>
                    {/* Etiquetas Lapis e Canetas */}
                    <LCLabels linhaLC={linhaLC} isKitMember={1}/>
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("LCM") === -1 ? "none" : ""}}>
                    {/* Etiquetas Lapis e Canetas Mini */}
                    <LCMLabels linhaLCM={linhaLCM} isKitMember={1}/>
                </Col>
            </Row>
            <Row className="rowKit"
                 style={{display: tiketaComp.indexOf("RED") === -1  ? "none" : ""}}>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("RED") === -1 ? "none" : ""}}>
                    {/* Redondas */}
                    <MiniRoundLabels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={0}/>
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("LCM") === -1 ? "none" : ""}}>
                </Col>
            </Row>
            {/* Pulsieras dos kits Paraia*/}
            <Row className="rowKit"
                 style={{display: tiketaComp.indexOf("PI1") === -1  ? "none" : ""}}>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("PI1") === -1 ? "none" : ""}}>
                    {/* Pulseira 1 */}
                    <img className="imgKit" id="productImage" src={"/media/" + (labelConfigState.labelConfig.selectedSubOptionsCodes.length===0 || tiketaComp.indexOf("PI1") === -1 ? labelConfigState.labelConfig.selectedSubOptionsCodes["PI1"] : labelConfigState.labelConfig.selectedSubOptionsCodes["PI1"].substring(0,4)) + ".png"} />
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("PI1") === -1 ? "none" : ""}}>
                    {/* Pulseira 1 */}
                    <img className="imgKit" id="productImage" src={"/media/" + (labelConfigState.labelConfig.selectedSubOptionsCodes.length===0 || tiketaComp.indexOf("PI2") === -1 ?  labelConfigState.labelConfig.selectedSubOptionsCodes["PI2"] :labelConfigState.labelConfig.selectedSubOptionsCodes["PI2"].substring(0,4)) + ".png"} />
                </Col>
            </Row>
            <Row className="rowKit"
                 style={{display: tiketaComp.indexOf("BIKE1") === -1  ? "none" : ""}}>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("BIKE1") === -1 ? "none" : ""}}>
                    {/* Etiquetas Bicicleta Médias */}
                    <BIKE1Labels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={1}/>
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("EC") === -1 ? "none" : ""}}>
                </Col>
            </Row>
            <Row className="rowKit"
                 style={{display: tiketaComp.indexOf("BIKE2") === -1  ? "none" : ""}}>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("BIKE2") === -1 ? "none" : ""}}>
                    {/* Etiquetas Bicicleta Médias */}
                    <BIKE2Labels linha1={linha1} linha2={linha2} linha3={linha3} isKitMember={1}/>
                </Col>
                <Col className="columnKit" style={{display: tiketaComp.indexOf("EC") === -1 ? "none" : ""}}>
                </Col>
            </Row>
            {
                /*<Row>
                <button onClick={onButtonClick}>Make Image to Cart</button>
            </Row>
            <Row> <img id="imagem" src="" width="5%" height="5%"/></Row>
            */
            }
        </div>
    </div>);
}
