import "./css/TextLabelsSelector.css"
import {Fragment, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";

export default function TextLabelsSelector(props) {
    const textLabels = props.textLabels;
    const labelsGroups = props.labelsGroups;
    const [arraySelectedTextLabels, setArraySelectedTextLabels] = useState(props.arraySelectedTextLabels);
    console.log(arraySelectedTextLabels);

    console.log(textLabels);
    console.log(labelsGroups);

    const addTextLabelHandler = (event) => {
        let aSelectedTextLabel4Update = arraySelectedTextLabels;
        let newTextLabelObject ="";
        if(arraySelectedTextLabels.length<props.options2Select) {
            newTextLabelObject = {
                    label_desc: event.target.getAttribute("data-label-desc"),
                    label_code: event.target.getAttribute("data-label-code"),
                    label_linha1: event.target.getAttribute("data-label-linha1"),
                    label_linha2: event.target.getAttribute("data-label-linha2")
            }
            setArraySelectedTextLabels((prevState) => prevState.concat(newTextLabelObject) );
        }
        console.log(arraySelectedTextLabels);
        let array4Update = arraySelectedTextLabels.concat(newTextLabelObject);
        props.onSelectedTextLabels(array4Update);
    }
    const removeTextLabelHandler = (event) => {
        let newArray = arraySelectedTextLabels.filter((value,index)=>{return index!==parseInt(event.target.getAttribute("data-label-index"))}); //value.subject_code!==event.target.getAttribute("data-subj-code")
        setArraySelectedTextLabels(newArray);
        props.onSelectedTextLabels(newArray);
    };
    const getTextLabelsByGroup = (group_code) => {
        return textLabels.filter((textLabel) => textLabel.text_group_code === group_code);
    };
    return(
        <Fragment>
            <label className="field">Selecione as Opções :</label>
            <Tabs defaultActiveKey={props.selectedTab} className="nav nav-pills nav-justified">
                {labelsGroups.map(({id,text_group_code,text_group_name}) => {
                    return (
                        <Tab eventKey={text_group_code}
                             title={text_group_name}
                             key={text_group_code}
                        >
                            <div className="field-selection" >
                                { getTextLabelsByGroup(text_group_code).map(
                                    ({label_code,label_desc,label_linha1,label_linha2}) => {
                                        return (
                                            <div
                                                key={label_code}
                                                className="textLabelsDiv"
                                                data-label-code={label_code}
                                                data-label-desc={label_desc}
                                                data-label-linha1={label_linha1}
                                                data-label-linha2={label_linha2}
                                                onClick={addTextLabelHandler}
                                            >
                                                {label_desc}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </Tab>
                    )
                })
                }
            </Tabs>
            <label className="field"> Items Selecionados: {arraySelectedTextLabels.length}/{props.options2Select}</label>
            <div className="textLabelsSelector">
                {arraySelectedTextLabels.length === 0 &&
                    <div className="textLabelsDiv">Sem Opções Selecionadas</div>
                }
                {arraySelectedTextLabels.map(({label_desc,label_code}, index) => {
                        return (
                            <div
                                key={99 + index}
                                className="textLabelsDiv"
                                data-label-code={label_code}
                                data-label-name={label_desc}
                                data-label-index={index}
                                onClick={removeTextLabelHandler}
                            >
                                {label_desc} | X
                            </div>
                        );
                    }
                )}
            </div>
        </Fragment>
    );
}