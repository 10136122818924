import React from 'react';

import Card from './Card';
import classes from './MessageModal.module.css';
import Loader from "../Loader";

const MessageModal = (props) => {
  return (
    <div>
      <div className={classes.backdrop} onClick={props.onConfirm} />
      <Card className={classes.modal}>
          <header className={classes.header}>
         <h2>{props.title}</h2>
        </header>
        <div className={classes.content}>
          <h4>{props.message}</h4>
        </div>
        <footer className={classes.actions}>
        </footer>
      </Card>
    </div>
  );
};

export default MessageModal;
