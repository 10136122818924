import {configureStore} from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware} from "redux";
import authSlice from "./authSlice";
import labelConfigSlice from "./labelConfigSlice";
import cartSlice from "./cartSlice";
import passwordUpdateSlice from "./passwordUpdateSlice";
import orderListSlice from "./orderListSlice";


const middleware = [thunk];

const store = configureStore({
        reducer: {
            userLogin: authSlice.reducer,
            labelConfig: labelConfigSlice.reducer,
            cart: cartSlice.reducer,
            passwordUpdate: passwordUpdateSlice.reducer,
            orderList: orderListSlice.reducer,
        },
    },
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;


