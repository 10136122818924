import React, {useCallback, useRef,useEffect} from "react";
import {useSelector} from "react-redux";
import "./css/MiniRoundLabels.css";
import {bgColorObj,txtColorObj} from "./constants/LabelsColorConstants"
import {lineSizes} from "./constants/LabelsConstants";


export default function MiniRoundLabels(props) {

    const labelConfigState = useSelector((state)=> state.labelConfig );
    let linha1, linha2, linha3, iconSrc, icon,isKitMember;
    linha1=props.linha1;
    linha2=props.linha2;
    linha3=props.linha3;
    isKitMember=props.isKitMember;
    icon = labelConfigState.labelConfig.icon;
    iconSrc = "/media/icons/" + icon + ".png";
    useEffect(() => {
        const text1 = document.getElementById('linha1');
        text1.innerHTML = text1.textContent.replace(/\S|\s/g, "<span class='idCircular'>$&</span>");
        const ele = text1.querySelectorAll('span');
        for (let i = 1; i < ele.length; i++) {
          ele[i].style.transform = `rotate(${i * 16.5}deg)`;
          text1.style.transform = `rotate(${-i * 8.5}deg)`;
        }
    }, [linha1])
    useEffect(() => {
        const text2 = document.getElementById('linha2');
        text2.innerHTML = text2.textContent.replace(/\S|\s/g, "<span class='idCircular'>$&</span>");
        const ele = text2.querySelectorAll('span');
        for (let i = 1; i < ele.length; i++) {
          ele[i].style.transform = `rotate(${i * 13}deg)`;
          text2.style.transform = `rotate(${-i * 6.5}deg)`;
        }
    }, [linha2])
    useEffect(() => {
            const text3= document.getElementById('linha3');
            const deg= -150/text3.textContent.length;
            let origin = 360 + (text3.textContent.length*5);
            text3.innerHTML= text3.textContent.replace(/\S|\s/g, "<span class='circTxt'>$&</span>");
            const ele = text3.querySelectorAll('span');
            for (let i = 0; i < ele.length; i++) {
                ele[i].style.height= "25px";
                ele[i].style.fontSize= "15pt";
                ele[i].style.position="absolute";
                //ele[i].style.zIndex= "99";
                ele[i].style.transform = `rotate(${origin}deg)translateY(95px)`;
                ele[i].style.transformOrigin = "0 100%";
                origin += -10;

            }
    }, [linha3])
    return(
        <div id="label-tiketa-rdmin" className="labelSimulationCard" style={{
                             zoom: isKitMember===1 ? "70%":"100%",
                         }}>
                <div
                    className="label label-rdmin"
                    style={{
                        background: labelConfigState.labelConfig.labelSimulationType === "icons" ? bgColorObj[labelConfigState.labelConfig.bkColor] : labelConfigState.labelConfig.backColorEsq,
                        display: labelConfigState.labelConfig.labelSimulationType === "themes" && labelConfigState.labelConfig.model === "right" ? "none" : "",
                    }}
                >
                    <div
                        className="label-brand label-rdmin-brand"
                        style={{
                            color: labelConfigState.labelConfig.labelSimulationType === "icons" ? txtColorObj[labelConfigState.labelConfig.txtColor] : labelConfigState.labelConfig.fontColorEsq,
                        }}
                    >
                        tiketa.pt
                    </div>
                    <div className="iconleft iconleft_rdmin">
                        <img
                            id="iconLeft"
                            alt="labelIcon"
                            className="iconleft iconleft_rdmin"
                            src={labelConfigState.labelConfig.labelSimulationType === "icons" ? iconSrc : "/media/icons/" + labelConfigState.labelConfig.iconEsq + ".png"}
                            style={{
                                display: icon === "drc00" && labelConfigState.labelConfig.labelSimulationType === "icons" ? "none" : "",
                            }}
                        />
                    </div>
                    <div id="linha1"
                        className="label-l1-sicon label-rdmin-l1-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            fontSize: lineSizes["REG"]["fontSizeL1_1"],
                        }}
                    >
                        {linha1}
                    </div>
                    <div id="linha2"
                        className="label-l1-sicon label-rdmin-l2-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            fontSize: lineSizes["REG"]["fontSizeL1_2"],
                        }}
                    >
                        {linha2}
                    </div>
                    <div id="linha3"
                        className="label-l3-sicon label-rdmin-l3-sicon"
                        style={{
                            color: txtColorObj[labelConfigState.labelConfig.txtColor],
                            fontFamily: labelConfigState.labelConfig.txtFont,
                            fontSize: lineSizes["REG"]["fontSizeL2_2"],
                        }}
                    >
                        {linha3}
                    </div>

                </div>

            </div>
    );

}
