import React, {useState, useEffect} from "react";
import {Row, Col, Card} from "react-bootstrap";

import axios from "axios";
import {Link, useParams} from "react-router-dom";

function SubCategoryPage() {
    const [catLv2, setCatLv2] = useState([]);
    const {category} = useParams();

    useEffect(() => {
        async function fetchCatLv2() {
            const {data} = await axios.get(`/api/get_child_categories/?category_code=${category}`);
            setCatLv2(data);
        }

        fetchCatLv2();
    }, []);

    return (
        <div>
            <h1>Categorias Secundárias</h1>
            <Row>
                {catLv2.map((category) => (
                    <Col md={3}>
                        <Card className="my-3 p-3 rounded border-0">
                            <Link
                                to={category.has_lv2_child_catg === 1 ? "/tiketa/category/category/" + category.category_code : "/tiketa/category/" + category.category_code}
                                className="btn btn-light my-3"
                            >
                                <Card.Img src={`/media/${category.image_url}`}/>
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default SubCategoryPage;
