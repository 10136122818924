import "./css/ShoeLabelsLeftRightThemeSelector.css";
import React, { useState } from "react";


export default function ShoeLabelsLeftRightThemeSelector(props) {
  const [themeSel, setThemeSel] = useState(props.selectedDespensaTheme);

  const selectDespensaThemeHandler = (event) => {
    let selectedTheme = event.target.getAttribute("data-theme");
    let themeName = event.target.getAttribute("data-themename");
    let fontLinha1 = event.target.getAttribute("data-fontlinha1");
    let fontLinha2 = event.target.getAttribute("data-fontlinha2");
    let fontColor = event.target.getAttribute("data-fontcolorlinha1");
    let labelBackgroundImage = event.target.getAttribute("data-labelbackgroundimage");

    setThemeSel(selectedTheme);
    props.onSelectedDespensaTheme(
      selectedTheme,
      themeName,
      fontLinha1,
      fontLinha2,
      fontColor,
      labelBackgroundImage,
    );
  };

  return (
    <div>
      <label className="field">Temas:</label>
      <div className="temas field-selection">
        {props.despensaThemes.map(
          ({
            theme_id,
            theme_name,
            font_linha1,
            font_linha2,
            font_color_linha1,
            theme_label_background_image,
            theme_label_select_image,
          }) => {
            return (
              <img
                key={theme_id}
                src={"media/" + theme_label_select_image}
                alt={theme_name}
                className={
                  theme_id === themeSel ? "single-theme selected" : "single-theme"
                }
                onClick={selectDespensaThemeHandler}
                data-theme={theme_id}
                data-themename={theme_name}
                data-fontlinha1={font_linha1}
                data-fontlinha2={font_linha2}
                data-fontcolorlinha1 = {font_color_linha1}
                data-labelbackgroundimage={theme_label_background_image}
              />
            );
          }
        )}
      </div>
    </div>
  );
}
