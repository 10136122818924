import axios from "axios";
import {authActions} from '../store/authSlice';
import {passwordUpdateActions} from "../store/passwordUpdateSlice";

export const updatePassword = (password) => async (dispatch, getState) => {
    try {
        dispatch(passwordUpdateActions.passwordUpdateRequest({}))
        const {
            userLogin: {userInfo},
        } = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const {data} = await axios.put('/api/users/profile/change_password',
            password,
            config
        )
        dispatch(passwordUpdateActions.passwordUpdateSuccess(data))
        dispatch(authActions.userLoginSuccess(data))
        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch(passwordUpdateActions.passwordUpdateFail(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message));
    }
}

