import {useLocation, useNavigate} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import axios from "axios";
import {resetCart} from "../../actions/cartActions";
import {useSelector} from "react-redux";

export default function FinalizeOrder(props) {
    const [data,setData]=useState([]);
    const { state } = useLocation();
    const userLogin = useSelector((state) => state.userLogin);
    const {order,total} = state;
    const navigate = useNavigate();
    const updateOrder = (event) => {
        event.preventDefault();
        const config = {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${userLogin.userInfo.token}`
                }
        }
        let orderDataUpdate={pos_store_reference: document.getElementById("pos_store_reference").value===""?"null":document.getElementById("pos_store_reference").value,
                            user_id:userLogin.userInfo.id,
                            status:"Processing",
                            client_invoice_date:"",
                            total:total};
        async function updateOrder() {
            const {data} = undefined || axios.put('api/order/update_order/' + order,
                        orderDataUpdate , config
            ).catch((error)=>{
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return {data:{id: "Erro - " + error.response.status }};
                 } else if (error.request) {
                    console.log(error.request);
                    return {data:{id: "Erro - " + error.request }};
                 } else {
                    console.log('Error', error.message);
                    return {data:{id:"Erro - " + error.message}};
                 }
                 return {data: {id:"Erro - " + error.config}};
            }).then ((data)=> {
                    navigate("/print_order_page", {state: {orderId: data?.data.id, total: data?.data.total}});
                }
            )
            setData(data);
        }
        updateOrder();
    }
    return(
        <form onSubmit={updateOrder} >
            <Row>
                <Col sm={12} className="headerRow">
                    <span>Obrigado por ter escolhido a Tiketa!</span>
                </Col>
            </Row>
            <Row>
                <Col sm={12} className="headerRow">
                    <span>A sua encomenda foi gravada com sucesso!</span>
                </Col>
                <Col sm={12} className="headerRow">
                    <span>Encomenda nº {order}</span>
                </Col>
                <Col sm={12} className="headerRow">
                    <span>Valor a pagar: €{total}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={3} className="headerRow">
                    <label className="field">  Referência da Loja (nº fatura):</label>
                </Col>
                <Col sm={5} className="headerRow">
                    <span><input className="input-text" autoComplete="off" id="pos_store_reference" style={{width: "100%"}}/></span>
                </Col>
                 <Col sm={4} className="headerRow">
                     <Button  type="submit" >
                            Finalizar &nbsp;&gt;&gt;
                     </Button>
                 </Col>
            </Row>
        </form>
    );
}