import { cartActions } from '../store/cartSlice';



export const addItem2Cart = (item) => (dispatch) => {
    dispatch(cartActions.addItem2Cart(item));
   // dispatch(resetLabelConfig());
}
export const removeItemFromCart = (item) => (dispatch) => {
    dispatch(cartActions.removeItemFromCart(item));
}
export const updateItemQuantityAndTotal = (cartItem) => (dispatch) => {
    dispatch(cartActions.updateItemQuantityAndTotal(cartItem));
}
export const updateClientData = (clientData) => (dispatch) => {
    dispatch(cartActions.updateClientData(clientData));
}
export const resetCart = () => (dispatch) => {
    dispatch(cartActions.resetCart());
}
export const updateShippingData = (shippingData) => (dispatch) => {
    console.log(shippingData);
    dispatch(cartActions.updateShippingData(shippingData));
}