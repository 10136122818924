import {Button, Col, Row} from "react-bootstrap";
import React, {Fragment, useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import InputFieldSelector from "../../components/simulator/labelsOptionsSelectors/InputFieldSelector";

export default function OrderInformation(props) {
    const [invoiceReceiptURL,setInvoiceReceiptURL] = useState("")
    const [edit,setEdit] = useState("true")
    const userLogin = useSelector((state) => state.userLogin);
    let order=props.order

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const config = {
         headers: {
             'Content-type': 'application/json',
             'Authorization': `Bearer ${userLogin.userInfo.token}`,
             'X-CSRFToken': getCookie('csrftoken')
         }
    }

    const getPdfDocument = (event) => {
        async function fetchDocumentLink() {
                const {data} = undefined || await axios.get(`/api/order/get_document_url/?docId=${order.client_invoice_number}`, config);
                setInvoiceReceiptURL(data);
         }
         fetchDocumentLink();
        if (invoiceReceiptURL !== "") {
            window.open(invoiceReceiptURL, '_blank');
        }

    }
    const setEditFlag = (event) => {
        setEdit("false");
    }
    const saveClientData = (event) => {
        let clientData = {
            orderID : event.target.getAttribute("data-orderid"),
            clientName : document.getElementById("clientName").value,
            clientNIF :  document.getElementById("clientNIF").value,
            clientEmail : document.getElementById("clientEmail").value,
            clientPhone : document.getElementById("clientPhone").value,
            clientAddress : document.getElementById("clientAddress").value,
            clientPostalCode : document.getElementById("clientPostalCode").value,
            clientPostalCodeLocation : document.getElementById("clientPostalCodeLocation").value,
        };
        props.onUpdateClientData(clientData);
        setEdit("true");

    }
  return (
      <Col sm={6}>
        <Row>
          <Col className="tableHeader" sm={6}>
            Informação da Encomenda:
          </Col>
            <Col className="tableHeader" sm={6}>
                {edit==="true" && order.status==="Pending" &&
                    <i
                        className="fa-solid fa-pen-to-square"
                        data-orderid={order.id}
                        onClick={setEditFlag}
                        style={{ cursor: "pointer" }}
                    ><span style={{ cursor: "pointer", fontSize:"7pt"}}> Editar dados </span></i>
                }
                {edit==="false" && order.status==="Pending" &&
                    <i
                        className="fa-solid fa-floppy-disk"
                        data-orderid={order.id}
                        onClick={saveClientData}
                        style={{ cursor: "pointer" }}
                    ><span data-orderid={order.id} style={{ cursor: "pointer", fontSize:"7pt"}}> Gravar dados </span></i>
                }
            </Col>
        </Row>
          <Row>
              <Col sm={4}>Loja:</Col>
              <Col sm={8}>{order.store_name}</Col>
          </Row>
          {edit==="true" &&
              <Fragment>
                <Row>
                  <Col sm={4}>Cliente:</Col>
                  <Col sm={8}>{order.client_name} </Col>
                </Row>
                <Row>
                  <Col sm={4}>Nº Contribuinte:</Col>
                  <Col sm={8}>{order.client_nif}</Col>
                </Row>
                <Row>
                  <Col sm={4}>Email:</Col>
                  <Col sm={8}>{order.client_email_address}</Col>
                </Row>
                <Row>
                  <Col sm={4}>Contacto:</Col>
                  <Col sm={8}>{order.client_phone_number}</Col>
                </Row>
                <Row className="tableHeader">
                  <Col sm={12}>Informação de Envio : ({order.delivery_type})</Col>
                  <Col sm={0}></Col>
                </Row>
                  <div style={{display: order.delivery_type==="Entrega ao domicilio" ? "" : "none"}}>
                        <Row>
                          <Col sm={4}>Metodo de Envio:</Col>
                          <Col sm={8}>{order.delivery_method}</Col>
                        </Row>
                        <Row>
                          <Col sm={4}>Morada:</Col>
                          <Col sm={8}>{order.client_address}</Col>
                        </Row>
                        <Row>
                          <Col sm={4}>Código Postal:</Col>
                          <Col sm={8}>{order.client_postal_code} {order.client_postal_code_location} </Col>
                        </Row>
                          <Row className="tableHeader">
                          <Col sm={4}></Col>
                          <Col sm={8}></Col>
                        </Row>
                  </div>
              </Fragment>
          }
          {edit==="false" &&
              <Fragment>
                <Row>
                  <Col sm={4}>Cliente:</Col>
                  <Col sm={8}>
                      <input type="text" id="clientName" defaultValue={order.client_name}/> </Col>
                </Row>
                <Row>
                  <Col sm={4}>Nº Contribuinte:</Col>
                  <Col sm={8}><input id="clientNIF" type="text" defaultValue={order.client_nif}/></Col>
                </Row>
                <Row>
                  <Col sm={4}>Email:</Col>
                  <Col sm={8}><input id="clientEmail" type="text" defaultValue={order.client_email_address}/></Col>
                </Row>
                <Row>
                  <Col sm={4}>Contacto:</Col>
                  <Col sm={8}><input id="clientPhone" type="text" defaultValue={order.client_phone_number}/></Col>
                </Row>
                   <Row className="tableHeader">
                  <Col sm={12}>Informação de Envio : ({order.delivery_type})</Col>
                  <Col sm={0}></Col>
                </Row>
                  <div style={{display: order.delivery_type==="Entrega ao domicilio" ? "" : "none"}}>
                        <Row>
                          <Col sm={4}>Metodo de Envio:</Col>
                          <Col sm={8}>{order.delivery_method}</Col>
                        </Row>
                        <Row>
                          <Col sm={4}>Morada:</Col>
                          <Col sm={8}><input id="clientAddress" type="text" defaultValue={order.client_address}/></Col>
                        </Row>
                        <Row>
                          <Col sm={4}>Código Postal:</Col>
                          <Col sm={8}><input id="clientPostalCode" type="text" defaultValue={order.client_postal_code}/>&nbsp;<input id="clientPostalCodeLocation" type="text" defaultValue={order.client_postal_code_location}/>  </Col>
                        </Row>
                          <Row className="tableHeader">
                          <Col sm={4}></Col>
                          <Col sm={8}></Col>
                        </Row>
                  </div>

              </Fragment>
          }
        <Row>
          <Col sm={4}>Data de Criação:</Col>
          <Col sm={8}>
            {order.created_date.substring(0, 4) === "1900"
              ? "---"
              : new Date(order.created_date).toLocaleString("pt-PT")}
          </Col>
        </Row>
           {order.delivery_type!=="Levantamento em mãos" &&
               <Row>
                    <Col sm={4}>Data de Envio:</Col>
                   <Col sm={8}>
                        {order.shipping_date.substring(0, 4) === "1900"
                          ? "---"
                          : new Date(order.shipping_date).toLocaleString("pt-PT")}
                   </Col>
               </Row>
           }
           {order.delivery_type!=="Levantamento em mãos" &&
                <Row>
                  <Col sm={4}>Ref. de Envio:</Col>
                  <Col sm={8}>
                    {order.shipping_ref === "null" ? "---" : order.shipping_ref} &nbsp;
                      {order.shipping_ref === "null" ? "" : <a href={order.delivery_method!="CTT-Correio Registado"? "https://tracking.dpd.pt/track-and-trace?reference=02481001:" + order.id :"https://www.ctt.pt/feapl_2/app/open/objectSearch/objectSearch.jspx?lang=def&objects=" + order.shipping_ref } target="_blank">Consultar Envio</a>}
                  </Col>
                </Row>
           }
          {order.delivery_type==="Levantamento em mãos" &&
          <Row>

              <Col sm={4}>Entregue ao Cliente em:</Col>
              <Col sm={8}>
                {order.close_date !== null &&
                order.close_date.substring(0, 4) === "1900"
                  ? "---"
                  : new Date(order.close_date).toLocaleString("pt-PT")}
              </Col>
          </Row>
        }
        <Row>
          <Col sm={4}>Data de Faturação:</Col>
          <Col sm={8}>
            {order.client_invoice_date.substring(0, 4) === "1900"
              ? "---"
              : new Date(order.client_invoice_date).toLocaleString("pt-PT")}
          </Col>
        </Row>
        <Row>
          <Col sm={4}>Ref. da Fatura:</Col>
          <Col sm={8}>
            {order.client_invoice_number === "null"
              ? "---"
              : order.client_invoice_ref + " (" + order.client_invoice_number + ")"}
            &nbsp;
            {order.client_invoice_number !== "null" &&
              order.client_invoice_number !== null &&
              invoiceReceiptURL === "" && (
                <i
                  className="fa-solid fa-cash-register"
                  data-orderid={order.client_invoice_number}
                  onClick={getPdfDocument}
                  style={{ cursor: "pointer" }}
                > <span style={{ cursor: "pointer", fontSize:"7pt"}}> Gerar Fatura </span> </i>
              )}
            {order.client_invoice_number !== "null" &&
              order.client_invoice_number !== null &&
              invoiceReceiptURL !== "" && (
                <i
                  className="fas fa-file-pdf"
                  data-orderid={order.client_invoice_number}
                  onClick={getPdfDocument}
                  style={{ cursor: "pointer" }}
                ><span style={{ cursor: "pointer", fontSize:"7pt"}}> Imprimir Fatura </span> </i>
              )}
          </Col>
        </Row>
         <Row>
          <Col sm={4}>Método de Pagamento:</Col>
          <Col sm={8}>
            {order.payment_method === "null"
              ? "---"
              : order.payment_method}
          </Col>
        </Row>
      </Col>
  );
}
