import "./css/ModelSelector.css";

const ModelSelector = (props) => {
  const bothHandler = () => {
    props.onSelectedModel("both");
  };
  const leftHandler = () => {
    props.onSelectedModel("left");
  };
  const rightHandler = () => {
    props.onSelectedModel("right");
  };
  return (
    <div>
      <label className="field">Modelo:</label>
      <div className="field-selection">
        <button
          type="button"
          className={
            props.selectedModel === "both"
              ? "btModelSelector selected"
              : "btModelSelector"
          }
          onClick={bothHandler}
        >
          Ambos
        </button>
        <button
          type="button"
          className={
            props.selectedModel === "left"
              ? "btModelSelector selected"
              : "btModelSelector"
          }
          onClick={leftHandler}
        >
          Esquerda
        </button>
        <button
          type="button"
          className={
            props.selectedModel === "right"
              ? "btModelSelector selected"
              : "btModelSelector"
          }
          onClick={rightHandler}
        >
          Direita
        </button>
      </div>
    </div>
  );
};

export default ModelSelector;
