import React, {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {Form, Button, Row, Col, Container} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import {login} from '../actions/userActions';
import {getElementFromSelector} from "bootstrap/js/src/util";

function LoginScreen() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const history = useNavigate();
    const dispatch = useDispatch();


    const userLogin = useSelector((state) => state.userLogin);

    const {userInfo, loading, error} = userLogin;

    useEffect(() => {
        if (userInfo) {
            if(userInfo.is_kiosk_store && window.matchMedia("(orientation: portrait)").matches) {
                history("/SplashPage");
            } else {
                history("/");
            }

        }
    }, [history, userInfo]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6}>
                    <h1>Login</h1>
                    {error && <Message variant="danger">{error}</Message>}
                    {loading && <Loader/>}
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId="email">
                            <Form.Label>Email </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button type="submit" variant="primary" className="mt-3">
                            Login
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginScreen