import React, {Fragment, useEffect, useState} from "react";
import {resetLabelConfig} from "../../actions/labelConfigActions";
import {useLocation} from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import axios from "axios";
import {Button, Col, Image, ListGroup, Row} from "react-bootstrap";
import "./PrintOrderPage.css";
import {useNavigate} from "react-router-dom";


export default function PrintOrderPage(props) {
    const { state } = useLocation();
    const {orderId,total} = state;
    const [order, setOrder] = useState([]);
    const userLogin = useSelector((state) => state.userLogin);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const config = {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${userLogin.userInfo.token}`
                }
    }
    useEffect(() => { // redirect to home , after 60 seconds
        const timeoutId = setTimeout(() => {
         navigate('/SplashPage')
         }, 600000)
        return () => clearTimeout(timeoutId);
    }, [])
    useEffect(()=>{
         async function fetchOrder() {
                const {data} = undefined || await axios.get(`/api/get_order/?id=${orderId}`, config);
                setOrder(data);
            }
            fetchOrder();
         console.log(order);
    },[orderId]);
    //console.log(order);
    const printOrderHandler = (event) => {
        event.preventDefault();
        window.print();
    }
    const newOrderClickHandler = () => {
        dispatch(resetLabelConfig());
        navigate("/");
    }
    return(
        <Fragment>
            <form onSubmit={printOrderHandler}>
                <ListGroup variant="flush" className=".d-print-inline-flex">
                    <Row>
                        <Col sm={12} className="headerRow1">
                            <span >O seu pedido é o nº {order[0]?.id} </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="headerRow">
                            <span>Valor a pagar é de : {order[0]?.total}€  </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="headerRow">
                            <span>Por favor, dirija-se ao balcão com o número do pedido para fazer o pagamento. Obrigado!</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4} className="headerRow">

                        </Col>
                        <Col sm={4} className="headerRow">
                            {(order[0]?.pos_store_reference!==null && order[0]?.pos_store_reference !=="null") &&
                            <span>Ref. Documento da Loja - {order[0]?.pos_store_reference}</span> }
                        </Col>
                        <Col sm={2} className="headerRow">
                            {!userLogin.userInfo.is_kiosk_store  &&
                                 <Button type="submit" className="printButton">
                                     <i className="fa fa-print" aria-hidden="true">&nbsp;</i>Imprimir
                                 </Button>
                            }
                        </Col>
                        <Col sm={2} className="headerRow">
                             <Button type="button" onClick={newOrderClickHandler} className="printButton">
                                 <i aria-hidden="true">&nbsp;</i>Novo Pedido
                             </Button>
                        </Col>
                        <Col sm={12}  className="titleRow"  >
                            <span>Resumo do pedido</span>
                        </Col>
                    </Row>
                    <Row className="tableHeader">
                        <Col sm={9} className="text-center">Artigo</Col>
                        <Col sm={1} className="text-center">Preço</Col>
                        <Col sm={1} className="text-center">Quant.</Col>
                        <Col sm={1} className="text-center">SubTotal</Col>
                    </Row>
                    {order.length!==0 && order[0].order_lines.map((item,index) => (
                        <ListGroup.Item key={index + "-" + item.sku}>
                            <Row>
                                <Col sm={9} >
                                    <Row>
                                        <Col sm={7}>
                                           {/* <img src="media/simulador/images/image_22_1.png" />*/}
                                            <Image src={item.image_blob} alt={item.prod_desc} fluid rounded />
                                        </Col>

                                    <Col sm={5}>
                                  <Row>
                                        <Col>
                                            <span className="productTitleCheckout printText">{ item.lote_ref===null?  item.sku  : item.sku + item.lote_ref}-{item.prod_desc}</span>
                                        </Col>
                                    </Row>
                                    {(item.lote_desc !== "" && item.lote_desc !== "null") &&
                                        <Row>
                                            <Col sm={12}>
                                                <span className="productDescriptionHeader printText">
                                                    Lote:
                                                </span>
                                               <span className="productCheckoutDescription printText">
                                                    {item.lote_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.label_simulation_type === "themes" &&
                                        <Fragment>
                                            <Row>
                                                <Col sm={12} >
                                                     <span className="productDescriptionHeader printText">
                                                        Tema:
                                                     </span>
                                                   <span className="productCheckoutDescription printText">
                                                       {item.theme_desc}
                                                   </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <span className="productDescriptionHeader printText">
                                                        Modelo:
                                                    </span>
                                                    <span className="productCheckoutDescription printText">
                                                        {item.theme_model === "both" ? "Ambos os modelos" : item.theme_model === "right" ? "Modelo da Direita" : "Modelo da Esquerda"  }
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                    {item.label_simulation_type === "icons" && item.sku !== "EACXMED" && item.sku !== "EPED" &&
                                        (<Fragment>
                                        <Row>
                                            <Col sm={12} >
                                                <span className="productDescriptionHeader printText">
                                                    Icon:
                                                </span>
                                                <span className="productCheckoutDescription printText">
                                                    {item.icon_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                 <span className="productDescriptionHeader printText">
                                                    Cor da Letra:
                                                 </span>
                                                <span className="productCheckoutDescription printText">
                                                     {item.font_color_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                            <Row>
                                            <Col sm={12}>
                                                <span className="productDescriptionHeader printText">
                                                    Cor do Fundo:
                                                </span>
                                                <span className="productCheckoutDescription printText">
                                                    {item.bk_color_desc}
                                                </span>
                                            </Col>
                                        </Row>
                                        </Fragment>)
                                    }
                                        {item.font !== "" &&
                                            <Row>
                                                <Col sm={12}>
                                                <span className="productDescriptionHeader printText">
                                                    Tipo de Letra:
                                                </span>
                                                    <span className="productCheckoutDescription printText">
                                                    {item.font}
                                                </span>
                                                </Col>
                                            </Row>
                                        }
                                    {item.linha1 !== "" && item.linha1 !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Linha 1: </span>
                                               <span className="productCheckoutDescription printText">{item.linha1}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.linha2 !== "" && item.linha2 !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Linha 2: </span>
                                                <span className="productCheckoutDescription printText">{item.linha2}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.linha3 !== "" && item.linha3 !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Linha 3: </span>
                                                <span className="productCheckoutDescription printText">{item.linha3}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.sku === "ADISC" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Disciplinas: </span>
                                                <span className="productCheckoutDescription printText">{JSON.parse(item.other_options_objects).map((el)=>el.subject_name).join(" , ")}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.sku === "EACXMED" &&
                                        <Fragment>
                                            <Row>
                                                <Col sm={12}>
                                                     <span className="productDescriptionHeader printText">
                                                        Cor da Letra:
                                                     </span>
                                                    <span className="productCheckoutDescription printText">
                                                         {item.font_color_desc}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} >
                                                   <span className="productDescriptionHeader printText">Opções: </span>
                                                    <span className="productCheckoutDescription printText">{JSON.parse(item.text_labels_objects).map((el)=>el.label_desc).join(" , ")}</span>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                    {item.sku.substring(0,5) === "EPED" &&
                                            <Row>
                                                <Col sm={12}>
                                                     <span className="productDescriptionHeader printText">
                                                        Tema:
                                                     </span>
                                                    <span className="productCheckoutDescription printText">
                                                         {item.theme_desc}
                                                    </span>
                                                </Col>
                                            </Row>
                                    }
                                    {item.sku.substring(0,5) === "EAESP" &&
                                        <Fragment>
                                            <Row>
                                                <Col sm={12}>
                                                     <span className="productDescriptionHeader printText">
                                                        Tema:
                                                     </span>
                                                    <span className="productCheckoutDescription printText">
                                                         {item.despensa_theme}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} >
                                                   <span className="productDescriptionHeader printText">Opções: </span>
                                                    <span className="productCheckoutDescription printText">{JSON.parse(item.text_labels_objects).map((el)=>el.label_desc).join(" , ")}</span>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                     {item.sku === "ARP" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Idioma: </span>
                                                <span className="productCheckoutDescription printText">{JSON.parse(item.other_options_objects).description}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {(["DCCAT","DCCAA"].includes(item.sku)) &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">{JSON.parse(item.options_descriptions)["DCCA"]}</span>
                                            <span className="productCheckoutDescription">{JSON.parse(item.selected_sub_options_codes)["DCCA"] + " - " +  JSON.parse(item.selected_sub_options_descriptions)["DCCA"]}</span>
                                        </Col>
                                    </Row>
                                     }
                                     {(["BM","EABOL","PI","MP"].includes(item.sku)) &&
                                    <Row>
                                        <Col sm={12} >
                                           <span className="productDescriptionHeader">{JSON.parse(item.options_descriptions)[item.sku]}</span>
                                            <span className="productCheckoutDescription">{JSON.parse(item.selected_sub_options_codes)[item.sku] + " - " +  JSON.parse(item.selected_sub_options_descriptions)[item.sku]}</span>
                                        </Col>
                                    </Row>
                                     }
                                    {item.linhaLC !== "" && item.linhaLC !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Lapis e Canetas: </span>
                                                <span className="productCheckoutDescription printText">{item.linhaLC}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {item.linhaLCM !== "" && item.linhaLCM !== "null" &&
                                        <Row>
                                            <Col sm={12} >
                                               <span className="productDescriptionHeader printText">Mini Lapis e Canetas: </span>
                                                <span className="productCheckoutDescription printText">{item.linhaLCM}</span>
                                            </Col>
                                        </Row>
                                    }
                                    {['KRAR','EFMN','EFM','FPP','KIT1','KIT2','KIT5','KRD','KPC','KPB'].includes(item.sku)  &&
                                                Object.keys(JSON.parse(item.options_descriptions)).map((el)=> {
                                                    return (
                                                        <Row>
                                                            <Col sm={12} >
                                                                <span className="productDescriptionHeader printText"> {JSON.parse(item.options_descriptions)[el]} </span>
                                                                <span className="productCheckoutDescription printText"> :  {JSON.parse(item.selected_sub_options_descriptions)[el]} </span>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                    }
                                    </Col>
                                    </Row>
                                </Col>
                                <Col sm={1} className="text-center">{item.price!=="" ? item.price : item.price }€</Col>
                                <Col sm={1} className="text-center">{item.quantity}</Col>
                                <Col sm={1} className="text-center">{item.quantity * (item.subtotal!=="" ? item.subtotal : item.price )}€</Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                    <Row className="tableHeader">
                        <Col sm={10}>Subtotal:</Col>
                        <Col sm={2}>{order.length!==0 && order[0].subtotal} €</Col>
                     </Row>
                    <Row className="tableHeader">
                        <Col sm={10}>Envio ({order.length!==0 && (order[0].delivery_method ==="" ? order[0].delivery_type : order[0].delivery_method) }):</Col>
                        <Col sm={2}>{order.length!==0 && order[0].delivery_cost} €</Col>
                     </Row>
                     <Row className="tableHeader">
                        <Col sm={10}>Total:</Col>
                        <Col sm={2}>{order.length!==0 && order[0].total} €</Col>
                     </Row>
                </ListGroup>
            </form>
            <div>
            </div>
        </Fragment>
    );
}