import React, {useState, useEffect} from "react";
import {Row, Col, Card} from "react-bootstrap";

import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Category from "../components/Category";
import {isLandscape} from "../utils/tiketaUtils";
import {logout} from "../actions/userActions";


function Home() {
    const [tiketas, setTiketas] = useState([]);
    const {userInfo} = useSelector((state) => state.userLogin);
    const history = useNavigate();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchTiketas() {
            if (userInfo) {
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
                const {data} = await axios.get("/api/get_categories_lv1_4_store/?store=" + userInfo.store, config).catch((error) => {
                    // APANHA O ERRO QUANDO A SESSÃO TERMINA, FORÇA O LOGOUT E VAI PARA A PÁGINA DE LOGIN
                        console.log("Session Expired!")
                        console.log(error.response.status);
                        dispatch(logout())
                        navigate("/login")
                     });
                setTiketas(data);
            } else {
                // TODO: custom error message here
                history("/login/");
            }
        }
        fetchTiketas();
        // TODO : se calhar o melhor é fazer reset ao labelconfig
    }, []);

    return (
        <div>
            <Row>
                {tiketas.map((category) => (
                    <Col sm={12} md={6} lg={4} xl={isLandscape(userInfo.vertical_layout)? "3" :"4"} key={category.category_code}>
                        <Category category={category}/>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default Home;
